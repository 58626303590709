import Emission2024 from "../components/emissionCalculator2024/Emission2024";
import BeforeDataOverview2024 from "../components/overview2024/BeforeDataOverview2024";
import SignIn2024 from "../components/sign2024/SignIn2024";
import Home from "../components/main2024/Home2024";
import BeforeDataExplorer2024 from "../components/dataExplorer2024/BeforeDataExplorer2024";
import BeforeActivityCategoryBookmark from "../components/activityCategoryBookmark2024/BeforeActivityCategoryBookmark2024";
import BeforeCalcuOverview2024 from "../components/overview2024/BeforeCalcuOverview2024";
import RequestTemp2024 from "../components/userRequestQuestion2024/RequestTemp2024";
import Help2024 from "../components/userRequestQuestion2024/HelpSupport2024";
import BeforeDataSource2024 from "../components/dataSource2024/BeforeDataSource2024";
import Pricing2024 from "../components/pricing2024/Pricing2024";
import BeforeCalculatorBookmark2024 from "../components/calculatorBookmark2024/BeforeCalculatorBookmark2024";
import FirstCreateAccount2024 from "../components/sign2024/FirstCreateAccount2024";
import PasswordStep1 from "../components/sign2024/passwordReset/PasswordStep1";
import AccountInfo2024 from "../components/sign2024/myInformation/AccountInfo2024";
import Terms2024 from "../components/policy2024/Terms2024";
import Privacy2024 from "../components/policy2024/Privacy2024";
import FindDataExplorer2024 from "../components/dataExplorer2024/FindDataExplorer2024";
import MyBookmarkEmissionData2024 from "../components/dataExplorer2024/MyBookmarkEmissionData2024";
import DataIntegrate2024 from "../components/dataExplorer2024/DataIntegrate2024";
import MyBookmarkEmissionCal2024 from "../components/emissionCalculator2024/MyBookmarkEmissionCal2024";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/emission2024",
    element: <Emission2024 />,
  },
  {
    path: "/myBookmarkEmissionCalculator2024",
    element: <MyBookmarkEmissionCal2024 />,
  },
  {
    path: "/login2024",
    element: <SignIn2024 />,
  },
  {
    path: "/SignUp2024",
    element: <FirstCreateAccount2024 />,
  },
  {
    path: "/passwordReset2024",
    element: <PasswordStep1 />,
  },
  {
    path: "/reqTemp2024",
    element: <RequestTemp2024 />,
  },
  {
    path: "/help2024/:type",
    element: <Help2024 />,
  },
  {
    path: "/price2024",
    element: <Pricing2024 />,
  },
  {
    path: "/dataIntegrate2024",
    element: <DataIntegrate2024 />,
  },
  {
    path: "/findDataExplorer2024",
    element: <FindDataExplorer2024 />,
  },
  {
    path: "/myBookmarkEmissionData2024",
    element: <MyBookmarkEmissionData2024 />,
  },
  {
    path: "/myInformation2024",
    element: <AccountInfo2024 />,
  },
  {
    path: "/privacyPolicy2024",
    element: <Privacy2024 />,
  },
  {
    path: "/termsOfUse2024",
    element: <Terms2024 />,
  },
  {
    path: "/dataSource2024",
    element: <BeforeDataSource2024 />,
  },
  {
    path: "/dataOverview2024",
    element: <BeforeDataOverview2024 />,
  },
  {
    path: "/beforeDataExplorer2024",
    element: <BeforeDataExplorer2024 />,
  },
  {
    path: "/activityCategoryBookmark2024",
    element: <BeforeActivityCategoryBookmark />,
  },
  {
    path: "/calculatorOverview2024",
    element: <BeforeCalcuOverview2024 />,
  },
  {
    path: "/calculatorBookmark2024",
    element: <BeforeCalculatorBookmark2024 />,
  },
  {
    path: "/myEmissionDataBookmark2024",
    element: <BeforeDataExplorer2024 />,
  },
];

export default routes;
