import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../util/userSlice";
import { postSignIn } from "../../service/signService";
import { useTranslation } from "react-i18next";

function SignIn() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    inputEmail: "",
    inputPwd: "",
  });

  const [formErrors, setFormErrors] = useState({
    inputEmail: "",
    inputPwd: "",
  });

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async () => {
    const errors = {};

    if (formData.inputEmail === "") {
      errors.inputEmail = "Email is required";
    } else if (!validateEmail(formData.inputEmail)) {
      if (formData.inputEmail !== "SYSTEM") {
        errors.inputEmail = "Invalid email format";
      }
    } else {
      errors.inputEmail = null;
    }

    if (formData.inputPwd === "") {
      errors.inputPwd = "Password is required";
    } else {
      errors.inputPwd = null;
    }
    setFormErrors(errors);

    if (
      Object.keys(errors).length === 0 ||
      (!errors.inputEmail && !errors.inputPwd)
    ) {
      try {
        const params = {
          email: formData.inputEmail,
          password: formData.inputPwd,
        };
        const response = await postSignIn(params);

        console.log("response>>>", response);

        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("access_token", response.data.accessToken);
          localStorage.setItem("token_type", response.data.grantType);
          dispatch(setUserInfo(jwt_decode(response.data.accessToken)));

          // 입력값 초기화
          setFormData({
            inputEmail: "",
            inputPwd: "",
          });

          // 메인 페이지로 이동
          nav("/");
        }
      } catch (error) {
        console.log("Error >>", error);
        // 로그인 실패 로직
        setFormErrors({
          inputEmail: "Incorrect email or password",
          inputPwd: "Incorrect email or password",
        });
      }
    }
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="pageTitle">{t(`SIGNIN.signIn`)}</div>
      <div className="cbSpBox accountBox">
        <div className="accountWrap">
          <div className="accountInput">
            <div className="accInp">
              <div>
                <label>{t(`SIGNIN.email`)}</label>
                <p className={formErrors.inputEmail ? "error" : ""}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, inputEmail: e.target.value })
                    }
                    onKeyDown={handleEnterPress}
                  />
                </p>
                {formErrors.inputEmail && (
                  <span className="errorText">{formErrors.inputEmail}</span>
                )}
              </div>
            </div>

            <div className="accInp">
              <div>
                <label>{t(`SIGNIN.pwd`)}</label>
                <p className={formErrors.inputPwd ? "error" : ""}>
                  <input
                    type="password"
                    onChange={(e) =>
                      setFormData({ ...formData, inputPwd: e.target.value })
                    }
                    onKeyDown={handleEnterPress}
                  />
                </p>
                {formErrors.inputPwd && (
                  <span className="errorText">{formErrors.inputPwd}</span>
                )}
              </div>
            </div>
          </div>
          <div className="accountBtn">
            <button onClick={handleSubmit}>{t(`SIGNIN.signIn`)}</button>
            <button
              className="forgot"
              onClick={() => {
                nav("/passwordReset2024");
              }}
            >
              {t(`SIGNIN.forgotPwd`)}
            </button>
            <p>
              {t(`SIGNIN.needSign1`)}
              <Link to="/SignUp2024">{t(`SIGNIN.needSign2`)}</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
