import { GET, POST } from "./fetch-auth-action.ts";

export const postSignUp = async (params) => {
  const URL = "/api/auth/signup";
  const response = POST(URL, params, null);
  return response;
};

export const postSignIn = async (params) => {
  const URL = "/api/auth/login";
  const response = POST(URL, params, null);
  return response;
};

export const mailSend = async (userId, userName) => {
  const URL = "/api/auth/verification/" + userId + "/" + userName;
  const response = GET(URL, null);
  return response;
};

export const getExistEmail = async (email) => {
  const URL = "/api/auth/exist/idCheck/" + email;
  const response = GET(URL, null);
  return response;
};

export const postResetPassword = async (params) => {
  const URL = "/api/auth/resetPassword";
  const response = POST(URL, params, null);
  return response;
};
