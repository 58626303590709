import React from "react";

function Pricing(){
    return (
        <>
            <div className="pageTitle">Plans and Pricing</div>
            <div className="priceWrap">
                <div className="priceGrid">
                    <div className="cbSpBox">
                        <div className="priceList priceData">
                            <div></div>
                            <div>
                                <dl>
                                    <dt></dt>
                                    <dd>
                                        <b>Free License</b>
                                        <strong>Free</strong>
                                    </dd>
                                </dl>
                            </div>
                            <div>
                                <dl>
                                    <dt></dt>
                                    <dd>
                                        <b>Billed Monthly</b>
                                        <strong>$1,000</strong>
                                    </dd>
                                </dl>
                            </div>
                            <div>
                                <dl>
                                    <dt>Most Popular</dt>
                                    <dd>
                                        <b>Billed Annually</b>
                                        <strong>$10,000</strong>
                                        <em>Save $2,000 annually</em>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="priceList">
                            <div>Your current plan</div>
                            <div>O</div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className="priceList">
                            <div>Explore & Integration of Emission Calculator & Emission Data</div>
                            <div>Only for Open Sources</div>
                            <div>Unlimited</div>
                            <div>Unlimited</div>
                        </div>
                        <div className="priceList">
                            <div>Bookmarks</div>
                            <div>X</div>
                            <div>Unlimited</div>
                            <div>Unlimited</div>
                        </div>
                        <div className="priceList">
                            <div>Request Emissions Activity Calculator</div>
                            <div>X</div>
                            <div>O</div>
                            <div>O</div>
                        </div>
                        <div className="priceList">
                            <div>Upload my private emission factors</div>
                            <div>X</div>
                            <div>O</div>
                            <div>O</div>
                        </div>
                        <div className="priceList">
                            <div>Update notification for bookmarked Emission Factors</div>
                            <div>X</div>
                            <div>O</div>
                            <div>O</div>
                        </div>
                        <div className="priceList btn">
                            <div></div>
                            <div>Default</div>
                            <div><button>Choose Plan</button></div>
                            <div><button>Choose Plan</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Pricing;