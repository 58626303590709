import React from "react";

export default function Terms2024KOR() {
  return (
    <>
      <div className="policyCont kor">
        <strong>제 1 장 총칙</strong>

        <b>제 1 조 (목적)</b>
        <p>
          이 약관은 주식회사띵스파이어(이하 “회사”라 합니다)에서 제공하는 Carbon
          API 서비스(접속 가능한 유•무선단말기의종류와 관계없이회사가
          제공하는이용가능한 모든 서비스를의미하며, 이하 "서비스"라 합니다)를
          이용함에있어, 이용조건 및 절차에관한사항, 회사와 이용자의권리와
          의무및책임에 관한 사항등을규정함을 목적으로합니다
        </p>

        <b>제 2 조 (약관의 효력 및변경)</b>
        <ol>
          <li>
            이 약관은 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을
            발생합니다.
          </li>
          <li>
            이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게
            공시하고, 회원이 서비스에 가입함으로써 효력을 발생합니다.
          </li>
          <li>
            회사는 필요하다고 인정되는 경우 본 약관을 사전고지없이 개정할 수
            있으며, 개정된 약관은 제12조에 정한 방법으로 공지합니다. 회원은
            개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을
            취소(회원탈퇴)할 수 있으며, 계속 사용하는 경우에는 약관개정에 대한
            동의로 간주합니다. 개정된 약관은 공지와 동시에 그 효력이 발생됩니다.
          </li>
        </ol>

        <b>제 3 조 (약관이외의 준칙)</b>
        <p>
          이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 기타
          관련법령의 규정에 따릅니다.
        </p>

        <b>제 4 조 (용어의정의)</b>
        <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ol>
          <li>
            회원 : 회사와 서비스 이용계약을 체결한 기업이나 법인 또는 법인에
            준하는 단체
          </li>
          <li>
            운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한
            사람
          </li>
          <li>
            서비스 : 단말기(PC, 휴대형단말기 등의 각종 유무선장치를 포함)와
            상관없이“회원”에게 회사가 제공하는 서비스를 의미하며, 회사가
            제공하는 서비스는 띵스파이어 Carbon API 서비스를 말함
          </li>
          <li>
            ID(고유번호) : 회원 식별과 회원의 서비스 이용을 위하여 회원이
            선정하고 회사가 승인한 문자와 숫자의조합
          </li>
          <li>
            Password(비밀번호) : 회원의 비밀 보호를 위해 회원자신이 설정한
            문자와 숫자의 조합
          </li>
          <li>
            서비스 중지 : 정상이용 중 회사가 정한 일정한 요건에 따라 일정기간
            동안 서비스의 제공을 중지하는것
          </li>
          <li>
            해지 : 회사 또는 회원이 서비스 이용계약을 체결한 이후 그 이용계약을
            종료시키는 의사표시
          </li>
          <li>
            위항에서 정의되지 않은 약관 상의 용어의 의미는 일반적인 거래관행에
            따릅니다.
          </li>
        </ol>

        <strong>제 2 장 서비스 이용계약</strong>

        <b>제 5 조 (이용계약의성립)</b>
        <ol>
          <li>
            고객은 이 약관에 동의한다는 의사표시를 한 후 회사가 정한 가입양식에
            따라 회원정보를 기입함으로써 회원가입을 신청합니다.
          </li>
          <li>
            이용계약의 성립시기는 고객의 이용신청에 대하여 회사가 승낙한
            시점으로합니다.
          </li>
          <li>
            회원은 회원가입시 등록한 사항에 변경이 있는경우 상당한 기간 이내에
            회사에 대하여 회원정보수정 등의 방법으로 변경사항을 알려야합니다.
          </li>
        </ol>

        <b>제 6 조 (이용신청)</b>
        <p>
          이용신청은 서비스의 신규회원가입 화면에서 다음사항을 가입 신청양식에
          기록하여 신청합니다.
        </p>
        <ol>
          <li>사업자등록번호</li>
          <li>E-mail(전자우편) 주소</li>
          <li>Password(비밀번호)</li>
        </ol>

        <b>제 7 조 (이용신청의승낙)</b>
        <p>
          회사는 제6조에서 정한바에 따라 이용신청한 고객에 대하여 서비스
          이용신청을 승낙 합니다.
        </p>

        <b>제 8 조 (이용신청에 대한 승낙의제한)</b>
        <p>
          회사는 다음의 각호에 해당하는 신청에 대하여는 승낙을 하지않을 수
          있습니다.
        </p>
        <ol>
          <li>기술상 서비스제공이 불가능 한 경우</li>
          <li>
            실명이 아니거나, 다른 사람의 명의사용 등 등록내용을 허위로 기재하여
            신청하는 경우
          </li>
          <li>등록사항을 누락하거나 오기하여 신청하는 경우</li>
          <li>
            사회의 질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우
          </li>
          <li>기타 회사가 정한 이용신청 요건에 충족되지 않는 경우</li>
        </ol>

        <strong>제 3 장이용계약의 변경및 탈퇴</strong>
        <b>제9 조 (이용계약 의변경)</b>
        <ol>
          <li>
            회원은 이용신청시 등록한 사항이 변경되었을 경우 회사가 정한 방법에
            의하여 변경 등록하여야 합니다.
          </li>
          <li>
            다음의 경우 회사는 직권으로 회원 ID를 변경하도록 요구하거나, 회원의
            신청에 의해 회원 ID를 변경할 수 있습니다.
            <ul className="romanList">
              <li>
                회원 ID가 회원의 전화번호, 주민등록번호 등으로 등록되어 회원
                개인정보 침해의 우려가 있는 경우
              </li>
              <li>타인에게 혐오감을 주거나 미풍양속에 저해되는 경우</li>
            </ul>
          </li>
        </ol>

        <b>제10 조 (회원탈퇴)</b>
        <p>
          {" "}
          회원이 서비스 이용계약을 해지하고자 할 경우 서비스 내 회원가입
          해지면에서 해지 신청을 하여야 하며, 회사는 회원탈퇴에 관한 규정에 따라
          이를 처리합니다.
        </p>

        <b>제 11 조 (회원자격의 상실)</b>
        <p>
          회원이 다음 각호의사유에 해당하는경우, 회사는 사전통지없이 이용계약을
          해지하거나또는서비스 이용을중지할 수있습니다.
        </p>
        <ol>
          <li>등록 신청시 기재사항을 허위로 작성한경우</li>
          <li>제28조에 규정한 회원의 의무를 이행하지 않을 경우</li>
          <li>
            타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 서비스
            운영질서를 저해하는 경우
          </li>
          <li>
            서비스를 이용하여 법령과 이 약관이 금지하거나, 미풍양속에 반하는
            행위를 하는 경우
          </li>
          <li>
            제2항 내지 제4항의 사유에 의한 회사조치에 대하여 회원은 회사가 정한
            절차에 따라 이의 신청을 할 수 있습니다.
          </li>
          <li>
            제5항의 이의신청이 정당하다고 회사가 인정하는 경우, 회사는 즉시
            서비스의 이용을 재개합니다.
          </li>
        </ol>

        <b>제 12 조 (회원에 대한 통지)</b>
        <ol>
          <li>
            회사가 회원에 대해 통지를 하는 경우, 회원이 회사에 제출한 전자우편
            주소로 할 수 있습니다.
          </li>
          <li>
            회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판에
            게시함으로써 개별 통지에 갈음 할 수 있습니다.
          </li>
          <li>
            회사는 서비스를 통해 회원의 컴퓨터에 쿠키를 전송할 수 있습니다.
            회원은 쿠키 수신을 거부하거나 쿠키 수신에 대해 경고하도록 브라우저
            설정을 변경할 수 있습니다.
          </li>
        </ol>

        <b>제 13 조 (회원정보 사용에대한 동의)</b>
        <ol>
          <li>
            회사가 이용신청서에 기재를 요구하는 회원 정보는 이 이용계약을
            이행하고 이용계약상 서비스제공을 위한 목적으로 이용합니다.
          </li>
          <li>
            회원들이 서비스를 편리하게 사용할 수 있도록 하기 위해 회원정보는
            회사에 제공될 수 있습니다. 이 경우 회사는 사전에 공지하며 이에
            동의하지 않는 회원은 등록을 취소할수 있습니다. 다만, 계속 이용하는
            경우 동의하는 것으로 간주합니다.
          </li>
          <li>
            회사가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시
            당해 이용자의 동의를 받습니다.
          </li>
          <li>
            제공된 회원정보는 이 약관에서 정한 경우외에 당해 이용자의 동의없이
            제3자에게 제공할수 없으며, 이에 대한 모든 책임은 회사가 집니다.
            다만, 다음의 경우에는 예외로 합니다.
            <ul className="romanList">
              <li>관계법령에 의하여 국가기관으로부터 요구 받은 경우</li>
              <li>
                범죄에 대한 수사상의 목적이있거나, 정보통신 윤리위원회의 요청이
                있는 경우
              </li>
              <li>
                통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로써 특정
                개인을 식별할 수 없는 형태로 제공하는 경우
              </li>
              <li>기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</li>
            </ul>
          </li>
          <li>
            제10조(회원 탈퇴) 및 제11조(회원 자격의상실)의 규정에 의하여 서비스
            이용계약이 종료된 때에는 회사는 회원정보를 즉시 삭제합니다.
          </li>
        </ol>
        <strong>제 4 장 정보 제공</strong>

        <b>제 14 조 (정보를 제공받는자)</b>
        <p>
          회원 정보를 제공받는자는 현대일렉트로닉스, 띵스파이어, KTL, 에코아이,
          캠토피아, 엔베스트, SEP협동조합 입니다.
        </p>

        <b>제 15 조 (정보이용 목적)</b>
        <ol>
          <li>
            회원이 보유한 주요설비 정보 분석을 통하여 탄소배출권 현황 및
            예상수익 분석 서비스를 제공합니다.
          </li>
          <li>
            탄소배출권 검토 분석결과에 따른 탄소배출권 전환 신청 서비스를
            제공합니다.
          </li>
          <li>
            회원이 보유한 탄소배출권을거래할 수 있는 거래소 서비스를 제공합니다.
          </li>
        </ol>

        <b>제 16 조 (제공하는 정보의 항목과 범위)</b>
        <ol>
          <li>
            전력 사용량 현황, 에너지 사용 보고서, 탄소배출권 관련 검토 등록정보,
            RE100인증신청 등록 정보등
          </li>
        </ol>

        <b>제 17 조 (정보저장 및 사용 기간)</b>
        <p>고객으로부터 수집한 정보는 아래와 같은 기간을 기준으로 사용한다.</p>
        <ol>
          <li>
            전력사용량현황, 에너지 사용 보고서, 탄소배출권 관련 검토 등록정보,
            RE100인증신청 등록 정보 : 취득일로부터 2년
          </li>
        </ol>

        <b>제 18 조 (정보제공 거부대한 사항)</b>
        <p>
          고객은 정보 제공을 거부할 수 있으며, 정보 제공 거부시 회사가 제공하는
          서비스 제한 및 해지될 수 있습니다.
        </p>

        <strong>제 5 장서비스 이용</strong>

        <b>제 19 조 (서비스의 이용시간)</b>
        <ol>
          <li>
            서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의
            업무상 또는 기술상의 사유로 서비스가 일시 중지될 수 있으며, 운영상의
            목적으로 회사가 정한기간 동안 서비스가 일시중지 될 수 있습니다.
            이러한 경우에는 사전 또는 사후에 이를 공지합니다
          </li>
          <li>
            회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다. 단,
            회사의 업무상 또는 기술상의 장애로 인해 서비스를 개시하지 못하는
            경우 그 내용을 사전 또는 사후에 공지합니다.
          </li>
          <li>
            회사는 서비스를 일정범위로 분할하여 각범위 별로 이용가능한 시간을
            별도로 정할 수 있으며, 이 경우 그 내용을 사전에 공지합니다.
          </li>
        </ol>
        <b>제 20 조 (서비스의 내용)</b>
        <ol>
          <li>
            회사가 제공하는 서비스는 다음과 같습니다.
            <ul className="romanList">
              <li>배출량 계산기</li>
              <li>배출계수 탐색기</li>
              <li>API 연동</li>
              <li>기타 회사가 정하는 서비스</li>
            </ul>
          </li>
          <li>
            회사는 필요한 경우 서비스의 내용을 공지없이 추가 또는 변경할
            수있습니다.
          </li>
        </ol>

        <b>제 21 조 (게시물 또는 내용물의 삭제)</b>
        <p>
          회사는 서비스의 게시물 또는내용물이 관계법령 및 전기공급 약관에
          위반되거나 게시기간을 초과하는 경우 사전 통지나 동의없이 이를 보완
          또는삭제할 수 있습니다.
        </p>

        <b>제 22 조 (서비스 제공의 중지)</b>
        <ol>
          <li>
            회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
            제한하거나 중지할 수 있습니다.
            <ul className="romanList">
              <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
              <li>회원이 회사의 영업활동을 방해하는 경우</li>
              <li>
                정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
                이용에 지장이 있는 경우
              </li>
              <li>제28조에 따른 회원의 의무를 다하지 않은 경우</li>
              <li>
                기타천재지변, 국가비상사태 등의 불가항력적 사유가 있는 경우
              </li>
            </ul>
          </li>
          <li>
            제1항에 의한 서비스중단의 경우에는 제12조에 정한 방법으로 사전 또는
            사후에 이용자에게 통지합니다.
          </li>
          <li>
            회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여
            이용자 또는 제3자가 입은 손해에 대하여 배상하지 않습니다.
            <br />
            단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지 않습니다.
          </li>
        </ol>

        <b>제 23 조 (정보의제공 및광고의 게재)</b>
        <ol>
          <li>
            회사는 서비스를 운영함에 있어 각종 정보를 서비스에 게재하거나
            전자우편 및 서신우편, SNS 등의 방법으로 회원에게 제공할 수 있습니다.
          </li>
          <li>
            회사는 서비스의 운용과 관련하여 서비스화면, 홈페이지, E-Mail, SNS
            등에 광고 등을 게재할 수 있습니다.
          </li>
        </ol>

        <b>제 24 조 (게시물또는 내용물의삭제)</b>
        <ol>
          <li>
            회사는 회원이 게시하거나 전달하는 서비스내의 모든 내용물이 다음의
            경우에 해당한다고 판단되는 경우 사전 통지없이 삭제할 수 있으며, 이에
            대해 회사는 어떠한 책임도 지지 않습니다.
            <ul className="romanList">
              <li>
                다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는
                내용인 경우
              </li>
              <li>공공질서 및 미풍양속에 위반되는 내용인 경우</li>
              <li>범죄적행위에 결부 된다고 인정되는내용인 경우</li>
              <li>
                회사 또는 제3자의 저작권 및 기타의 권리를 침해하는 내용인 경우
              </li>
              <li>
                기타 관계법령 및 회사의 세부 지침등에 위반된다고 판단되는 경우
              </li>
            </ul>
          </li>
          <li>
            회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수
            있으며, 회원은 그 지침에 따라 각종 게시물을 등록 또는 삭제하여야
            합니다.
          </li>
        </ol>
        <b>제 25 조 (게시물에대한 권리및 책임)</b>
        <ol>
          <li>
            게시물에 대한 권리와 책임은 게시자에게 있으며, 회사는 게시자의 동의
            없이는 이를 서비스내 게재 이외의 영리적 목적으로 사용할 수 없습니다.
          </li>
          <li>
            회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에
            있습니다.
          </li>
          <li>
            회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에
            게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수
            없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.
          </li>
        </ol>

        <b>제 26 조 (서비스이용책임)</b>
        <p>
          회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여
          어떠한 종류의 영업 활동도 할 수없으며 특히 해킹, 광고를 통한 수익
          획득행위, 음란사이트를 통한 상업행위, 상용S/W 불법배포 등을 할 수
          없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한
          구속 등 법적조치 등에 관해서는 회사가 책임을 지지않으며, 회원은 이와
          같은 영업활동 등과 관련하여 회사에 대하여 손해배상 책임을 집니다.
        </p>

        <strong>제 6 장 권리와 의무</strong>

        <b>제 27 조 (회사의의무)</b>
        <ol>
          <li>
            회사는 특별한 사유가 없는 한 서비스 제공설비를 항상 운용 가능한
            상태로 유지,보수 하여야 하며, 안정적으로 서비스를 제공할 수 있도록
            최선의 노력을 다하여야 합니다.
          </li>
          <li>
            회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인 정보
            보호를 위한 보안시스템을 갖추어야 하며, 개인정보취급방침을 공시하고
            이를 준수합니다.
          </li>
          <li>
            회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시
            처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리일정을 서비스
            또는 전자우편을 통하여 동회원에게 통지합니다.
          </li>
        </ol>
        <b>제 28 조 (회원의의무)</b>
        <ol>
          <li>
            회원은 관계법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한
            주의사항, 회사가 통지하는사항을 준수하여야 하며, 회사의 업무에
            방해되는 행위를 하여서는 안됩니다.
          </li>
          <li>
            회원은 회사의 사전 동의없이 서비스를 이용하여 어떠한 영리행위도 할
            수 없으며, 법에 저촉되는 자료를 배포 또는 게재할 수 없습니다.
          </li>
          <li>
            회원은 주소, 고객번호등 자기 신상정보의 변경사항 발생 시 즉각
            변경하여야 합니다. 회원정보를 수정하지 않아 발생하는 모든 결과에
            대한 책임은 회원에게 있습니다.
          </li>
          <li>
            회원은 서비스와 관련하여 다음 사항을 하여서는 아니됩니다.
            <ul className="romanList">
              <li>회원가입 신청 또는 회원정보 변경시 허위 내용의 등록</li>
              <li>서비스에 게시된 정보의 변경</li>
              <li>
                회사가 정한 정보 이외의 정보(컴퓨터프로그램 등)의 송신 또는 게시
              </li>
              <li>다른회원의 ID를 부정하게 사용하는행위</li>
              <li>해킹행위 또는 컴퓨터 바이러스 유포행위</li>
              <li>회사 혹은 기타 제3자의 저작권등 지적 재산권에 대한 침해</li>
              <li>회사의 서비스 운영의 방해</li>
              <li>
                회사 혹은 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              </li>
              <li>
                외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 미풍양속에
                반하는정보를 서비스에 공개 또는 게시하는 행위
              </li>
            </ul>
          </li>
        </ol>
        <b>제 29 조 (회원 ID와비밀번호 관리에대한 회원의 의무와책임)</b>
        <ol>
          <li>
            ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게
            부여된 ID와 비밀번호의 관리 소홀, 부정사용에 의하여 발생하는 모든
            민ㆍ형사상의 책임은 회원에게 있습니다.
          </li>
          <li>
            회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
          </li>
          <li>
            회원은 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을
            인지하는 경우에는 즉시 회사에 통보하여야 하며 회사의 안내가 있는
            경우 그에 따라야 합니다.
          </li>
          <li>
            회원이 제3항에 따라 비밀번호를 변경하지 않거나 회사의 조치에 응하지
            아니하여 발생하는 모든 불이익 및 제2항의 부정 이용에 대한 책임은
            회원에게 있습니다.
          </li>
        </ol>
        <b>제 30 조 (양도금지)</b>
        <p>
          회원은 서비스의 이용권한, 기타 이용계약상 지위를타인에게양도, 증여할
          수없으며 이를담보로제공할 수 없습니다.
        </p>

        <b>제 31 조 (손해배상)</b>
        <ol>
          <li>
            회사가 제공하는 서비스와 관련하여 회원에게 어떠한 손해가
            발생하더라도 회사의 과실에 의한 경우를 제외하고 이에 대하여 책임을
            지지않습니다.
          </li>
          <li>
            회사는 회원이 제28조에 따른 의무를 다하지 않아 회원에게 발생한
            손해에 대해서는 어떠한 경우라도 책임을 지지 않습니다.
          </li>
        </ol>
        <b>제 32 조 (면책사항)</b>
        <ol>
          <li>
            회사는 회원이 서비스를 통해 게재 또는 전송한 정보, 자료, 사실의
            정확성, 신뢰성 등 내용에 관하여 어떠한 보증도 하지 아니하며, 회원의
            서비스 자료에 대한 취사 선택 또는이용으로 발생하는 손해등에 대해
            책임을지지않습니다.
          </li>
          <li>
            회사는 회원이 서비스의 이용과 관련하여 기대하는 이익의 상실이나
            서비스를 통하여 얻은 자료로 인한 손해등에 관하여 책임을 지지
            않습니다.
          </li>
          <li>
            회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한
            분쟁에 대해서는 개입 할 의무가 없으며 이로 인한 손해를 배상할 책임도
            없습니다.
          </li>
          <li>
            회사는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는
            책임이 면제됩니다.
            <br />
            특히, 청구서 E-Mail발송과 관련하여 회원 측 서버의 장애나 문제로
            발생한 청구서 미 수신에 관하여 회사는 책임을지지 않습니다.
          </li>
          <li>
            본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우,
            이 약관을 위반한 회원은 회사에 발생되는 모든 손해를 배상하여야 하며,
            동손해로부터 회사를 면책시켜야 합니다.
          </li>
          <li>
            회사는 서비스에서 제공되는 전자우편의 메시지, 게시판의 게시물 등이
            보유되는 최대 일수, 송수신할 수 있는 전자우편 메시지의 최대 크기
            등을 제한할 수 있습니다.
          </li>
        </ol>

        <b>제 33 조 (분쟁의해결)</b>
        <ol>
          <li>
            회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
            위하여 필요한 모든 노력을 하여야 합니다.
          </li>
          <li>
            제1항의 노력에도 불구하고 분쟁이 해결되지 않을 경우, 양 당사자는
            민사소송법상의 관할법원에 소를 제기할수 있습니다.
          </li>
          <li>동 소송에는 대한민국 법령을 적용합니다.</li>
        </ol>

        <strong>부 칙</strong>
        <p>이 약관은 2024년 8월 2일부터 시행합니다.</p>
      </div>
    </>
  );
}
