import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

export const recentFindDataParamSlice = createSlice({
  name: "recentFindDataParamSlice",
  initialState,
  reducers: {
    setRecentFindData(state, action) {
      return action.payload;
    },
  },
});

export const { setRecentFindData } = recentFindDataParamSlice.actions;
export default recentFindDataParamSlice.reducer;
