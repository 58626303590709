import React, { useEffect, useState } from "react";
import { getIndustryCatg } from "../../service/userRequestService";
import { useTranslation } from "react-i18next";

function SecondCreateAccount({ prop }) {
  const { t } = useTranslation();
  const [viewSelectList, setViewSelectList] = useState(false);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState("Please select a industry");
  const { formData, setFormData, formErrors, setFormErrors, setSecondView } =
    prop;
  useEffect(() => {
    getIndustry();
  }, []);

  const getIndustry = async () => {
    try {
      const response = await getIndustryCatg();
      if (response.status === 200) {
        setCategory(response.data);
      }
    } catch (error) {
      console.error("getIndustryCatg Error: ", error);
    }
  };

  const selectOptionClick = (data) => {
    setViewSelectList(!viewSelectList);
    setTitle(data.cdName);
    setFormData({ ...formData, inputIndustry: data.cdCode });
  };
  return (
    <>
      <div className="accInp">
        <div>
          <label>{t(`SIGNUP.industry`)}</label>
          <div className="selectObj">
            <p
              className={formErrors.inputIndustry ? "error" : "d"}
              onClick={() => {
                setViewSelectList(!viewSelectList);
              }}
            >
              {title && <span>{title}</span>}
              {!title && <span>{t(`SIGNUP.pleaseSelect`)}</span>}
            </p>
            <span className="errorText">{formErrors.inputIndustry}</span>

            <ul style={{ display: viewSelectList ? "block" : "none" }}>
              {category && (
                <>
                  {category.map((data, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        selectOptionClick(data);
                      }}
                    >
                      {data.cdName}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="accInp">
        <div>
          <label>Role</label>
          <p>
            <input type="text" />
          </p>
          <span className="errorText">error</span>
        </div>
      </div> */}
      <div className="accInp">
        <div>
          <label>{t(`SIGNUP.pwd`)}</label>
          <p className={formErrors.inputPassword ? "error" : null}>
            <input
              type="password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  inputPassword: e.target.value,
                });
              }}
            />
          </p>
          <span className="errorText">{formErrors.inputPassword}</span>
        </div>
      </div>
      <div className="accInp">
        <div>
          <label>{t(`SIGNUP.pwdConfirm`)}</label>
          <p className={formErrors.inputConfirmPassword ? "error" : null}>
            <input
              type="password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  inputConfirmPassword: e.target.value,
                });
              }}
            />
          </p>
          <span className="errorText">{formErrors.inputConfirmPassword}</span>
        </div>
      </div>
    </>
  );
}

export default SecondCreateAccount;
