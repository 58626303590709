import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./css/carbonscope2024.css";

import PUBFile from "./components/pub/PUBFile";
import Lnb from "./components/pub/Lnb";
import Lnb2024 from "./components/main2024/Lnb2024";

import { Provider } from "react-redux";
import store from "./util/store";
import routes from "./routes/routes";
import { logOut } from "./util/authUtil";
import Loading from "./Loading";
import { alertData } from "./util/alertData";

// 로그아웃 카운트 전역 변수
let count = 0;

function App() {
  checkDate();
  const timerIdRef = useRef(null);
  useEffect(() => {
    const handleMouseMove = () => {
      // 마우스가 움직이면 count 초기화
      count = 0;
      // 기존 타이머가 있으면 제거
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
      // 1초마다 count 증가하는 타이머 설정
      timerIdRef.current = setInterval(() => {
        count += 1;
        // 1시간 동안 마우스 감지가 없다면 로그아웃 처리(기획 요구사항 반영)
        if (count > 3600) {
          if (localStorage.getItem("user")) {
            logOut();
            alert(alertData.notReaction);
            count = 0;
          }
        }
      }, 1000);
    };

    // 마우스 움직임 이벤트 리스너
    window.addEventListener("mousemove", handleMouseMove);

    // 컴포넌트 언마운트 시 타이머와 이벤트 리스너 제거
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

const Layout = ({ children }) => {
  return (
    <div id="wrap2024">
      <Lnb2024 />
      <div id="ScopeWrap">{children}</div>
    </div>
  );
};

// 퍼블리싱 레이아웃
const PubLayout = ({ children }) => {
  return (
    <div id="wrap2024">
      <Lnb></Lnb>
      <div id="ScopeWrap">{children}</div>
    </div>
  ); // <body> 태그 대신 <div> 사용
};

const AppContent = () => {
  const loadingData = useSelector((state) => state.loadingSlice.viewOption);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loadingData);
  }, [loadingData]);

  const location = useLocation();

  return (
    <>
      {loading ? <Loading /> : null}

      <div className="App">
        {!location.pathname.includes("/pubRoot") && (
          <>
            <Layout>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </Layout>
          </>
        )}

        {/* publishing 컴포넌트 */}
        {location.pathname.includes("/pubRoot") && (
          <PubLayout>
            <Routes>
              <Route
                exact
                path="/pubRoot/:pageID"
                element={<PUBFile pageID={":pageID"} />}
              />
            </Routes>
          </PubLayout>
        )}
      </div>
    </>
  );
};

const checkDate = () => {
  const storageUserData = JSON.parse(localStorage.getItem("user"));
  if (storageUserData) {
    const storageTokenDate = new Date(storageUserData.accessTokenExpiresIn);
    const nowDate = new Date();

    if (nowDate > storageTokenDate) {
      alert(alertData.expirationToken);
      logOut();
    }
  }
};
export default App;
