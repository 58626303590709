import React, { useEffect, useState } from "react";
import {
  deleteEmissionActivityCategoryBookmark,
  insertEmissionActivityCategoryBookmark,
} from "../../service/activityCategoryService";
import { alertData } from "../../util/alertData";

function ActivityCategoryBookmarkList({ dataList, selectedData, userInfo }) {
  const [copyDataList, setCopyDataList] = useState();

  const [th, setTh] = useState([
    {
      title: "No",
      ascSort: true,
    },
    {
      title: "Activity Level1",
      ascSort: true,
    },
    {
      title: "Activity Level2",
      ascSort: true,
    },
    {
      title: "Activity Level3",
      ascSort: true,
    },
    {
      title: "Activity Level4",
      ascSort: true,
    },
    // {
    //   title: "Used Template",
    //   ascSort: true,
    // },
    {
      title: "Factor Count",
      ascSort: true,
    },
    {
      title: ` Bookmark`,
      ascSort: true,
    },
  ]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let splitData;
      let dataArray = dataList;

      for (let i = 0; i < dataArray.length; i++) {
        splitData = dataList[i].factItemAllName.split(" / ");
        dataArray[i].firstActivity = splitData[0];
        dataArray[i].secondActivity = splitData[1];
        dataArray[i].thirdActivity = splitData[2];
        dataArray[i].fourthActivity = splitData[3];
      }

      setCopyDataList(dataArray);
    } else {
      setCopyDataList(dataList);
    }
  }, [dataList]);

  // bookmark 클릭 시
  const onClickBookmark = (data, index) => {
    if (data.dataBookmark === 0) {
      insertBookmark(data, index);
    } else {
      deleteBookmark(data, index);
    }
  };

  // 북마크 등록
  const insertBookmark = async (data, index) => {
    try {
      let copyBookmarkData = [...copyDataList];
      const params = {
        userNo: userInfo.userNo,
        factId: data.factItemId,
        factItemAllName: data.factItemAllName,
      };

      const response = await insertEmissionActivityCategoryBookmark(params);
      if (response.data === 1) {
        alert(alertData.complateBookmark);

        copyBookmarkData[index].dataBookmark = 1;
        setCopyDataList(copyBookmarkData);
      }
    } catch (error) {
      console.error("insertEmissionActivityCategoryBookmark error: " + error);
    }
  };

  // 북마크 해제
  const deleteBookmark = async (data, index) => {
    try {
      let copyBookmarkData = [...copyDataList];
      const response = await deleteEmissionActivityCategoryBookmark(
        data.factItemId,
        userInfo.userNo
      );
      if (response.data === 1) {
        alert(alertData.cancelBookmark);

        copyBookmarkData[index].dataBookmark = 0;
        setCopyDataList(copyBookmarkData);
      }
    } catch (error) {
      console.error("deleteEmissionActivityCategoryBookmark error: " + error);
    }
  };

  return (
    <>
      <div className="dataExpStickyTable">
        <table>
          <colgroup>
            <col width="8%" />
            <col width="19%" />
            <col width="19%" />
            <col width="19%" />
            <col width="19%" />
            {/* <col width="16%" /> */}
            <col width="8%" />
            <col width="8%" />
          </colgroup>
          <thead>
            <tr>
              {th.map((header, index) => (
                <th key={index}>
                  <div>
                    <b>{header.title}</b>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!copyDataList && (
              <tr>
                <td colSpan={8}>
                  <div className="noData">
                    Please select all activity levels of the data to explore
                    more.
                  </div>
                </td>
              </tr>
            )}

            {copyDataList && (
              <>
                {copyDataList.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div>{index + 1}</div>
                    </td>
                    <td>
                      <div>{data?.firstActivity}</div>
                    </td>
                    <td>
                      <div>{data?.secondActivity}</div>
                    </td>
                    <td>
                      <div>{data?.thirdActivity}</div>
                    </td>
                    <td>
                      <div>{data?.fourthActivity}</div>
                    </td>
                    {/* <td>
                      <div>{data?.curtCatgName}</div>
                    </td> */}
                    <td>
                      <div>{data.factorCount}</div>
                    </td>
                    <td>
                      <div>
                        <button
                          className={data.dataBookmark > 0 ? "fav on" : "fav"}
                          onClick={() => {
                            onClickBookmark(data, index);
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ActivityCategoryBookmarkList;
