import React from "react";

const Lnb = () => {
  return (
      <>
          <div id="ScopeLNBWrap">

              <div id="ScopeLNB">
                  <h1>
                      <strong>Carbon API</strong>
                  </h1>
                  <p className="line"><a href="#" className="home" data-link="goTo"><b></b><em>Home</em></a></p>
                  <p className="on"><a href="#" className="produc" data-link="goTo"><b></b><em>Calculate Emissions</em></a></p>
                  <p><a href="#" className="find"  data-link="goTo"><b></b><em>Find Emission Data</em></a></p>
                  <p><a href="#" className="develop"><b></b><em>Integrate with API</em></a></p>
                  <p className="line"><a href="#" className="pricing"><b></b><em>Pricing</em></a></p>
                  <p><a href="#" className="setting"><b></b><em>Setting</em></a></p>
                  <p className="line"><a href="#" className="help"><b></b><em>Help & Support</em></a></p>
                  <div className="btm">
                      <p className="line on"><span className="notifi"><b><u className="new"></u> </b><em>Notifications</em></span></p>
                      <p className="accountWrap"><span className="signin"><b></b><em>Sign in</em></span></p>
                      <p className="accountWrap on"><span className="account"><b>JK</b><em>My Profile</em></span></p>
                  </div>
                  <div className="LNBOpen" id="ProducDetail">
                      <strong><span>Products</span></strong>
                      <ul>
                          <li><b>Emission Calculator</b></li>
                          <li><a href="#" className="on">Calculator Overview</a></li>
                          <li><a href="#">Calculator Explorer</a></li>
                          <li><a href="#">Request Templates</a></li>
                          <li><b>Emission Data</b></li>
                          <li><a href="#">Data Overview</a></li>
                          <li><a href="#">Data Explorer</a></li>
                          <li><a href="#">Data Sources</a></li>
                      </ul>
                  </div>
                  <div className="LNBOpen" id="SettingDetail">
                      <strong><span>Setting</span></strong>
                      <ul>
                          <li><b>Policy</b></li>
                          <li><a href="#">Privacy Policy</a></li>
                          <li><a href="#">Terms of Use</a></li>
                          <li><a href="#">Cookie Policy</a></li>
                          <li><b>Language</b></li>
                          <li><a href="#">Korean</a></li>
                          <li><a href="#">English</a></li>
                      </ul>
                  </div>
                  <div className="LNBOpen" id="NotifiList">
                      <strong><span>Notifications</span></strong>
                      <ul className="notiList">
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                          <li>
                              <div><span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div className="LNBOpen" id="SignInList">
                      <strong><span>My Profile</span></strong>
                      <ul>
                          <li><b>My Bookmark List</b></li>
                          <li><a href="#">Emission Calculator Bookmark</a></li>
                          <li><a href="#" className="on">Emission Activity Category Bookmark</a></li>
                          <li><b>My Private Data</b></li>
                          <li><a href="#">Upload my own Emission Data</a></li>
                          <li><b>My Information</b></li>
                          <li><a href="#">My Account detail</a></li>
                          <li><b>Admin</b></li>
                          <li><a href="#">Request Template</a></li>
                          <li><a href="#">Help & Support</a></li>
                          <li><a href="#">Member List</a></li>
                      </ul>
                      <div className="logout">
                          <button>Logout</button>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
      ;
};

export default Lnb;
