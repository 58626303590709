import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { getScope, getSector } from "../../service/dataOverviewService";
import { useTranslation } from "react-i18next";

export default function HomePieAndBarChart({ Highcharts }) {
  const { t } = useTranslation();
  const [scope, setScope] = useState();
  const [totalScope, setTotalScope] = useState(0);
  const [sector, setSector] = useState();

  useEffect(() => {
    getScopeData();
    getSectorData();
  }, []);
  const getScopeData = async () => {
    const response = await getScope();
    if (response?.status === 200) {
      const responseData = response.data;
      const data = [
        {
          y: responseData.scope1Count,
          name: "Scope 1",
          color: "#4e75ff",
        },
        {
          y: responseData.scope2Count,
          name: "Scope 2",
          color: "#3e5ecc",
        },
        {
          y: responseData.scope3Count,
          name: "Scope 3",
          color: "#2f4699",
        },
        {
          y: responseData.scope9Count,
          name: "Out of scope",
          color: "gray",
        },
      ];

      setScope(data);
      setTotalScope(
        Number(
          responseData.scope1Count +
            responseData.scope2Count +
            responseData.scope3Count +
            responseData.scope9Count
        ).toLocaleString("ko-KR", { maximumFractionDigits: 4 })
      );
    } else {
      return;
    }
  };

  const getSectorData = async () => {
    const response = await getSector();
    if (response?.status === 200) {
      const responseData = response.data;
      let otherCount = 0;
      let val = [];
      const color = ["#EF9D00", "#4E75FF", "#40C0DC", "#C00000", "#F45C5C"];
      responseData.map((data) => {
        if (data.scopeId === "S3") {
          if (val.length < 5) {
            val.push({
              data: [data.sectorCount],
              name: data.catgName,
              color: color[val.length],
            });
          } else {
            otherCount += data.sectorCount;
          }
        }
      });

      val.push({ data: [otherCount], name: "Other Sectors", color: "gray" });

      // 배열을 역순으로 정렬
      val.reverse();

      setSector(val);
    } else {
      return;
    }
  };

  const chart1option = {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: "260px",
      width: 460,
      align: "center",
    },
    title: {
      text: null,
    },
    subtitle: {
      useHTML: true,
      text:
        `<span style="display: block;text-align: center;font-size: 20px;color: #000;">Total</span><b style="display: block;text-align: center;font-size: 33px;font-weight: 800;color: #000;">` +
        totalScope +
        `</b>`,
      floating: true,
      verticalAlign: "middle",
      align: "center",
      x: 80,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      align: "left",
      verticalAlign: "middle",
      labelFormat:
        '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
      layout: "vertical",
      itemStyle: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "700",
        textOverflow: "ellipsis",
      },
      itemMarginBottom: 15,
      x: 0,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 180,
      },
      series: {
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        data:
          scope?.map((data) => ({
            y: data.y,
            name: data.name,
            color: data.color,
          })) || [],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  const chart3option = {
    chart: {
      backgroundColor: "transparent",
      type: "bar",
      height: 250,
      spacingBottom: 150,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [""],
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      gridLineWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        borderWidth: 0,
        height: "40px",
      },
    },
    series: sector,
    legend: {
      verticalAlign: "top",
      floating: true,
      margin: 0,
      padding: 0,
      align: "center",
      reversed: true,
      // labelFormat:
      //   '<span style="color:{color}">{name}</span> : <b> {yData:,.0f} </b>',
      // 천 단위 콤마 포맷 설정
      labelFormatter: function () {
        const formattedNumber = new Intl.NumberFormat().format(this.yData[0]);
        return `<span style="color:${this.color}">${this.name}</span> : <b> ${formattedNumber} </b>`;
      },
      itemStyle: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "500",
        textOverflow: "ellipsis",
      },
      itemMarginBottom: 5,
      y: 90,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
  return (
    <>
      <div className="basicChart">
        <div className="cbSpBox">
          <strong>{t("HOME.ourData")}</strong>
          <HighchartsReact highcharts={Highcharts} options={chart1option} />
        </div>
        <div className="cbSpBox">
          <strong>{t("HOME.major")}</strong>
          <HighchartsReact highcharts={Highcharts} options={chart3option} />
        </div>
      </div>
    </>
  );
}
