import React, { useEffect, useState } from "react";
import { formatDate } from "../../util/common";
import { getActivityCategoryCount } from "../../service/dataExplorerService";
import { useTranslation } from "react-i18next";
const colWidth = [
  "7%",
  "7%",
  "8%",
  "6%",
  "12%",
  "",
  "15%",
  "8%",
  "9%",
  "8%",
  "8%",
];

function DataExplorerList({ dataList, selectedData, userInfo }) {
  const { t } = useTranslation();
  const [copyDataList, setCopyDataList] = useState();

  // table header
  const [th, setTh] = useState([
    {
      title: "Scope",
      ascSort: true,
    },
    {
      title: "Region",
      ascSort: true,
    },
    {
      title: "Data year",
      ascSort: true,
    },
    {
      title: "Unit",
      ascSort: true,
    },
    {
      title: "GHG/Unit",
      ascSort: true,
    },
    {
      title: "GHG Conversion Factor",
      ascSort: true,
    },
    {
      title: "Source",
      ascSort: true,
    },
    {
      title: `Last\nUpdate`,
      ascSort: true,
    },
    {
      title: `Calculation\nmethod`,
      ascSort: true,
    },
    {
      title: ` LCA\nActivity`,
      ascSort: true,
    },
    {
      title: ` Bookmark`,
      ascSort: true,
    },
  ]);
  // factor data view control
  const [bookmarkStateControl, setBookmarkStateControl] = useState([]);
  const [sortMapping, setSortMapping] = useState([
    { key: "", asc: true },
    { key: "nationId", asc: true },
    { key: "yyyy", asc: true },
    { key: "unit", asc: true },
    { key: "gasId", asc: true },
    { key: "factorVal", asc: true },
    { key: "srcInfo", asc: true },
    { key: "chgDtm", asc: true },
    { key: "rawCaculationMethod", asc: true },
    { key: "", asc: true },
  ]);
  useEffect(() => {
    if (dataList?.length > 0) {
      // factor / bookmark control init
      initData();
    } else {
      setCopyDataList(null);
    }
  }, [dataList]);

  function initData() {
    dataList.forEach((data) => {
      let date = formatDate(data.chgDtm);
      data.chgDtm = date;
      data.factorVal = Number(data.factorVal);
      data.viewControl = false;
    });
    setCopyDataList(dataList);
    temporaryBookmarkSettings(); // 임시(카테고리 북마크 데이터 모두 북마크 표시)
  }

  // 임시(카테고리 북마크 데이터 모두 북마크 표시)
  function temporaryBookmarkSettings() {
    let factItemId;

    for (let i = 4; i > 0; i--) {
      if (selectedData[`selectedData${i}`]) {
        factItemId = selectedData[`selectedData${i}`].factItemId;
        tempCategoryCount(factItemId);
        return;
      }
    }
  }

  const tempCategoryCount = async (factItemId) => {
    let check = false;
    try {
      const response = await getActivityCategoryCount(
        factItemId,
        userInfo.userNo
      );
      if (response.status === 200) {
        if (response?.data === 1) {
          check = true;
        } else {
          check = false;
        }
      }

      const initBookmarkStateArray = new Array(dataList.length).fill(check);
      setBookmarkStateControl(initBookmarkStateArray);
    } catch (error) {
      console.error(error);
    }
  };

  // 헤더 정렬
  const headerSortClick = (index) => {
    let cpData = structuredClone(copyDataList);
    if (cpData) {
      let thData = [...th];
      thData[index].ascSort = !thData[index].ascSort;
      setTh(thData);

      let data = [...sortMapping];
      data[index].asc = !sortMapping[index].asc;
      setSortMapping(data);
      const { key, asc } = sortMapping[index];

      if (asc) {
        cpData = cpData.sort((a, b) => {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
        setCopyDataList(cpData);
      } else {
        cpData = cpData.sort((a, b) => {
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
          return 0;
        });
        setCopyDataList(cpData);
      }
    }
  };

  // 데이터 체크 클릭 시
  const onClickViewFactor = (index) => {
    let cpData = structuredClone(copyDataList);
    cpData[index].viewControl = true;
    setCopyDataList(cpData);
  };

  // bookmark 클릭 시
  const onClickBookmark = (index) => {
    updateBookmark(index);
  };

  // (임시)북마크 기능을 막는다.
  const updateBookmark = async (index) => {
    // let copyBookmarkData = [...bookmarkStateControl];
    // copyBookmarkData[index] = !copyBookmarkData[index];
    // setBookmarkStateControl(copyBookmarkData);
  };
  return (
    <>
      <div className="dataExpStickyTable">
        <table>
          <colgroup>
            {colWidth.map((data, index) => (
              <col width={data} key={index} />
            ))}
          </colgroup>

          {/* table herder */}
          <thead>
            <tr>
              {th.map((header, index) => (
                <th key={index}>
                  <div>
                    <b>{header.title}</b>
                    {index === 0 ? null : index === 10 ? null : (
                      <span
                        className={
                          header.ascSort ? "orderby asc" : "orderby desc"
                        }
                        onClick={() => {
                          headerSortClick(index);
                        }}
                      ></span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!copyDataList && (
              <tr>
                <td colSpan={11}>
                  <div className="noData">{t(`EXPLORERDATA.pleaseSelect`)}</div>
                </td>
              </tr>
            )}

            {copyDataList && (
              <>
                {copyDataList.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {/* <div>{index + 1}</div> */}
                      <div>
                        {data.rawFactorId.includes("-S1-")
                          ? "S1"
                          : data.rawFactorId.includes("-S2-")
                          ? "S2"
                          : data.rawFactorId.includes("-S3-")
                          ? "S3"
                          : data.rawFactorId.includes("-S9-")
                          ? "S9"
                          : null}
                      </div>
                    </td>
                    <td>
                      <div>{data.nationId}</div>
                    </td>
                    <td>
                      <div>{data.yyyy}</div>
                    </td>
                    <td>
                      <div>{data.unit}</div>
                    </td>
                    <td>
                      <div>kgCO2e of {data.gasId}</div>
                    </td>
                    <td>
                      {/* <div className="right"> */}
                      <div
                        onClick={() => {
                          onClickViewFactor(index);
                        }}
                      >
                        {/* {viewFactorStateControl[index] === true ? ( */}
                        {data.viewControl === true ? (
                          <span className="chkData on">{data.factorVal}</span>
                        ) : (
                          <span className="chkData">Check Data</span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>{data.srcInfo}</div>
                    </td>
                    <td>
                      <div>{data.chgDtm}</div>
                    </td>
                    <td>
                      <div>{data.rawCaculationMethod}</div>
                    </td>
                    <td>
                      <div>--</div>
                    </td>
                    <td>
                      <div>
                        <button
                          className={
                            bookmarkStateControl[index] ? "fav on" : "fav"
                          }
                          onClick={() => {
                            onClickBookmark(index);
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DataExplorerList;
