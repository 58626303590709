import React from "react";

export default function Privacy2024KOR() {
  return (
    <>
      <div className="policyCont kor">
        <strong>제 1 장 총칙</strong>
        <b>제 1 조 (목적)</b>
        <p>
          이 약관은 주식회사 띵스파이어(이하 “회사”라 합니다)에서 제공하는
          띵스파이어 Carbon API 서비스 (접속 가능한 유•무선단말기의종류와
          관계없이 회사가 제공하는이용가능한 모든 서비스를 의미하며, 이하
          "서비스"라 합니다)를 이용함에있어, 이용조건 및 절차에 관한 사항,
          회사와 이용자의권리와 의무 및 책임에 관한 사항 등을 규정함을
          목적으로합니다.
        </p>
        <ol>
          <li>
            개인정보의 수집항목 및 수집방법
            <p>
              (주)띵스파이어의 띵스파이어 Carbon API 서비스에서는 기본적인 회원
              서비스 제공을 위한 필수정보와 정보주체 각각의 기호와 필요에 맞는
              서비스를 제공하기 위한 선택정보로 구분하여 다음의 정보를 수집 하고
              있습니다. 선택정보를 입력하지 않은 경우에도 서비스 이용에는 제한이
              없습니다. 또한 (주)띵스파이어 띵스파이어 Carbon API 서비스에서는
              정보주체의 기본적 인권 침해의 우려가 있는 민감한 개인
              정보(사상·신념, 노동조합·정당의 가입·탈퇴, 정치적 견해, 건강,
              성생활, 유전정보 및 범죄 경력에 관한 정보 등)는 수집하지 않습니다.
            </p>
            <ul>
              <li>
                가. 수집하는 개인정보의 항목 / &lt;법인·기업·단체 회원 가입&gt;
                필수항목
                <ul className="dotList">
                  <li>가입정보: 사업자등록번호, 이메일, 비밀번호</li>
                  <li>
                    회원정보관리: 기관단체명, 전화번호, 팩스번호, 기관단체주소,
                    대표자명, 업종 및 업태, 설립년도, 근로자수, 주생산품, 연간
                    가동일수, 홈페이지 주소, 담당자명, 담당자 전화번호, 담당자
                    이메일
                    <br />
                    ㄴ&lt;컴퓨터에 의해 자동으로 수집되는 정보&gt;
                  </li>
                  <li>
                    홈페이지를 이용할 경우 다음 정보는 로그인 기록을 통하여
                    자동적으로 수집·저장됩니다.
                  </li>
                  <li>
                    이용자 여러분의 인터넷서버 도메인과 홈페이지를 방문할 때
                    거친 웹사이트의 주소
                  </li>
                  <li>이용자의 브라우저 종류 및 OS</li>
                  <li>
                    방문일시, 쿠키 등 &lt;연계시스템으로부터 수집되는 정보 및
                    제3자 제공 정보&gt; 필수항목
                  </li>
                  <li>
                    가입 시 입력한 개인정보를 통하여 연계기관(시스템)별로
                    에너지사용량이 수집·저장됩니다.
                  </li>
                </ul>
              </li>
              <li>
                나. 개인정보 수집방법
                <ul className="dotList">
                  <li>홈페이지 회원가입을 통한 수집</li>
                  <li>
                    가입회원 개인정보를 이용하여 연계기관(시스템)을 통한 수집
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <b>제 2조 개인정보의 수집 이용목적 및 보유 이용기간</b>
        <p>
          (주)띵스파이어의 Carbon API 서비스에서는 정보주체의 회원가입일로부터
          서비스를 제공하는 기간 동안에 한하여 (주)띵스파이어 Carbon API
          서비스를 이용하기 위한 최소한의 개인정보를 보유 및 이용하게 됩니다.
          회원가입 등을 통해 개인정보의 수집·이용, 제공 등에 대해 동의하신
          내용은 언제든지 철회하실 수 있습니다. 회원 탈퇴를 요청하거나
          개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집/이용목적을
          달성하거나 보유/이용기간이 종료한 경우, 사업 폐지 등의 사유발생시 당해
          개인정보를 지체없이 파기합니다.
        </p>
        <b>제 3조 개인정보의 열람, 갱신, 수정 또는 삭제</b>
        <p>
          회원님의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
          지체 없이 파기합니다.
        </p>
        <ol>
          <li>
            회원님께서는 언제든지 등록되어 있는 회원님의 개인정보를 열람하거나
            수정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는
            회사 홈페이지 로그인 후 "마이페이지&gt;회원정보수정" 항목을 클릭하여
            직접 열람 또는 정정하거나, 개인정보 보호책임자 및
            고객서비스담당부서에 서면, 전화 또는 이메일로 연락하시면 지체 없이
            조치하겠습니다.
          </li>
          <li>
            회원님께서 직접 권리행사를 하실 수 없는 경우에는 법정대리인이나
            위임을 받은 자 등 대리인을 통하여 하실 수 있으며, 이 경우 개인정보
            보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.{" "}
          </li>
          <li>
            회원님께서 개인정보의 오류에 대한 정정을 요청한 경우, 정정을
            완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.
          </li>
          <li>
            잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
            제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.
          </li>
        </ol>
        <b>제 4조 개인정보의 동의철회</b>
        <ol>
          <li>
            회원가입 등을 통한 개인정보의 수집, 이용, 제공에 대하여 회원은 가입
            시 동의한 내용에 대해서 언제든지 철회할 수 있습니다.
          </li>
          <li>
            동의 철회는 당사의 개인정보 민원담당자에게 서면, 전화, 이메일 등으로
            연락하시면 신속하게 개인정보의 삭제 등 필요한 조치를 합니다.
          </li>
          <li>
            회원님이 자발적으로 회원 탈퇴한 후 회원 재가입, 임의해지 등을
            반복적으로 행하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등으로
            경제적 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 등
            편법과 불법 행위를 하는 회원을 차단하고자 회사는 회원님의 일부
            개인정보를 탈퇴 후 7일이 지난 후 파기 합니다.
          </li>
          <li>
            이미 제공된 회원정보를 철회하는 데는 일정 시간이 소요되며, 소요되는
            시간 동안 마케팅에 활용되지 않도록 하겠습니다. 회사는 회원님의
            소중한 정보를 보호하기 위하여 신속하게 처리되도록 최선의 노력을
            다하겠습니다.
          </li>
        </ol>
        <b>제 5조 개인정보의 제3자 제공 및 위탁에 관한 사항</b>
        <p>
          회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며,
          관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록
          필요한 사항을 규정하고 있습니다. 현재 회사의 개인정보 위탁처리 기관 및
          위탁업무 내용은 다음과 같습니다.
        </p>
        <ol>
          <li>
            회사는 회원님의 동의가 있거나 법률의 규정에 의한 경우를 제외하고는
            어떠한 경우에도 회원님의 개인정보를 이용하거나 타인 또는 타기업,
            기관에 제공하지 않습니다.
          </li>
          <li>
            회사는 모임 참여 시 개설자, 상품 구매 시 상품공급자에게 개인정보를
            제공할 수 있습니다. (개인정보의 제 3자 제공)
          </li>
          <li>
            회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며,
            관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록
            필요한 사항을 규정하고 있습니다. 현재 회사의 개인정보 위탁처리 기관
            및 위탁업무 내용은 다음과 같습니다. 개인정보 제3자 제공 현황 보기
          </li>
          <li>
            회사는 회원님의 개인정보를 해당약관에 고지한 범위 내에서 사용하며,
            회원님의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
            이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의
            경우에는 예외로 합니다.
            <ul className="alphaList">
              <li>이용자들이 제3자 제공에 동의한 경우</li>
              <li>
                법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
                방법에 따라 수사기관의 요구가 있는 경우
              </li>
            </ul>
          </li>
          <li>
            회사가 제공하는 서비스를 통하여 주문 및 결제가 이루어진 경우 상담 등
            거래 당사자간 원활한 의사소통과 배송 및 모임 등 거래이행을 위하여
            관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공합니다.
          </li>
        </ol>
        <b>제 6조 회원의 권리와 그 행사 방법</b>
        <ol>
          <li>
            회원님은 언제든지 등록되어 있는 본인의 개인정보를 조회하거나 수정할
            수 있으며 회원 탈퇴 절차를 통하여 개인정보 이용에 대한 동의 등을
            철회할 수 있습니다.
          </li>
          <li>
            개인정보의 조회/수정을 위해서는 사이트의 [회원정보관리] 항목에서
            확인 가능하며, 가입 해지(동의철회)는 [회원정보관리] 하단 메뉴
            '탈퇴하기'를 통하여 탈퇴하실 수 있습니다. 이 외에도 회사의 개인정보
            보호책임자에게 서면, 전화 또는 이메일로 연락하여 다음 각 호의
            개인정보 보호 관련 권리를 행사 할 수 있습니다.
            <ul className="alphaList">
              <li>개인정보 열람 요구</li>
              <li>오류 등이 있을 경우 정정 요구</li>
              <li>삭제의 요구</li>
              <li>처리정지 요구</li>
            </ul>
          </li>
          <li>
            회원님이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
            완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 회사는
            회원님의 요청에 의해 해지 또는 삭제된 개인정보는 제2조에 명시된 바에
            따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
            있습니다.
          </li>
        </ol>
        <b>제 7조 아동의 개인정보보호</b>
        <ol>
          <li>
            만 14세 미만의 아동의 개인정보를 보호하기 위하여 회원가입은 만14세
            이상에만 허용하여 아동의 개인정보를 수집하지 않습니다.
          </li>
        </ol>
        <b>제 8조 회원의 의무</b>
        <ol>
          <li>
            회원님은 자신의 개인정보를 보호할 의무가 있으며, 회사의 책임 없는
            사유로 발생한 문제에 대하여 회사는 일체의 책임을 지지 않습니다.
          </li>
          <li>
            회원님의 개인정보를 최신의 상태로 정확하게 입력하시기 바랍니다.
            회원님의 부정확한 정보입력으로 발생하는 문제의 책임은 회원님
            자신에게 있으며, 타인의 주민등록번호 등 개인정보를 도용하여 서비스
            이용 시 회원자격 상실과 함께 주민등록법에 의거하여 처벌될 수
            있습니다.
          </li>
          <li>
            회원님은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의
            정보를 침해하지 않을 의무도 가지고 있습니다. 아이디, 비밀번호를
            포함한 회원님의 개인정보가 유출되지 않도록 조심하시고 게시물을
            포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오.
          </li>
          <li>
            회원님은 『정보통신망이용촉진및정보보호등에관한법률』,
            개인정보보호법, 주민등록법 등 기타 개인정보에 관한 법률을 준수하여야
            합니다.
          </li>
        </ol>
        <b>제 9조 링크 사이트</b>
        <p>
          회사는 회원님께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수
          있습니다. 이 경우 회사는 외부 웹사이트 및 자료에 대한 아무런 통제권이
          없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수
          없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 통하여 타
          웹사이트의 페이지로 옮겨갈 경우 해당 웹사이트의 개인정보처리방침은
          회사와 무관하므로 새로 방문한 웹사이트의 정책을 검토 해보시기
          바랍니다.
        </p>

        <b>
          제 10조 개인정보 자동수집 장치(쿠키 등)의 설치, 운영 및 그 거부에 관한
          사항
        </b>
        <ol>
          <li>
            회사는 회원님께 보다 적절하고 유용한 서비스를 제공하기 위하여
            회원님의 정보를 수시로 저장하고 불러오는 ‘쿠키(cookie)’를
            사용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가
            회원님의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 회원님의 컴퓨터
            하드디스크에 저장됩니다. 회원님께서는 쿠키의 사용여부에 대하여
            선택하실 수 있습니다. 단, 쿠키를 사용하지 않아 생기는 서비스 사용의
            문제 및 제한은 회사가 책임 지지 않습니다.
          </li>
          <li>
            쿠키 설정 거부 방법
            <br />- 회원님은 사용하시는 웹 브라우저의 옵션을 설정함으로써 모든
            쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의
            저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이
            필요한 일부 서비스의 이용에 제한이 생길 수 있음을 양지하시기
            바랍니다.
          </li>
        </ol>
        <b>제 11조 개인정보의 기술적/관리적 보호 대책</b>
        <p>
          회사는 회원님의 개인정보를 보호하기 위하여 다음과 같은 보호 대책을
          시행하고 있습니다.
        </p>
        <ol>
          <li>
            비밀번호의 암호화
            <p>
              회원님의 비밀번호는 암호화되어 저장 및 관리되고 있습니다.
              비밀번호는 오직 회원 본인만이 알 수 있으며 개인정보를 확인 및
              변경할 경우에도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
              따라서 회원님의 비밀번호가 타인에게 알려지지 않도록 각별히
              주의하시기 바랍니다.
            </p>
          </li>
          <li>
            해킹 및 컴퓨터 바이러스 등에 대비
            <p>
              회사는 해킹이나 컴퓨터 바이러스에 의하여 회원님들의 개인정보가
              유출되거나 훼손되는 것을 막기 위하여 필요한 보안조치를 이용하고
              있으며, 더욱 향상된 보안조치를 확보할 수 있도록 가능한 모든 기술적
              방법을 구비하기 위하여 노력하고 있습니다.
            </p>
          </li>
          <li>
            개인정보 처리자의 제한 및 교육
            <p>
              회사는 개인정보를 처리하는 직원을 최소한으로 제한하고 있으며, 관련
              직원들에 대한 교육을 수시로 실시하여 본 방침의 이행 및 준수여부를
              확인하고 있습니다.
            </p>
          </li>
        </ol>
        <b>제 12조 개인정보보호책임자 안내</b>
        <ol>
          <li>
            회사는 회원님께서 양질의 정보를 안전하게 이용하실 수 있도록 최선을
            다하고 있습니다. 개인정보를 보호하는데 있어 회원님께 고지한 사항들에
            반하는 사고가 발생할 시에 회사가 모든 책임을 집니다. 그러나 기술적인
            보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에
            의해 발생하는 예기치 못한 사고로 인한 정보의 훼손에 관해서는 책임이
            없습니다. 회원님의 개인정보를 처리하는 책임자는 다음과 같으며
            개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.
            <ul>
              <li>[개인정보보호책임자]</li>
              <li>- 성명 : 신봉조</li>
              <li>- 소속/직책 : 이사</li>
              <li>- 메일 : shinbong@thingspire.com</li>
              <li>- 전화번호: 02-6265-1945</li>
            </ul>
          </li>
          <li>
            회사는 회원님의 의견을 소중하게 생각하며, 회원님은 의문사항으로부터
            언제나 성실한 답변을 받을 권리가 있습니다.
          </li>
          <li>
            회사는 회원님과의 원활환 의사소통을 위해 고객서비스담당 부서를
            운영하고 있으며 연락처는 다음과 같습니다.
            <ul>
              <li>[고객서비스담당 부서: 운영팀]</li>
              <li>- 메일 : sjchoi@thingspire.com</li>
              <li>- 전화번호: 02-6265-1945</li>
            </ul>
          </li>
          <li>전화상담은 평일 10:00 ~ 17:00까지 가능합니다.</li>
          <li>
            이메일이나 팩스 및 우편을 이용한 상담은 접수 후 24시간 내에 성실하게
            답변 드리겠습니다. 다만, 근무시간 이후 또는 주말 및 공휴일에는 익일
            처리하는 것을 원칙으로 합니다.
          </li>
          <li>
            기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터,
            개인정보분쟁조정위원회, 대검찰청 사이버수사과, 경찰청 사이버안전국
            등으로 문의하실 수 있습니다.
            <ul>
              <li>* 개인정보침해신고센터</li>
              <li>- 전화 : (국번없이) 118</li>
              <li>- URL : https://privacy.kisa.or.kr</li>
            </ul>
            <ul>
              <li>* 개인정보분쟁조정위원회</li>
              <li>- 전화 : 1833-6972</li>
              <li>- URL : https://www.kopico.go.kr</li>
            </ul>
            <ul>
              <li>* 경찰청 사이버안전국</li>
              <li>- 전화 : (국번없이) 182</li>
              <li>- URL : https://cyberbureau.police.go.kr</li>
            </ul>
            <ul>
              <li>* 대검찰청 사이버수사과</li>
              <li>- 전화 : (국번없이) 1301</li>
              <li>- URL : http://www.spo.go.kr</li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
}
