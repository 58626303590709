import React from "react"

function Find(){
    return (
        <>
            <div className="pageTitle">
                Emission Data Explorer
            </div>
            <div className="cbSpBox">
                <div className="findKeyword">
                    <div>
                        <input type="text" placeholder="Search by keywords" />
                    </div>
                </div>

                <div className="findGrid">
                    <dl className="filter">
                        <dt>Filter</dt>
                        <dd>
                            <div className="selectObj">
                                <p><span>Scope</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="selectObj">
                                <p><span>Region</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="selectObj">
                                <p><span>Data year</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="selectObj">
                                <p><span>Unit</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="selectObj">
                                <p><span>Source</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="selectObj">
                                <p><span>GWP100</span></p>
                                <ul>
                                    <li>Scope1</li>
                                    <li>Scope2</li>
                                </ul>
                            </div>
                            <div className="ResetBtn">
                                <button>
                                    <span>Reset Options</span>
                                </button>
                            </div>

                        </dd>
                    </dl>
                    <div className="dataExpStickyTable">
                        <table>
                            <colgroup>
                                <col width="12%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="17%"/>
                                <col width="15%"/>
                                <col width="10%"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>
                                    <div>
                                        <b>Activity Level1</b>
                                    </div>

                                </th>
                                <th>
                                    <div>
                                        <b>Activity Level2</b>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <b>Activity Level3</b>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <b>Activity Level4</b>
                                    </div>
                                </th>
                                <th>
                                    <div className="right">
                                        <b>Emission Factor</b>
                                    </div>
                                </th>
                                <th>
                                    <div className="right">
                                        <b>LCA Activity</b>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <b>Bookmark</b>
                                    </div>

                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan={7}>
                                    <div className="noData">Search and explore emission data.</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Fuels</div>
                                </td>
                                <td>
                                    <div>Gaseous Fuels</div>
                                </td>
                                <td>
                                    <div>Liquified Natural Gas (LNG)</div>
                                </td>
                                <td>
                                    <div>Manufacturing Industries</div>
                                </td>
                                <td>
                                    <div className="right"><span className="chkData on">3.12E-2 kgCO2e of CO2</span></div>
                                </td>
                                <td>
                                    <div className="right">--</div>
                                </td>
                                <td>
                                    <div className="center">
                                        <button className="fav on"></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</div>
                                </td>
                                <td>
                                    <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</div>
                                </td>
                                <td>
                                    <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</div>
                                </td>
                                <td>
                                    <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</div>
                                </td>
                                <td>
                                    <div className="right"><span className="chkData">Check Data</span></div>
                                </td>
                                <td>
                                <div className="right">--</div>
                                </td>
                                <td>
                                    <div className="center">
                                        <button className="fav on"></button>
                                    </div>
                                </td>
                            </tr>
                            {[...Array(10)].map((value, index) => {
                                let fav = index === 1 ? "fav on" : "fav";
                                return (
                                    <tr>
                                        <td>
                                            <div>Fuels</div>
                                        </td>
                                        <td>
                                            <div>Gaseous Fuels</div>
                                        </td>
                                        <td>
                                            <div>Liquified Natural Gas (LNG)</div>
                                        </td>
                                        <td>
                                            <div>Manufacturing Industries</div>
                                        </td>
                                        <td>
                                            <div className="right"><span className="chkData on">3.12E-2 kgCO2e of CO2</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="right">--</div>
                                        </td>
                                        <td>
                                            <div className="center">
                                                <button className="fav"></button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Find;