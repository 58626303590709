import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  getIndustryCatg,
  getMyInfo,
} from "../../../service/userRequestService";
import AccountEdit2024 from "./AccountEdit2024";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../util/common";

function AccountInfo2024() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [myInfo, setMyInfo] = useState(null);
  const [industryList, setIndustryList] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const userJSON = localStorage.getItem("user");
  let userInfo = null;
  const navigate = useNavigate();
  if (userJSON) {
    userInfo = jwt_decode(JSON.parse(userJSON)?.accessToken);
  }

  useEffect(() => {
    if (userInfo) {
      searchMyInfo();
      searchIndustryList();
    } else {
      navigate("/login2024");
    }
  }, []);

  const searchMyInfo = async () => {
    try {
      const response = await getMyInfo(userInfo.userNo);
      if (response.status === 200) {
        setMyInfo(response.data);
      }
    } catch (error) {
      console.error("getMyInfo Error:", error);
    }
  };

  const searchIndustryList = async () => {
    try {
      const response = await getIndustryCatg();
      if (response.status === 200) {
        setIndustryList(response.data);
      }
    } catch (error) {
      console.error("getIndustryCatg Error:", error);
    }
  };

  const dateStringFormat = (originalDate) => {
    const [year, month, day] = originalDate.split(".");
    const date = new Date(year, month - 1, day);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  return (
    <>
      <div className="pageTitle">{t(`MYINFO.accDetail`)}</div>
      <div className="accountDetail">
        <div className="left">
          <div className="cbSpBox">
            <div className="boxTitle">{t(`MYINFO.genInfo`)}</div>
            {!editMode && (
              <div className="boxCont">
                <ul>
                  <li>
                    <span>{t(`MYINFO.email`)}</span>
                    <b>{myInfo?.email}</b>
                  </li>
                  <li>
                    <span>{t(`MYINFO.firstName`)}</span>
                    <b>{myInfo?.firstName}</b>
                  </li>
                  <li>
                    <span>{t(`MYINFO.lastName`)}</span>
                    <b>{myInfo?.lastName}</b>
                  </li>
                  <li>
                    <span>{t(`MYINFO.company`)}</span>
                    <b>{myInfo?.companyName}</b>
                  </li>
                  <li>
                    <span>{t(`MYINFO.industry`)}</span>
                    <b>{myInfo?.cdName}</b>
                  </li>
                  <li>
                    <span>{t(`MYINFO.role`)}</span>
                    <b>{myInfo?.userRole}</b>
                  </li>
                </ul>
                <button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {t(`MYINFO.edit`)}
                </button>
              </div>
            )}
            {editMode && (
              <AccountEdit2024
                prop={{
                  myInfo: myInfo,
                  setMyInfo: setMyInfo,
                  setEditMode: setEditMode,
                  industryList: industryList,
                }}
              />
            )}
          </div>
        </div>

        {myInfo && (
          <div className="right">
            <div className="cbSpBox">
              <div className="boxTitle">{t(`MYINFO.apiKey`)}</div>
              <div className="boxCont">
                {myInfo?.cbUserSubscribeNm !== "1" ? (
                  <>
                    <p>{t(`MYINFO.notShareApi`)}</p>
                    <div className="key">{myInfo?.appKey}</div>
                    <div className="date">
                      {t(`MYINFO.issued`)}{" "}
                      <b>{dateStringFormat(formatDate(myInfo?.startDtm))}</b>
                    </div>
                  </>
                ) : (
                  <div className="noData">{t(`MYINFO.noData`)}</div>
                )}
              </div>
            </div>

            <div className="cbSpBox">
              <div className="boxTitle">{t(`MYINFO.subStatus`)}</div>
              <div className="boxCont">
                {myInfo?.cbUserSubscribeNm !== "1" ? (
                  <>
                    <ul>
                      <li>
                        <span>{t(`MYINFO.subPlan`)}</span>
                        <b>
                          {myInfo?.cbUserSubscribeNm}{" "}
                          {myInfo?.cbUserSubscribeNm === "3"
                            ? "($10,000)"
                            : myInfo?.cbUserSubscribeNm === "2"
                            ? "($1,000)"
                            : null}
                        </b>
                      </li>
                      <li>
                        <span>{t(`MYINFO.subPeriod`)}</span>
                        <b>
                          {myInfo?.endDtm && (
                            <>
                              {formatDate(myInfo?.startDtm)} -
                              {formatDate(myInfo?.endDtm)}
                            </>
                          )}
                        </b>
                      </li>
                    </ul>
                    <button
                      onClick={() => {
                        nav("/price2024");
                      }}
                    >
                      {t(`MYINFO.chgSub`)}
                    </button>
                  </>
                ) : (
                  <div className="noData">{t(`MYINFO.noData`)}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountInfo2024;
