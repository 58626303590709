import React from "react";

export default function Privacy2024ENG() {
  return (
      <>
          <div className="policyCont eng">
              <ol>
                  <li>
                      <b>Introduction</b>
                      [Thingspire] ("Company," "we," "us," or "our") respects your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Thingspire Carbon API platform ("Service"), accessible through our website [carbonscope.io] ("Site"). Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
                  </li>
                  <li>
                      <b>Information We Collect</b>
                      <ol>
                          <li>
                              <b>Personal Data</b>
                              We may collect and process the following types of personal data:
                              <ul>
                                  <li><b>Identity Information: </b>Name, username, date of birth, and gender.</li>
                                  <li><b>Contact Information: </b>Email address, phone number, and mailing address.</li>
                                  <li><b>Account Information: </b>Username, password, and other authentication data.</li>
                                  <li><b>Payment Information: </b>Payment card details, billing address, and transaction history.</li>
                                  <li><b>Usage Data: </b>Information about how you use our Service, including traffic data, location data, logs, and other communication data.</li>
                              </ul>
                          </li>
                          <li>
                              <b>Non-Personal Data</b>
                              We may also collect non-personal data that cannot be used to identify you, such as browser type, operating system, and website usage patterns.
                          </li>
                      </ol>
                  </li>
                  <li>
                      <b>How We Use Your Information</b>
                      We use the information we collect for the following purposes:
                      <ul>
                          <li><b>To Provide and Maintain the Service: </b>To set up and manage your account, provide customer support, and process transactions.</li>
                          <li><b>To Improve Our Service: </b>To understand how users interact with our Service, personalize user experiences, and enhance features.</li>
                          <li><b>To Communicate with You: </b>To send you updates, promotional materials, and information about changes to our terms, policies, or other important notices.</li>
                          <li><b>To Ensure Security and Compliance: </b>To protect our users, detect and prevent fraud, and comply with legal obligations.</li>
                      </ul>
                  </li>
                  <li>
                      <b>Disclosure of Your Information</b>
                      We may share your personal data with third parties in the following circumstances:
                      <ul>
                          <li><b>Service Providers: </b>We may share information with third-party vendors who perform services on our behalf, such as payment processing, data analysis, email delivery, and customer service.</li>
                          <li><b>Legal Requirements: </b>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                          <li><b>Business Transfers: </b>In the event of a merger, acquisition, or asset sale, your personal data may be transferred to the acquiring entity.</li>
                          <li><b>With Your Consent: </b>We may share your information with other parties with your consent.</li>
                      </ul>
                  </li>
                  <li>
                      <b>Data Security</b>
                      We implement appropriate technical and organizational measures to protect your personal data from unauthorized
                      access, disclosure, alteration, or destruction. However, no method of transmission over the internet or
                      electronic storage is completely secure, and we cannot guarantee absolute security.
                  </li>
                  <li>
                      <b>Data Retention</b>
                      We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected
                      and to comply with legal obligations. When we no longer need your personal data, we will securely delete or anonymize it.
                  </li>
                  <li>
                      <b>Your Data Protection Rights</b>
                      Depending on your jurisdiction, you may have the following rights regarding your personal data:
                      <ul>
                          <li><b>Access: </b>You have the right to request access to your personal data.</li>
                          <li><b>Rectification: </b>You have the right to request the correction of inaccurate or incomplete personal data.</li>
                          <li><b>Erasure: </b>You have the right to request the deletion of your personal data under certain conditions.</li>
                          <li><b>Restriction: </b>You have the right to request the restriction of processing your personal data.</li>
                          <li><b>Data Portability: </b>You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable format.</li>
                          <li><b>Objection: </b>You have the right to object to the processing of your personal data under certain conditions.</li>
                      </ul>
                      To exercise these rights, please contact us using the contact information provided below.
                  </li>
                  <li>
                      <b>Cookies and Tracking Technologies</b>
                      We use cookies and similar tracking technologies to collect and store information about your use of the Service. You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. However, if you disable cookies, some parts of the Service may become inaccessible or not function properly.
                  </li>
                  <li>
                      <b>Changes to This Privacy Policy</b>
                      We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Site. You are advised to review this Privacy
                  </li>
              </ol>
          </div>
      </>
  );
}
