import React, { useEffect } from "react";
import SelectBox from "../common/SelectBox2024";

const optionSetting = (info) => {
  let option = [];
  info.forEach((data) => {
    option.push({ value: data.name, label: data.name });
  });
  return option.filter((item) => Object.keys(item).length !== 0);
};

const getUnitOptions = (unitString) => {
  if (!unitString) return [];
  return unitString.split(",").map((unit) => ({ value: unit, label: unit }));
};

const uniqueOptions = (options) => {
  return options.filter(
    (obj, index, self) =>
      index ===
      self.findIndex((t) => t.value === obj.value && t.label === obj.label)
  );
};

const renderUnitAndUsage = (
  unitOptions,
  formData,
  comboState,
  handleComboClick,
  handleUnitType,
  handleOnChangeValue,
  level,
  activityLevelText,
  selectData,
  handleOnChangeNeedValMap
) => {
  for (let i = selectData.length - 1; i >= 0; i--) {
    if (selectData[i].length !== 0) {
      if (selectData[i].needVarMap && selectData[i].needVarMap.length !== 0) {
        return (
          <>
            {unitOptions.length > 0 ? (
              <>
                <div className="inputObj">
                  <label>단위</label>
                  <SelectBox
                    value={formData.unit ?? ""}
                    options={unitOptions}
                    isOpen={comboState}
                    onClick={() => handleComboClick(level + 1)}
                    onChange={(input) => handleUnitType(input)}
                  />
                </div>

                <div className="inputObj">
                  <label>{activityLevelText}</label>
                  <div>
                    <p>
                      <input
                        step="0.1"
                        type="number"
                        value={formData.totalValue ?? 0}
                        placeholder="Please enter"
                        onChange={(e) => handleOnChangeValue(e.target.value)}
                      />
                    </p>
                  </div>
                </div>

                {selectData[i]?.needVarMap[
                  selectData[i + 1]?.catgItemNo
                    ? selectData[i + 1]?.catgItemNo
                    : selectData[i]?.catgItemNo
                ]?.map((data, index) =>
                  // 템플릿 자체 오류로 인해 value = ""일 때 안보이게 처리(varValue에 값이 포함되어 있음)
                  // 임직원 출장 / 대중교통에서 지역선택 시 문제임
                  data.name === "KPH" ? (
                    data.value ? (
                      <div className="inputObj" key={index}>
                        <label>{data.descr}</label>
                        <div>
                          <p>
                            <input
                              step="0.1"
                              type="number"
                              defaultValue={
                                formData &&
                                formData.needValMap &&
                                formData.needValMap[data.name]
                                  ? formData.needValMap[data.name].value || 0
                                  : data?.value || 0
                              }
                              placeholder="Please enter"
                              onChange={(e) =>
                                handleOnChangeNeedValMap(
                                  data.name,
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          </p>
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <div className="inputObj" key={index}>
                      <label>{data.descr}</label>
                      <div>
                        <p>
                          <input
                            step="0.1"
                            type="number"
                            defaultValue={
                              formData &&
                              formData.needValMap &&
                              formData.needValMap[data.name]
                                ? formData.needValMap[data.name].value || 0
                                : data?.value || 0
                            }
                            placeholder="Please enter"
                            onChange={(e) =>
                              handleOnChangeNeedValMap(
                                data.name,
                                e.target.value,
                                index
                              )
                            }
                          />
                        </p>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <div className="inputObj">단위가 없습니다.</div>
            )}
          </>
        );
      }
    }
  }

  return (
    <>
      {unitOptions.length > 0 ? (
        <>
          <div className="inputObj">
            <label>단위</label>
            <SelectBox
              value={formData.unit ?? ""}
              options={unitOptions}
              isOpen={comboState}
              onClick={() => handleComboClick(level + 1)}
              onChange={(input) => handleUnitType(input)}
            />
          </div>
          <div className="inputObj">
            <label>{activityLevelText}</label>
            <div>
              <p>
                <input
                  step="0.1"
                  type="number"
                  value={formData.totalValue ?? 0}
                  placeholder="Please enter"
                  onChange={(e) => handleOnChangeValue(e.target.value)}
                />
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="inputObj">단위가 없습니다.</div>
      )}
    </>
  );
};

const LevelComponent = React.memo(
  ({
    levelInfo,
    formData,
    level,
    comboState,
    handleComboClick,
    handleLevelType,
    handleUnitType,
    handleOnChangeValue,
    parent,
    activityLevelText,
    selectData,
    handleOnChangeNeedValMap,
  }) => {
    // 모든 레벨은 이 컴포넌트를 쓴다. 값 변경 시 초기화하는 로직이 있기에, 계속 리렌더링이 발생  => levelInfo.length === 0일 때 리턴 필요
    if (levelInfo.length === 0) {
      return <></>;
    }
    if (levelInfo.children && levelInfo.children.length > 0) {
      if (levelInfo.children[0].type !== "FactId") {
        return (
          <div className="inputObj">
            <label>{levelInfo.children[0].type}</label>
            <SelectBox
              value={formData[`level${level}`] ?? ""}
              options={optionSetting(levelInfo.children)}
              isOpen={comboState}
              onClick={() => handleComboClick(level + 1)}
              onChange={(input) => handleLevelType(input, level)}
            />
          </div>
        );
      } else {
        let unitOptions;
        // 부모 레벨에 factInfo.unit가 있을 경우 해당 유닛 사용
        if (parent?.factInfo?.unit) {
          unitOptions = getUnitOptions(parent.factInfo.unit);
        } else {
          unitOptions = uniqueOptions(
            levelInfo.children.flatMap((data) => getUnitOptions(data.unit))
          );
        }

        return renderUnitAndUsage(
          unitOptions,
          formData,
          comboState,
          handleComboClick,
          handleUnitType,
          handleOnChangeValue,
          level,
          activityLevelText,
          selectData,
          handleOnChangeNeedValMap
        );
      }
    } else if (
      levelInfo.children === null ||
      levelInfo.children?.length === 0
    ) {
      // 해당 레벨에 유닛이 없고 varValue값이 있을 경우
      if (!levelInfo.unit && levelInfo.varValue) {
        let unitOptions;
        // 부모 레벨에 factInfo.unit가 있을 경우 해당 유닛 사용
        if (parent?.factInfo?.unit) {
          unitOptions = getUnitOptions(parent.factInfo.unit);
        } else {
          unitOptions = getUnitOptions(parent.unit);
        }

        return renderUnitAndUsage(
          unitOptions,
          formData,
          comboState,
          handleComboClick,
          handleUnitType,
          handleOnChangeValue,
          level,
          activityLevelText,
          selectData,
          handleOnChangeNeedValMap
        );
      } else {
        let unitOptions;
        // 부모 레벨에 factInfo.unit가 있을 경우 해당 유닛 사용
        if (parent?.factInfo?.unit) {
          unitOptions = getUnitOptions(parent.factInfo.unit);
        } else {
          unitOptions = getUnitOptions(levelInfo.unit);
        }

        return renderUnitAndUsage(
          unitOptions,
          formData,
          comboState,
          handleComboClick,
          handleUnitType,
          handleOnChangeValue,
          level,
          activityLevelText,
          selectData,
          handleOnChangeNeedValMap
        );
      }
    }
  }
);

export default LevelComponent;
