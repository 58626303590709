import React, {useEffect} from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import map01 from "../../images/map_01.png";
import map02 from "../../images/map_02.png";
import map03 from "../../images/map_03.png";

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

const chart1option = {
    chart: {
        backgroundColor:'transparent',
        type: 'pie',
        height: '260px',
        width: 720,
    },
    title: {
        text: null,
    },
    subtitle: {
        useHTML: true,
        text: `<span style="display: block;text-align: center;font-size: 24px;color: #000;">Total</span><b style="display: block;text-align: center;font-size: 48px;font-weight: 800;color: #000;">25,342</b>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        x: -165,
    },
    tooltip: {
        enabled: false
    },
    legend: {
        align: 'right',
        verticalAlign: 'middle',
        labelFormat: '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
        layout: 'vertical',
        itemStyle : {"color": "#000", "fontSize": "16px", "fontWeight": "700", "textOverflow": "ellipsis"},
        itemMarginBottom: 15,
        x: -150,
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            size: 220
        },
        series: {
            borderWidth: 0,
            borderRadius: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '85%',
            dataLabels: {
                enabled: false,
            }
        }
    },
    series: [{
        type: 'pie',
        data: [
            {
                y: 5416,
                name: "Scope 1",
                color: "#4e75ff",
            },
            {
                y: 2017,
                name: "Scope 2",
                color: "#3e5ecc",
            },
            {
                y: 17711,
                name: "Scope 3",
                color: "#2f4699",
            }
        ],
    }],
    exporting: {
        buttons: {
            contextButton: {
                enabled: false
            }
        }
    },
}


const chart2option = {
    chart: {
        backgroundColor:'transparent',
        type: 'pie',
        height: '260px',
        width:  720
    },
    title: {
        text: null,
    },
    subtitle: {
        useHTML: true,
        text: `<b style="color:#000;font-size: 48px;font-weight: 800;">Region</b>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        x: - 160,
    },
    tooltip: {
        enabled: false
    },
    legend: {
        align: 'right',
        verticalAlign: 'middle',
        labelFormat: '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
        layout: 'vertical',
        itemStyle : {"color": "#000", "fontSize": "16px", "fontWeight": "700", "textOverflow": "ellipsis"},
        itemMarginBottom: 5,
        x: -100,
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            size: 220
        },
        series: {
            borderWidth: 0,
            borderRadius: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '85%',
            dataLabels: {
                enabled: false,
            }
        }
    },
    series: [{
        type: 'pie',
        data: [
            {
                y: 1099,
                name: "Australia",
                color: "#EF9D00",
            },
            {
                y: 7218,
                name: "UK",
                color: "#4E75FF",
            },
            {
                y: 724,
                name: "Korea",
                color: "#40C0DC",
            },
            {
                y: 1621,
                name: "Japan",
                color: "#C00000",
            },
            {
                y: 2182,
                name: "New Zealand",
                color: "#F45C5C",
            },
            {
                y: 1481,
                name: "USA",
                color: "#F2802F",
            },
            {
                y: 1624,
                name: "Global",
                color: "#58B90A",
            },
            {
                y: 9393,
                name: "Other Countries",
                color: "#919191",
            }
        ],
    }],
    exporting: {
        buttons: {
            contextButton: {
                enabled: false
            }
        }
    },
}

const chart3option = {
    chart: {
        backgroundColor:'transparent',
        type: 'pie',
        height: 450,
    },
    title: {
        text: null,
    },
    subtitle: {
        useHTML: true,
        text: `<b style="color:#000;font-size: 38px;font-weight: 800;">Activity</b>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
    },
    tooltip: {
        enabled: false
    },
    legend: {
        verticalAlign: 'bottom',
        floating: true,
        labelFormat: '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
        itemStyle : {"color": "#000", "fontSize": "16px", "fontWeight": "700", "textOverflow": "ellipsis"},
        itemMarginBottom: 5,
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            size: 220,
        },
        series: {
            borderWidth: 0,
            borderRadius: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '85%',
            dataLabels: {
                enabled: false,
            },

        }
    },
    series: [{
        type: 'pie',
        data: [
            {
                y: 1099,
                name: "Australia",
                color: "#EF9D00",
            },
            {
                y: 7218,
                name: "UK",
                color: "#4E75FF",
            },
            {
                y: 724,
                name: "Korea",
                color: "#40C0DC",
            },
            {
                y: 1621,
                name: "Japan",
                color: "#C00000",
            },
            {
                y: 2182,
                name: "New Zealand",
                color: "#F45C5C",
            },
            {
                y: 1481,
                name: "USA",
                color: "#F2802F",
            },
            {
                y: 1624,
                name: "Global",
                color: "#58B90A",
            },
            {
                y: 9393,
                name: "Other Countries",
                color: "#919191",
            },
            {
                y: 1099,
                name: "Australia",
                color: "#EF9D00",
            },
            {
                y: 7218,
                name: "UK",
                color: "#4E75FF",
            },
            {
                y: 724,
                name: "Korea",
                color: "#40C0DC",
            },
            {
                y: 1621,
                name: "Japan",
                color: "#C00000",
            },
            {
                y: 2182,
                name: "New Zealand",
                color: "#F45C5C",
            },
            {
                y: 1481,
                name: "USA",
                color: "#F2802F",
            },
            {
                y: 1624,
                name: "Global",
                color: "#58B90A",
            },
            {
                y: 9393,
                name: "Other Countries",
                color: "#919191",
            }
        ],
    }],
    exporting: {
        buttons: {
            contextButton: {
                enabled: false
            }
        }
    },
}

function DataOverview() {
    useEffect(() => {
        document.body.classList.add('kr');
    });

    return (
        <>
            <div className="pageTitle">
                세계에서 가장 효율적인 배출량 계산기 API에 오신 것을 환영합니다.
            </div>
            <div className="dataOverview">
                <strong>
                    배출계수와 보충 데이터를 배출회계 소프트웨어에 탐색하고 구현하세요.
                </strong>
                <div className="DataOverviewGrid">
                    <div className="cbSpBox span4">
                        <div className="boxTitle">
                            총 데이터 보유현황
                        </div>
                        <div className="DataChart">
                            <div>
                                <HighchartsReact highcharts={Highcharts} options={chart1option}/>
                            </div>
                            <div>
                                <HighchartsReact highcharts={Highcharts} options={chart2option}/>
                            </div>
                        </div>
                    </div>
                    <div className="cbSpBox span4">
                        <div className="boxTitle">
                            산업 분야별 데이터 현황
                        </div>
                        <div className="ActiveChart">
                            <HighchartsReact highcharts={Highcharts} options={chart3option}/>
                        </div>

                    </div>
                    <div className="cbSpBox span2">
                        <div className="boxTitle">
                            아시아 태평양에 대한 특화된 데이터 영역
                        </div>
                        <div className="mapCont">
                            <div>
                                <p><img src={map01} alt=""/></p>
                                <dl>
                                    <dt>808</dt>
                                    <dd>Emissions Data<br/>from Korea</dd>
                                </dl>
                            </div>
                            <div>
                                <p><img src={map02} alt=""/></p>
                                <dl>
                                    <dt>1,819</dt>
                                    <dd>Emissions Data<br/>from Japan</dd>
                                </dl>
                            </div>
                            <div>
                                <p><img src={map03} alt=""/></p>
                                <dl>
                                    <dt>848</dt>
                                    <dd>Emissions Data<br/>from Thai</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>데이터 조화</dt>
                            <dd>
                                전 세계 다양한 소스를 통해
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>행 단위 검사</dt>
                            <dd>
                                데이터 무결성에 대해
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>쉽게 사용자 정의 가능</dt>
                            <dd>
                                통화, 단위, 언어 최적화 등 다양한 사용자 환경에 맞춰
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>전문가의 도움</dt>
                            <dd>
                                또는 배출계수 선택 및 경계 설정
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>사용의 용이성</dt>
                            <dd>
                                자체 개발한 배출량 산정 보완 데이터를 통해
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox overviewBox">
                        <dl>
                            <dt>감사 준비</dt>
                            <dd>
                                특정 데이터 소스별, 투명한 계산 방법 및 가정
                            </dd>
                        </dl>
                    </div>

                </div>
            </div>
        </>
    );
}

export default DataOverview;