import React from "react";
import iso01 from "../../images/iso01.svg";
import iso02 from "../../images/iso02.svg";
import iso03 from "../../images/iso03.svg";
import iso04 from "../../images/iso04.svg";

function Home() {
    return (
        <>
            <div className="pageTitle">
                Welcome to Carbonscope
            </div>
            <div className="homeTopGrid">
                <div className="cbSpBox">
                    <div className="boxTitle">Carbon Managing<br/>is an imperative issue</div>
                    <div className="pressure">
                        <div className="gover"><b>Government</b></div>
                        <div className="indu"><b>Industry</b></div>
                        <div className="corp"><b>Corporate</b></div>
                        <p className="arrow">
                            <b></b>
                            <span>Global pressure</span>
                        </p>
                    </div>
                    <dl className="pressure_desc">
                        <dt>Paris Agreement</dt>
                        <dd>
                            Convention to ensure that all countries are committed to net-zero so that the average global
                            temperature does not rise by more than 1.5 degrees Celsius compared to pre-industrial times.
                        </dd>
                        <dt>
                            NDC
                        </dt>
                        <dd>
                            Each country should submit a Nationally Determined Contribution(NDC) to announce and
                            implement its climate change response and greenhouse gas reduction goals.
                        </dd>
                    </dl>
                </div>
                <div className="cbSpBox">
                    <div className="boxTitle">Companies struggle with<br/>managing their carbon emissions</div>
                    <dl>
                        <dt>Requires automation in calculation</dt>
                        <dd>Current way is low-tech and manual</dd>
                        <dt>Complexity in measuring emission</dt>
                        <dd>Inconsistent data across human activities, regions and methodologies</dd>
                        <dt>Intensive amount of time, labour and cost</dt>
                        <dd>
                            Takes huge amount of development & audit-readiness costs
                        </dd>
                    </dl>
                </div>
                <div className="cbSpBox">
                    <div className="boxTitle">Here’s how<br/>Carbonscope can help</div>
                    <dl>
                        <dt>Ease of Use</dt>
                        <dd>API integration only by search & bookmark</dd>
                        <dt>Streamlined</dt>
                        <dd>Mass balance with Big Data</dd>
                        <dt>Accurate</dt>
                        <dd>Science-based calculation</dd>
                        <dt>Thorough</dt>
                        <dd>Comprehensive platform that suits your business</dd>
                        <dt>Audit-readiness</dt>
                        <dd>Transparency within data, methods and assumptions</dd>
                    </dl>
                </div>
            </div>
            <div className="homeBtmGrid">
                <div className="cbSpBox">
                    <a href="#">Check my API bookmarks</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">Open API docs</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">Explore more Emission Calculator</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">Explore more Emission Data</a>
                </div>
                <div className="cbSpBox">
                    <div>
                        Certificates
                        <span>
                            <img src={iso01}/>
                            <img src={iso02}/>
                            <img src={iso03}/>
                            <img src={iso04}/>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;