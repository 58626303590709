import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { insertRequestContactus } from "../../service/userRequestService";
import { alertData } from "../../util/alertData";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";

function HelpSupport() {
  const { type } = useParams();
  const numericType = Number(type);
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    contents: "",
    checkData: {
      check1: { value: numericType === 1 ? "Price Payment Process" : null },
      check2: {
        value:
          numericType === 2
            ? "Emissions Calculator & Data API integration"
            : null,
      },
      check3: {
        value:
          numericType === 3
            ? "Carbon Accounting consulting for your business"
            : null,
      },
      check4: {
        value:
          numericType === 4 ? "Managing my private emission factors" : null,
      },
      check5: { value: numericType === 5 ? "Partnering with us" : null },
      check6: {
        value: numericType === 6 ? "Explore career opportunities" : null,
      },
      check7: { value: numericType === 7 ? "Press/Media request" : null },
      check8: { value: numericType === 8 ? "Something else" : null },
    },
  });

  const userJSON = JSON.parse(localStorage.getItem("user"));
  const checkBoxTitle = [
    "check1",
    "check2",
    "check3",
    "check4",
    "check5",
    "check6",
    "check7",
    "check8",
  ];

  let userInfo = null;
  if (userJSON) {
    userInfo = jwtDecode(userJSON.accessToken);
  }

  useEffect(() => {
    if (userInfo?.userName) {
    } else {
      navigate("/login2024");
    }
  }, []);

  const submitRequest = () => {
    if (
      !formData.contents ||
      (!formData.checkData.check1.value &&
        !formData.checkData.check2.value &&
        !formData.checkData.check3.value &&
        !formData.checkData.check4.value &&
        !formData.checkData.check5.value &&
        !formData.checkData.check6.value &&
        !formData.checkData.check7.value &&
        !formData.checkData.check8.value)
    ) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.allCheckValue",
      }));
      setPopupStatus(true);
    } else {
      insertData();
    }
  };

  const insertData = async () => {
    let data1 = formData.checkData.check1.value
      ? t(`HELP.${formData.checkData.check1.value}`)
      : null;
    let data2 = formData.checkData.check2.value
      ? t(`HELP.${formData.checkData.check2.value}`)
      : null;
    let data3 = formData.checkData.check3.value
      ? t(`HELP.${formData.checkData.check3.value}`)
      : null;
    let data4 = formData.checkData.check4.value
      ? t(`HELP.${formData.checkData.check4.value}`)
      : null;
    let data5 = formData.checkData.check5.value
      ? t(`HELP.${formData.checkData.check5.value}`)
      : null;
    let data6 = formData.checkData.check6.value
      ? t(`HELP.${formData.checkData.check6.value}`)
      : null;
    let data7 = formData.checkData.check7.value
      ? t(`HELP.${formData.checkData.check7.value}`)
      : null;
    let data8 = formData.checkData.check8.value
      ? t(`HELP.${formData.checkData.check8.value}`)
      : null;

    try {
      const param = {
        userMail: userInfo.userMail,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        rqtCatg:
          data1 +
          " / " +
          data2 +
          " / " +
          data3 +
          " / " +
          data4 +
          " / " +
          data5 +
          " / " +
          data6 +
          " / " +
          data7 +
          " / " +
          data8,
        rqtContent: formData.contents,
        rqtType: "contactus",
      };
      const response = await insertRequestContactus(param);
      if (response.status === 200) {
        setPopupData((prevData) => ({
          ...prevData,
          popupStatus: true,
          content: "ALERT.writeComplete",
        }));
        setPopupStatus(true);

        setFormData({
          contents: "",
          checkData: {
            check1: { value: null },
            check2: { value: null },
            check3: { value: null },
            check4: { value: null },
            check5: { value: null },
            check6: { value: null },
            check7: { value: null },
            check8: { value: null },
          },
        });
        document.getElementById("content").value = "";

        for (let i = 1; i < 9; i++) {
          document.getElementById(`check${i}`).checked = false;
        }
      }
    } catch (error) {
      console.error("insertRequestContactus Error:", error);
    }
  };
  return (
    <>
      <div className="pageTitle">{t(`HELP.title`)}</div>
      <div className="cbSpBox accountBox">
        <div className="requestWrap">
          <div className="accountInput">
            <div className="accSelect">
              <label>{t(`HELP.selectItem`)}</label>
              <ul>
                {checkBoxTitle.map((item, index) => (
                  <li key={index}>
                    <input
                      id={`check${index + 1}`}
                      type="checkbox"
                      defaultChecked={index + 1 === numericType}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            checkData: {
                              ...prevFormData.checkData,
                              [`check${index + 1}`]: { value: item },
                            },
                          }));
                        } else {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            checkData: {
                              ...prevFormData.checkData,
                              [`check${index + 1}`]: { value: null },
                            },
                          }));
                        }
                      }}
                    />
                    <label>
                      <em></em>
                      {t(`HELP.${item}`)}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`HELP.firstNm`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.firstName ? userInfo.firstName : ""}
                  />
                </p>
              </div>
              <div>
                <label>{t(`HELP.lastNm`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.lastName ? userInfo.lastName : ""}
                  />
                </p>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`HELP.workEmail`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.userMail ? userInfo.userMail : ""}
                  />
                </p>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`HELP.help`)}</label>
                <p>
                  <textarea
                    id="content"
                    onChange={(e) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        contents: e.target.value,
                      }));
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="accountMsg flex">
            {t("HELP.policy")
              .split("\n")
              .map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            <button>
              <Link to="/privacyPolicy2024">{t("HELP.checkPolicy")}</Link>
            </button>
          </div>
          <div className="accountBtn">
            <button type="submit" onClick={submitRequest}>
              {t(`HELP.submit`)}
            </button>
          </div>
        </div>
      </div>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default HelpSupport;
