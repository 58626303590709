import React from "react";
import { useTranslation } from "react-i18next";

function PasswordStep2({ formData, setFormData, formErrors }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="accInp">
        <div>
          <label>{t(`RESETPWD.pwd`)}</label>
          <p className={formErrors.inputPassword ? "error" : null}>
            <input
              type="password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  inputPassword: e.target.value,
                });
              }}
            />
          </p>
          <span className="errorText">{formErrors.inputPassword}</span>
        </div>
      </div>
      <div className="accInp">
        <div>
          <label>{t(`RESETPWD.pwdConfirm`)}</label>
          <p className={formErrors.inputConfirmPassword ? "error" : null}>
            <input
              type="password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  inputConfirmPassword: e.target.value,
                });
              }}
            />
          </p>
          <span className="errorText">{formErrors.inputConfirmPassword}</span>
        </div>
      </div>
    </>
  );
}

export default PasswordStep2;
