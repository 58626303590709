export const mainMenu = [
  {
    link: "/",
    name: "Home",
    pClass: "line",
    aClass: "home",
    goTo: true,
    transData: "home",
  },
  {
    // link: "#",
    link: "/emission2024",
    name: "Products",
    pClass: null,
    aClass: "produc",
    goTo: true,
    // goTo: false,
    transData: "cal",
  },

  {
    // link: "/",
    link: "/findDataExplorer2024",
    name: "Find Emission Data",
    pClass: null,
    aClass: "find",
    goTo: true,
    transData: "find",
  },
  {
    link: "/dataIntegrate2024",
    name: "Integrate",
    pClass: null,
    aClass: "develop",
    goTo: true,
    transData: "integrate",
  },
  {
    link: "/price2024",
    name: "Pricing",
    pClass: "line",
    aClass: "pricing",
    goTo: true,
    transData: "pricing",
  },
  {
    link: "#",
    name: "Setting",
    pClass: null,
    aClass: "setting",
    goTo: false,
    transData: "setting",
  },
  {
    link: "/help2024/0",
    name: "Help & Support",
    pClass: "line",
    aClass: "help",
    goTo: true,
    transData: "help",
  },
];

// export const productMenu = [
//   {
// parent: "Emission Calculator",
// parentTransData: "cal",
//   child: [
//     {
//       link: "/calculatorOverview2024",
//       aClass: "",
//       title: "Calculator Overview",
//       transData: "calOverview",
//     },
//     {
//       link: "/emission2024",
//       aClass: "",
//       title: "Calculator Explorer",
//       transData: "calExplorer",
//     },
//     {
//       link: "/reqTemp2024",
//       aClass: "",
//       title: "Request Templates",
//       transData: "reqTem",
//     },
//   ],
// },
// {
//   parent: "Emission Data",
//   parentTransData: "emissionData",
//   child: [
//     {
//       link: "/dataOverView2024",
//       aClass: "",
//       title: "Data Overview",
//       transData: "dataOverview",
//     },
//     {
//       link: "/dataExplorer2024",
//       aClass: "",
//       title: "Data Explorer",
//       transData: "dataExplorer",
//     },
//     {
//       link: "/dataSource2024",
//       aClass: "",
//       title: "Data Sources",
//       transData: "dataSources",
//     },
//   ],
//   },
// ];

export const settingMenu = [
  {
    parent: "Policy",
    parentTransData: "policy",
    child: [
      {
        link: "/privacyPolicy2024",
        aClass: "",
        title: "Privacy Policy",
        transData: "privacy",
      },
      {
        link: "/termsOfUse2024",
        aClass: "",
        title: "Terms of Use",
        transData: "terms",
      },
      // {
      //   link: "#",
      //   aClass: "",
      //   title: "Cookie Policy",
      //   transData: "cookie",
      // },
    ],
  },
  {
    parent: "Language",
    parentTransData: "lang",
    child: [
      {
        link: null,
        aClass: "",
        title: "Korean",
        transData: "ko",
      },
      {
        link: null,
        aClass: "",
        title: "English",
        transData: "en",
      },
    ],
  },
];

export const myProfileMenu = [
  // {
  //   parent: "My Bookmark List",
  //   parentTransData: "myBookmarkList",
  //   child: [
  //     {
  //       link: "/calculatorBookmark2024",
  //       aClass: "",
  //       title: "Emission Calculator Bookmark",
  //       transData: "myCalBookmark",
  //     },
  //     {
  //       link: "/activityCategoryBookmark2024",
  //       aClass: "",
  //       title: "Emission Activity Category Bookmark",
  //       transData: "myActBookmark",
  //     },
  //     {
  //       link: "/myEmissionDataBookmark2024",
  //       aClass: "",
  //       title: "Emission Data Bookmark",
  //       transData: "myDataBookmark",
  //     },
  //   ],
  // },
  // {
  //   parent: "My Private Data",
  //   parentTransData: "myPrivateData",
  //   child: [
  //     {
  //       link: "#",
  //       aClass: "",
  //       title: "Upload my own Emission Data",
  //       transData: "myUploadData",
  //     },
  //   ],
  // },
  {
    parent: "My Information",
    parentTransData: "myInfo",
    child: [
      {
        link: "/myInformation2024",
        aClass: "",
        title: "My Account detail",
        transData: "myAcc",
      },
    ],
  },
  {
    parent: "Request",
    parentTransData: "myRequest",
    child: [
      {
        link: "/reqTemp2024",
        aClass: "",
        title: "Request Emission Calculator",
        transData: "myRequestEmissionCal",
      },
    ],
  },
];

export const noticeMenu = [
  {
    contents:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
  {
    contents:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
];
