import { GET } from "./fetch-auth-action.ts";

export const getRegion = async () => {
  const URL = "/api/dataStatus/getRegion";
  let header = null;
  const response = GET(URL, header);
  return response;
};
export const getScope = async () => {
  const URL = "/api/dataStatus/getScope";
  let header = null;
  const response = GET(URL, header);
  return response;
};

export const getAsia = async () => {
  const URL = "/api/dataStatus/getAsia";
  let header = null;
  const response = GET(URL, header);
  return response;
};

export const getSector = async () => {
  const URL = "/api/dataStatus/getSector";
  let header = null;
  const response = GET(URL, header);
  return response;
};

export const getNationSector = async () => {
  const URL = "/api/dataStatus/getNationSector";
  let header = null;
  const response = GET(URL, header);
  return response;
};
