import { useEffect, useState } from "react";
import {
  deleteEmssionDataBookmark,
  getMyEmissionData,
} from "../../service/dataExplorerService";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { decrypt } from "../../util/crypto";
import { useDispatch } from "react-redux";
import { setRecentFindData } from "../../util/recentFindDataParamSlice";
import { alertData } from "../../util/alertData";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";
import { getMyInfo } from "../../service/userRequestService";
import { useTranslation } from "react-i18next";

export default function MyBookmarkEmissionData() {
  const { t } = useTranslation();
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const [bookmarkList, setBookmarkList] = useState(null);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const token = localStorage.getItem("user");
  const userInfo = token ? jwt_decode(token) : null;
  useEffect(() => {
    if (userInfo?.userNo) {
      myBookmarkList(userInfo.userNo);
    } else {
      nav("/login2024");
    }
  }, []);

  const [myInfo, setMyInfo] = useState(null);
  useEffect(() => {
    if (userInfo?.userNo) {
      myData();
    }
  }, [userInfo?.userNo]);

  const myData = async () => {
    const response = await getMyInfo(userInfo?.userNo);
    if (response.status === 200) {
      setMyInfo(response.data);
    }
  };

  const myBookmarkList = async (userNo) => {
    const response = await getMyEmissionData(userNo);
    if (response.status === 200 && response.data.length > 0) {
      response.data.forEach((data) => {
        data.viewDetail = false;
        data.factorVal = Number(decrypt(data.factorVal));

        let splitFactName = data.factItemAllName.split(" / ");
        data.level1 = splitFactName[0] ? splitFactName[0] : null;
        data.level2 = splitFactName[1] ? splitFactName[1] : null;
        data.level3 = splitFactName[2] ? splitFactName[2] : null;
        data.level4 = splitFactName[3] ? splitFactName[3] : null;

        data.accessToken = "Bearer " + localStorage.getItem("access_token");
        data.apiUrl =
          window.location.host +
          "/api/userRequest/getRequestEmissionData/" +
          userInfo.userNo +
          "/" +
          data.userFactValId;

        data.apiUrl = data.apiUrl.replace(
          "carbonscope.io",
          "carbonscope.io:8080"
        );
      });
      setBookmarkList(response.data);
    }
  };

  const handleDelete = async (data, index) => {
    const response = await deleteEmssionDataBookmark(data);
    if (response.status === 200) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.deleteAlert",
      }));
      setPopupStatus(true);

      let cpDataList = structuredClone(bookmarkList);
      cpDataList.splice(index, 1); // 삭제 값 제거
      console.log(cpDataList);
      setBookmarkList(cpDataList);
    }
  };
  return (
    <>
      <div className="pageTitle">
        <button
          className="back"
          onClick={() => {
            nav("/dataIntegrate2024");
          }}
        ></button>
        My Bookmarked Emission Data
      </div>
      {/* */}
      {bookmarkList && (
        <div className="CalcuBookmark ovh">
          {bookmarkList.map((data, index) => (
            <div className="cbSpBox bmDataBox" key={index}>
              <button
                className="delete"
                onClick={() => {
                  handleDelete(data, index);
                }}
              />
              <div className={data.viewDetail ? "flag open" : "flag"}>
                <button
                  onClick={() => {
                    let cloneData = structuredClone(bookmarkList);
                    cloneData[index].viewDetail = !cloneData[index].viewDetail;
                    setBookmarkList(cloneData);
                  }}
                />
              </div>
              <div className="dataWrap">
                <div className="stepData">
                  <p>{data.level1}</p>
                  <p>{data.level2}</p>
                  <p>{data.level3}</p>
                  <p>{data.level4}</p>
                </div>
                <div className="detailData">
                  <ul>
                    <li>
                      <b>Emissions Factor</b>{" "}
                      <span>
                        {data.factorVal} kgCO2e of {data.gasId}
                      </span>
                    </li>
                    <li>
                      <b>Scope</b> <span>{data.scope}</span>
                    </li>
                    <li>
                      <b>Region</b> <span>{data.nationId}</span>
                    </li>
                    <li>
                      <b>Data Year</b> <span>{data.yyyy}</span>
                    </li>
                    <li>
                      <b>Unit</b> <span>{data.unit}</span>
                    </li>
                    <li>
                      <b>Source</b> <span>{data.srcInfo}</span>
                    </li>
                    <li>
                      <b>GWP100</b> <span>{data.rawCaculationMethod}</span>
                    </li>
                    <li>
                      <b>LCA Activity</b> <span>--</span>
                    </li>
                  </ul>
                  <div className="calcuApi">
                    <label>API Code Request</label>
                    <div className="ApiResult">
                      {myInfo?.cbUserSubscribeCd === "1" ? (
                        <div className="code">
                          {t(`EXPLORERCAL.membership`)}
                        </div>
                      ) : (
                        <div className="code">
                          curl --header "APP_KEY: {myInfo?.appKey}" \ <br />
                          --url "http://{data.apiUrl}"
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="verCheck">
                  <button
                    onClick={() => {
                      if (data.checkUpdateVal) {
                        dispatch(setRecentFindData(data));
                        nav("/findDataExplorer2024", { replace: true });
                      } else {
                        setPopupData((prevData) => ({
                          ...prevData,
                          popupStatus: true,
                          content: "ALERT.notEmissionUpdateDate",
                        }));
                        setPopupStatus(true);
                      }
                    }}
                  >
                    Check updated version
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!bookmarkList && (
        <div className="CalcuBookmark">
          <div className="cbSpBox noData">
            <Link to="/findDataExplorer2024">
              <span>Go to browse and bookmark emission data</span>
            </Link>
          </div>
        </div>
      )}
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}
