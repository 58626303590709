import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import { getMyInfo } from "../../service/userRequestService";

const EmissionResult = React.memo(({ resultData, userInfo }) => {
  const nav = useNavigate();
  const { t } = useTranslation();

  const [myInfo, setMyInfo] = useState(null);
  useEffect(() => {
    if (userInfo?.userNo) {
      myData();
    }
  }, [userInfo]);

  const myData = async () => {
    const response = await getMyInfo(userInfo?.userNo);
    if (response.status === 200) {
      setMyInfo(response.data);
    }
  };

  // console.log("resultData => ", resultData);
  resultData?.factors.forEach((data) => {
    let splitData = data.factName.split(" / ");
    data.LevelName1 = splitData[0];
    data.LevelName2 = splitData[1];
    data.LevelName3 = splitData[2];
    data.LevelName4 = splitData[3];
  });
  return (
    <>
      <Grid item xs={5} className="calcData">
        <dl className="calcResult">
          <dt>{t(`EXPLORERCAL.calResult`)}</dt>
          <dd className="cbSpBox">
            {resultData && (
              <>
                <strong>
                  {resultData.emissionAmount < 5
                    ? parseFloat(resultData.emissionAmount.toFixed(5))
                    : parseFloat(
                        (resultData.emissionAmount + 0.000005).toFixed(5)
                      )}
                  &nbsp; <em>kgCO2e</em>
                </strong>
              </>
            )}
          </dd>
        </dl>
        <dl className="calcDetail">
          <dt>{t(`EXPLORERCAL.calDetail`)}</dt>
          <dd className="cbSpBox">
            {myInfo?.cbUserSubscribeCd === "1" && (
              <div className="paidWrap">
                <strong>
                  {t("EXPLORERCAL.membership")
                    .split("\n")
                    .map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                </strong>
                <button
                  onClick={() => {
                    nav("/price2024");
                  }}
                >
                  {t("EXPLORERCAL.pricing")}
                </button>
              </div>
            )}

            {resultData && (
              <div
                className={
                  myInfo?.cbUserSubscribeCd === "1"
                    ? "calcDetailWrap noPaid"
                    : "calcDetailWrap"
                  // Number(userInfo?.cbUserSubscribeCd) === 1
                  //   ? "calcDetailWrap noPaid"
                  //   : "calcDetailWrap"
                }
              >
                {resultData.afCalcFmla && (
                  <div className="summary">
                    <b>{t(`EXPLORERCAL.equation`)}</b>
                    <ul>
                      <li>
                        {t(`EXPLORERCAL.emissions`)} = {resultData.afCalcFmla}
                      </li>
                    </ul>
                  </div>
                )}
                <Swiper
                  className="calDetailData"
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {resultData.factors.map((data, index) => (
                    <SwiperSlide key={index} className="calDetailSlide">
                      <b>
                        {/*{t(`EXPLORERCAL.factor`)}*/}
                        Data {index + 1}
                        {/*{data.varName
                        ? data.varName
                        : data.gasId
                        ? data.gasId
                        : data.factorId}*/}
                      </b>
                      {resultData.gasVals && (
                        <>
                          {resultData?.gasVals[`${data.gasId}`]?.amount && (
                            <>
                              <strong>
                                {resultData?.gasVals[`${data.gasId}`]?.amount}
                                &nbsp;kg CO2e of {data.gasId}
                              </strong>
                              {/* <li>
                                kg CO2e of {data.gasId} :
                                {resultData?.gasVals[`${data.gasId}`]?.amount}
                                &nbsp; kgCO2e
                              </li> */}
                            </>
                          )}
                        </>
                      )}
                      <ul>
                        <li>
                          <b>{t(`EXPLORERCAL.activity`)}</b>{" "}
                          <span>
                            {data?.LevelName1}
                            {data?.LevelName2 ? " / " : null}
                            {data?.LevelName2}
                            {data?.LevelName3 ? " / " : null}
                            {data?.LevelName3}
                            {data?.LevelName4 ? " / " : null}
                            {data?.LevelName4}
                          </span>
                        </li>
                        <li>
                          <b>Scope</b> <span>{data?.cbScopeId}</span>
                        </li>
                        <li>
                          <b>{t(`EXPLORERCAL.resgion`)}</b>{" "}
                          <span>{data.nationId} </span>
                        </li>
                        <li>
                          <b> {t(`EXPLORERCAL.year`)}</b>{" "}
                          <span>{data.yyyy}</span>
                        </li>
                        <li>
                          <b>Unit</b> <span>{data.unit}</span>
                        </li>
                        <li>
                          <b>{t(`EXPLORERCAL.source`)}</b>{" "}
                          <span>{data.sourceInfo} </span>
                        </li>
                        <li>
                          <b>GWP100</b> <span>{data.gwpRptId}</span>
                        </li>
                        <li>
                          <b>LCA Activity</b> <span>--</span>
                        </li>
                        {/*<li>
                        {t(`EXPLORERCAL.oriFactor`)}:{" "}
                        {Number(data.orgFactorValDecrypt)}{" "}
                      </li>
                      <li>
                        {t(`EXPLORERCAL.toFactor`)} :{" "}
                        {Number(data.factorValDecrypt)}
                      </li>*/}
                      </ul>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </dd>
        </dl>
      </Grid>
    </>
  );
});

export default EmissionResult;
