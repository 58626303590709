import HighchartsReact from "highcharts-react-official";
import mapDataWorld from "@highcharts/map-collection/custom/world.geo.json";
import { useEffect, useState } from "react";
import { getNationSector } from "../../service/dataOverviewService";
import { useTranslation } from "react-i18next";

export default function HomeAreaChart2024({ Highcharts }) {
  const { t } = useTranslation();
  const [nationData, setNationData] = useState([]);

  useEffect(() => {
    nationSector();
  }, []);

  const nationSector = async () => {
    const response = await getNationSector();
    if (response.status === 200) {
      let result = [];
      response.data.forEach((data) => {
        let refine = [data.NATION_ID2.toLowerCase(), data.FACTOR_COUNT];
        result.push(refine);
      });
      setNationData(result);
    }
  };
  const mapChartOption = {
    chart: {
      map: mapDataWorld,
    },
    title: {
      text: null,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    colorAxis: {
      min: 0,
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          format: "{point.value}",
        },
        allAreas: true,
        data: nationData,
      },
    ],
  };

  return (
    <div className="worldchart">
      <div className="cbSpBox">
        <strong>{t("HOME.resional")}</strong>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"mapChart"}
          options={mapChartOption}
        />
      </div>
    </div>
  );
}
