import React, { useEffect, useState } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { color } from "highcharts";
import map01 from "../../images/map_01.png";
import map02 from "../../images/map_02.png";
import map03 from "../../images/map_03.png";
import {
  getScope,
  getRegion,
  getSector,
  getAsia,
} from "../../service/dataOverviewService";
import { useTranslation } from "react-i18next";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

function DataOverview() {
  const { t } = useTranslation();
  const [scope, setScope] = useState();
  const [totalScope, setTotalScope] = useState(0);
  const [region, setResion] = useState();
  const [sector, setSector] = useState();
  const [asia, setAsia] = useState();
  useEffect(() => {
    getScopeData();
    getRegionData();
    getSectorData();
    getAsiaData();
  }, []);
  const getScopeData = async () => {
    const response = await getScope();
    if (response?.status === 200) {
      const responseData = response.data;
      const data = [
        {
          y: responseData.scope1Count,
          name: "Scope 1",
          color: "#4e75ff",
        },
        {
          y: responseData.scope2Count,
          name: "Scope 2",
          color: "#3e5ecc",
        },
        {
          y: responseData.scope3Count,
          name: "Scope 3",
          color: "#2f4699",
        },
        {
          y: responseData.scope9Count,
          name: "Scope 9",
          color: "gray",
        },
      ];

      setScope(data);
      setTotalScope(
        responseData.scope1Count +
          responseData.scope2Count +
          responseData.scope3Count +
          responseData.scope9Count
      );
    } else {
      return;
    }
  };

  const getRegionData = async () => {
    const response = await getRegion();
    if (response?.status === 200) {
      const responseData = response.data;
      const data = [
        {
          y: responseData.australia,
          name: "Australia",
          color: "#EF9D00",
        },
        {
          y: responseData.uk,
          name: "UK",
          color: "#4E75FF",
        },
        {
          y: responseData.kor,
          name: "Korea",
          color: "#40C0DC",
        },
        {
          y: responseData.china,
          name: "China",
          color: "red",
        },
        {
          y: responseData.japan,
          name: "Japan",
          color: "#C00000",
        },
        {
          y: responseData.newZealand,
          name: "New Zealand",
          color: "#F45C5C",
        },
        {
          y: responseData.usa,
          name: "USA",
          color: "#F2802F",
        },
        {
          y: responseData.global,
          name: "Global",
          color: "#58B90A",
        },

        {
          y: responseData.otherCountries,
          name: "Other Countries",
          color: "#919191",
        },
      ];

      setResion(data);
    } else {
      return;
    }
  };

  const getSectorData = async () => {
    const response = await getSector();
    if (response?.status === 200) {
      const responseData = response.data;
      let otherCount = 0;
      let val = [];
      const color = ["#EF9D00", "#4E75FF", "#40C0DC", "#C00000", "#F45C5C"];
      responseData.map((data) => {
        if (data.scopeId === "S3") {
          if (val.length < 5) {
            val.push({
              y: data.sectorCount,
              name: data.catgName,
              color: color[val.length],
            });
          } else {
            otherCount += data.sectorCount;
          }
        }
      });

      val.push({ y: otherCount, name: "Ohter Sectors", color: "gray" });

      setSector(val);
    } else {
      return;
    }
  };

  const getAsiaData = async () => {
    const response = await getAsia();
    if (response?.status === 200) {
      const data = {
        KOR: response.data.kor,
        JPN: response.data.japan,
        THA: response.data.thai,
      };
      setAsia(data);
    } else {
      return;
    }
  };

  const chart1option = {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: "260px",
      width: 720,
    },
    title: {
      text: null,
    },
    subtitle: {
      useHTML: true,
      text:
        `<span style="display: block;text-align: center;font-size: 24px;color: #000;">Total</span><b style="display: block;text-align: center;font-size: 48px;font-weight: 800;color: #000;">` +
        totalScope +
        `</b>`,
      floating: true,
      verticalAlign: "middle",
      align: "center",
      x: -155,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      labelFormat:
        '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
      layout: "vertical",
      itemStyle: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "700",
        textOverflow: "ellipsis",
      },
      itemMarginBottom: 15,
      x: -150,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 220,
      },
      series: {
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        data:
          scope?.map((data) => ({
            y: data.y,
            name: data.name,
            color: data.color,
          })) || [],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  const chart2option = {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: "260px",
      width: 720,
    },
    title: {
      text: null,
    },
    subtitle: {
      useHTML: true,
      text: `<b style="color:#000;font-size: 48px;font-weight: 800;">Region</b>`,
      floating: true,
      verticalAlign: "middle",
      align: "center",
      x: -155,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      labelFormat:
        '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
      layout: "vertical",
      itemStyle: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "700",
        textOverflow: "ellipsis",
      },
      itemMarginBottom: 5,
      x: -100,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 220,
      },
      series: {
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        data:
          region?.map((data) => ({
            y: data.y,
            name: data.name,
            color: data.color,
          })) || [],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  const chart3option = {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: 450,
    },
    title: {
      text: null,
    },
    subtitle: {
      useHTML: true,
      text: `<b style="color:#000;font-size: 38px;font-weight: 800;">Activity</b>`,
      floating: true,
      verticalAlign: "middle",
      align: "center",
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      verticalAlign: "bottom",
      floating: true,
      labelFormat:
        '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
      itemStyle: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "700",
        textOverflow: "ellipsis",
      },
      itemMarginBottom: 5,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 220,
      },
      series: {
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        data:
          sector?.map((data) => ({
            y: data.y,
            name: data.name,
            color: data.color,
          })) || [],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <>
      <div className="pageTitle">{t(`OVERVIEWDATA.welcomCal`)}</div>
      <div className="dataOverview">
        <strong>{t(`OVERVIEWDATA.softwareDesc`)}</strong>
        <div className="DataOverviewGrid">
          <div className="cbSpBox span4">
            <div className="boxTitle">{t(`OVERVIEWDATA.dataStatus`)}</div>
            <div className="DataChart">
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chart1option}
                />
              </div>
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chart2option}
                />
              </div>
            </div>
          </div>
          <div className="cbSpBox span4">
            <div className="boxTitle">{t(`OVERVIEWDATA.majorSectors`)}</div>
            <div className="ActiveChart">
              <HighchartsReact highcharts={Highcharts} options={chart3option} />
            </div>
          </div>
          <div className="cbSpBox span2">
            <div className="boxTitle">{t(`OVERVIEWDATA.asiaPacific`)}</div>

            {asia && <Asia asiaData={asia} />}
          </div>

          <HardCodingPub />
        </div>
      </div>
    </>
  );
}

const Asia = (data) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mapCont">
        <div>
          <p>
            <img src={map01} />
          </p>
          <dl>
            <dt>{data.asiaData.KOR}</dt>
            <dd>
              {t("OVERVIEWDATA.korEmission")
                .split("\n")
                .map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
            </dd>
          </dl>
        </div>
        <div>
          <p>
            <img src={map02} />
          </p>
          <dl>
            <dt>{data.asiaData.JPN}</dt>
            <dd>
              {t("OVERVIEWDATA.japEmission")
                .split("\n")
                .map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
            </dd>
          </dl>
        </div>
        <div>
          <p>
            <img src={map03} />
          </p>
          <dl>
            <dt>{data.asiaData.THA}</dt>
            <dd>{t(`OVERVIEWDATA.thaiEmission`)}</dd>
          </dl>
        </div>
      </div>
    </>
  );
};
const HardCodingPub = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.harmonization`)}</dt>
          <dd>
            {t("OVERVIEWDATA.worldSources")
              .split("\n")
              .map((line, index) => (
                <div key={index}>{line}</div>
              ))}
          </dd>
        </dl>
      </div>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.rowInspection`)}</dt>
          <dd>{t(`OVERVIEWDATA.integrity`)}</dd>
        </dl>
      </div>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.customizable`)}</dt>
          <dd>{t(`OVERVIEWDATA.optimization`)}</dd>
        </dl>
      </div>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.assistance`)}</dt>
          <dd>{t(`OVERVIEWDATA.boundarySetting`)}</dd>
        </dl>
      </div>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.ease`)}</dt>
          <dd>{t(`OVERVIEWDATA.supplementary`)}</dd>
        </dl>
      </div>
      <div className="cbSpBox overviewBox">
        <dl>
          <dt>{t(`OVERVIEWDATA.readiness`)}</dt>
          <dd>{t(`OVERVIEWDATA.assumptions`)}</dd>
        </dl>
      </div>
    </>
  );
};
export default DataOverview;
