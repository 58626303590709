import React from "react";
import { SyncLoader } from "react-spinners";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)", // 회색 배경색
  zIndex: 9999, // 가장 위로 올리기
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const loaderStyle = {
  borderColor: "black",
};


export default function Loading() {
  return (
    <div style={overlayStyle}>
      {/*<SyncLoader
        color="rgba(90, 90, 90, 0.9)"
        cssOverride={loaderStyle}
        size={50}
      />*/}
      <div className="LoadingImg" />
    </div>
  );
}
