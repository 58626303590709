import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
// import { getFirstActivityLevel } from "../../service/dataExplorerService";
import {
  getEmissionActivityCategoryList,
  getBizCategory,
} from "../../service/activityCategoryService";
import ActivityCategoryBookmarkList from "./BeforeActivityCategoryBookmarkList2024";
import { useNavigate } from "react-router-dom";

function ActivityCategoryBookmark() {
  const navigate = useNavigate();
  const userJSON = localStorage.getItem("user");
  let userInfo;
  if (userJSON) {
    userInfo = jwt_decode(JSON.parse(userJSON)?.accessToken);
  }

  const [selectTilte, setSelectTilte] = useState([null, null, null, null]); // selected title
  const [selectView, setSelectView] = useState([false]); // select view control

  // select data
  const [selectedData, setSelectData] = useState({
    selectedData1: null,
  });
  const [levelInfo, setLevelInfo] = useState([]); // activity level
  const [ativityCategoryDataList, setActivityCategoryDataList] = useState(null); // activityCategory 조회 데이터 리스트

  // 초기 level1 세팅
  useEffect(() => {
    if (userInfo?.userName) {
      firstLevel1();
    } else {
      navigate("/login2024");
    }
  }, []);

  const firstLevel1 = async () => {
    try {
      // const response = await getFirstActivityLevel();
      const response = await getBizCategory();
      if (response.status === 200) {
        setLevelInfo((prev) => [
          ...prev,
          {
            Level1: response.data,
          },
        ]);
      }
    } catch (error) {
      console.error("getFirstActivityLevel Error:", error);
    }
  };

  useEffect(() => {
    if (selectedData.selectedData1 !== null) {
      // getCategoryList(userInfo.userNo, selectedData.selectedData1.factCatgId);
      getCategoryList(userInfo.userNo, selectedData.selectedData1.curtCatgNo);
    }
  }, [selectedData]);

  const getCategoryList = async (userNo, catgId) => {
    try {
      const response = await getEmissionActivityCategoryList(userNo, catgId);
      if (response.status === 200) {
        setActivityCategoryDataList(response.data);
      }
    } catch (error) {
      console.error("getEmissionActivityCategoryList Error:", error);
    }
  };

  // 셀렉트 박스 클릭 시
  const selectOnClcik = (selectIndex) => {
    let copySelectViewControl = [...selectView];
    copySelectViewControl[selectIndex] = !copySelectViewControl[selectIndex];

    // 자기 자신 외 모두 닫기
    for (let i = 0; i < 4; i++) {
      if (i !== selectIndex) {
        copySelectViewControl[i] = false;
      }
    }
    setSelectView(copySelectViewControl);
  };

  // 셀렉트 옵션 클릭 시
  const optionOnClick = (optionData, ontionIndex, selectIndex) => {
    titleSetting();
    selectedDataSetting();

    function selectedDataSetting() {
      // 데이터 셋팅
      let copySelectedData = [selectedData];
      copySelectedData[`selectedData${selectIndex + 1}`] = optionData;
      setSelectData(copySelectedData);

      // 옵션 컨트롤
      let copySelectViewControl = [...selectView];
      copySelectViewControl[selectIndex] = false;
      setSelectView(copySelectViewControl);
    }

    // 셀렉트 박스 선택 시 보여지는 타이틀 세팅
    function titleSetting() {
      let copySelectTitle = [...selectTilte];
      // if (selectIndex === 0) {
      //   copySelectTitle[selectIndex] =
      //     optionData.factCatgName + "(" + optionData.cbScopeId + ")";
      // } else if (selectIndex > 0) {
      //   copySelectTitle[selectIndex] = optionData.factItemName;
      // }
      copySelectTitle[selectIndex] = optionData.curtCatgName;
      setSelectTilte(copySelectTitle);
    }
  };
  return (
    <>
      <div className="pageTitle">My Emission Activity Category Bookmark</div>
      <div className="cbSpBox innerScroll">
        <div className="dataExpInput">
          {levelInfo?.map((data, selectIndex) => (
            <div className="inputObj wide" key={selectIndex}>
              <div>
                {selectTilte && (
                  <p
                    onClick={() => {
                      selectOnClcik(selectIndex);
                    }}
                  >
                    {selectTilte[selectIndex] ? (
                      <b className="ellipsis">{selectTilte[selectIndex]}</b>
                    ) : (
                      <b className="ellipsis placeholder">
                        {/* Select Lv{selectIndex + 1} */}
                        Select Template
                      </b>
                    )}

                    <button>열림</button>
                  </p>
                )}
                <ul
                  style={{
                    display: selectView[selectIndex] ? "block" : "none",
                  }}
                >
                  {data[`${"Level" + (selectIndex + 1)}`]?.map(
                    (optionData, ontionIndex) => (
                      <li
                        key={ontionIndex}
                        onClick={() => {
                          optionOnClick(optionData, ontionIndex, selectIndex);
                        }}
                      >
                        {/* {selectIndex === 0
                          ? optionData.factCatgName +
                            "(" +
                            optionData.cbScopeId +
                            ")"
                          : optionData.factItemName} */}
                        {optionData.curtCatgName}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <ActivityCategoryBookmarkList
          dataList={ativityCategoryDataList}
          selectedData={selectedData}
          userInfo={userInfo}
        />
      </div>
    </>
  );
}

export default ActivityCategoryBookmark;
