import React, { useEffect } from "react";

const SelectBox = (props) => {
  const {
    placeholder = "Please Select",
    options = [
      { label: "Freight vehicles", value: "Freight vehicles" },
      { label: "Passenger vehicels", value: "Passenger vehicels" },
      {
        label: "Vehicles for passengers and freight",
        value: "Vehicles for passengers and freight",
      },
    ],
    value,
    isOpen,
    onClick,
    onChange,
  } = props;

  useEffect(() => {}, [props]);

  const toggleSelect = () => {
    onClick();
  };

  const handleOptionClick = (option) => {
    onChange(option);
    onClick();
  };

  return (
    <div onClick={toggleSelect}>
      <p>
        <b> {value ? value.label : placeholder}</b>
        <button onClick={toggleSelect}>열림</button>
      </p>
      <ul style={{ display: isOpen }}>
        {options.map((option, index) => (
          <li key={index} onClick={() => handleOptionClick(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectBox;
