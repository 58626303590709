import React from "react";

function CreateAccount() {
    return (
        <>
            <div className="pageTitle">Create Account</div>
            <div className="cbSpBox accountBox">
                <div className="accountWrap">
                    <div className="accountInput">
                        <div className="accInp">
                            <div>
                                <label>First name</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>
                            <div>
                                <label>Last name</label>
                                <p>
                                    <input type="text" value="Kim"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Company Name</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Company Email</label>
                                <div>
                                    <p>
                                        <input type="text" value="jaehong"/>
                                    </p>
                                    <button>Verification</button>
                                </div>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Verification Code</label>

                                <p className="verifi">
                                    <input type="text" value="jaehong"/>
                                    <em>10:00</em>
                                </p>
                                <p className="success">
                                    <input type="text" value="jaehong"/>
                                </p>
                                <p className="error">
                                    <input type="text" value="jaehong"/>
                                </p>
                                <span className="errorText">error msg</span>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Industry</label>
                                <div className="selectObj">
                                    <p className="d"><span>Agriculture, Forestry, Fishing and Hunting</span></p>
                                    <ul>
                                        <li>Agriculture, Forestry, Fishing and Hunting</li>
                                        <li>Mining, Quarrying, and Oil and Gas Extraction</li>
                                        <li>Utilities</li>
                                        <li>Construction</li>
                                        <li>Manufacturing</li>
                                        <li>Wholesale Trade</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Role</label>
                                <p>
                                    <input type="text" value="Product Designer"/>
                                </p>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Create Password</label>
                                <p>
                                    <input type="password" value="jaehong"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Confirm Password</label>
                                <p>
                                    <input type="password" value="jaehong"/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accountMsg">
                        By selecting Create account, you agree to our <b>Terms</b> and
                        have read and acknowledge our <b>Global Privacy Statement.</b>
                        <p className="chkWrap">
                            <input type="checkbox" />
                            <label>Agree</label>
                        </p>
                    </div>
                    <div className="accountBtn">
                        <button>Sign in</button>
                        <p>If you already have an account, <a href="#">Sign in.</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateAccount;