import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { logOut } from "../../util/authUtil";
import {
  getIndustryCatg,
  getMyInfo,
  insertRequestTemplate,
} from "../../service/userRequestService";
import { alertData } from "../../util/alertData";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";

function RequestTemp() {
  const [popupStatus, setPopupStatus] = useState(false);
  const [myInfo, setMyInfo] = useState(null);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [catgData, setCatgData] = useState();
  const [viewCheck, setViewCheck] = useState(false);
  const [formData, setFormData] = useState({
    title: "Please Select",
    contents: "",
  });

  const userJSON = JSON.parse(localStorage.getItem("user"));
  let userInfo = null;
  if (userJSON) {
    userInfo = jwtDecode(userJSON.accessToken);
  }

  useEffect(() => {
    if (userInfo?.userName) {
      getCategoryData();
      searchMyInfo();
    } else {
      navigate("/login2024");
    }
  }, []);

  const searchMyInfo = async () => {
    try {
      const response = await getMyInfo(userInfo.userNo);
      if (response.status === 200) {
        let cpFormData = formData;
        cpFormData.title = response.data.cdName;
        setFormData(cpFormData);

        setMyInfo(response.data);
      }
    } catch (error) {
      console.error("getMyInfo Error:", error);
    }
  };

  const getCategoryData = async () => {
    try {
      const response = await getIndustryCatg();
      if (response.status === 200) {
        setCatgData(response.data);
      }
    } catch (error) {
      console.error("getIndustryCatg Error:", error);
    }
  };

  const optionSelected = (data) => {
    setFormData({ title: data.cdName, contents: formData.contents });
    setViewCheck(false);
  };

  const submitRequest = () => {
    if (formData.title === "Please Select" || !formData.contents) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.allCheckValue",
      }));
      setPopupStatus(true);
    } else {
      insertData();
    }
  };

  const insertData = async () => {
    try {
      const param = {
        userMail: userInfo.userMail,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        rqtCatg: formData.title,
        rqtContent: formData.contents,
        rqtType: "template",
      };
      const response = await insertRequestTemplate(param);
      if (response.status === 200) {
        setPopupData((prevData) => ({
          ...prevData,
          popupStatus: true,
          content: "ALERT.writeComplete",
        }));
        setPopupStatus(true);

        setFormData((prev) => ({
          ...prev,
          contents: "",
        }));
        document.getElementById("content").value = "";
        // window.location.reload();
      }
    } catch (error) {
      console.error("insertRequestTemplate Error:", error);
    }
  };
  return (
    <>
      <div className="pageTitle">{t(`REQUESTCAL.requestCal`)}</div>
      <div className="cbSpBox accountBox">
        <div className="requestWrap">
          <div className="accountInput">
            <div className="accInp">
              <div>
                <label>{t(`REQUESTCAL.firstNm`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.firstName ? userInfo.firstName : ""}
                  />
                </p>
              </div>
              <div>
                <label>{t(`REQUESTCAL.lastNm`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.lastName ? userInfo.lastName : ""}
                  />
                </p>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`REQUESTCAL.workEmail`)}</label>
                <p>
                  <input
                    type="text"
                    readOnly
                    value={userInfo?.userMail ? userInfo.userMail : ""}
                  />
                </p>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`REQUESTCAL.industryDomain`)}</label>
                <div className="selectObj">
                  <p
                    onClick={() => {
                      setViewCheck(!viewCheck);
                    }}
                  >
                    <span className="placeholder">{formData.title}</span>
                  </p>
                  <ul style={{ display: viewCheck ? "block" : "none" }}>
                    {catgData?.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          optionSelected(data);
                        }}
                      >
                        {data.cdName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`REQUESTCAL.help`)}</label>
                <p>
                  <textarea
                    id="content"
                    onChange={(e) => {
                      setFormData({
                        title: formData.title,
                        contents: e.target.value,
                      }); //
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="accountMsg flex">
            {t(`REQUESTCAL.policy`)}
            <button>
              <Link to="/privacyPolicy2024">Check our Privacy Policy</Link>
            </button>
          </div>
          <div className="accountBtn">
            <button type="submit" onClick={submitRequest}>
              {t(`REQUESTCAL.submit`)}
            </button>
          </div>
        </div>
      </div>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default RequestTemp;
