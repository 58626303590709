import React from "react";

function RequsetTemp() {
    return (
        <>
            <div className="pageTitle">
                Request Calculator for your industry domain
            </div>
            <div className="cbSpBox accountBox">
                <div className="requestWrap">
                    <div className="accountInput">
                        <div className="accInp">
                            <div>
                                <label>First name*</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>
                            <div>
                                <label>Last name*</label>
                                <p>
                                    <input type="text" value="Kim"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Work Email*</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Tell us your industry domain*</label>
                                <div className="selectObj">
                                    <p><span className="placeholder">Please Select</span></p>
                                    <ul>
                                        <li>Agriculture, Forestry, Fishing and Hunting</li>
                                        <li>Mining, Quarrying, and Oil and Gas Extraction</li>
                                        <li>Utilities</li>
                                        <li>Construction</li>
                                        <li>Manufacturing</li>
                                        <li>Wholesale Trade</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>How can we help?</label>
                                <p>
                                    <textarea/>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="accountMsg">
                        We need the contact information you provide to us to contact you about our products and
                        services.
                        Please review our <b>Privacy Policy.</b>
                    </div>
                    <div className="accountBtn">
                        <button>Submit</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RequsetTemp;