import { useEffect, useState } from "react";
import {
  addEmssionDataBookmark,
  deleteEmssionDataBookmark,
  findEmissionData,
} from "../../service/dataExplorerService";
import { alertData } from "../../util/alertData";
import { decrypt } from "../../util/crypto";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { nowInsertDate } from "../../util/common";
import { useDispatch, useSelector } from "react-redux";
import { setRecentFindData } from "../../util/recentFindDataParamSlice";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../util/loadingSlice";

function FindDataExplorer2024() {
  const { t } = useTranslation();
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const nav = useNavigate();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState(); // 데이터 리스트 원본
  const [viewDataList, setViewDataList] = useState(); // 필터링을 위한 데이터 리스트
  const [optionList, setOptionList] = useState();
  const [openSelect, setOpenSelect] = useState(null);

  const recentFindData = useSelector((state) => state.recentFindDataParamSlice);

  const [selectTitle, setSelectTitle] = useState({
    scope: { label: "Scope", value: "" },
    region: { label: "Region", value: "" },
    year: { label: "Data year", value: "" },
    unit: { label: "Unit", value: "" },
    source: { label: "Source", value: "" },
    gwp: { label: "GWP100", value: "" },
  });

  //   const [selectTitle]
  const token = localStorage.getItem("user");
  const userInfo = token ? jwt_decode(token) : null;

  useEffect(() => {
    if (!userInfo) {
      nav("/login2024");
    }
  }, [userInfo]);

  const handleSearchData = async (keyword) => {
    if (keyword) {
      dispatch(setLoading({ viewOption: true }));
      const param = {
        keyword: keyword,
        userNo: userInfo ? userInfo.userNo : null,
      };
      const response = await findEmissionData(param);
      if (response.status === 200) {
        dispatch(setLoading({ viewOption: false }));
        if (response.data[0].nullData === "Y") {
          setPopupData((prevData) => ({
            ...prevData,
            popupStatus: true,
            content: "ALERT.searchDataNull",
          }));
          setPopupStatus(true);

          return;
        }
        if (response.data[0].overData === "Y") {
          setPopupData((prevData) => ({
            ...prevData,
            popupStatus: true,
            content: "ALERT.searchDataOver",
          }));
          setPopupStatus(true);
          return;
        }

        // view단에 맞게 데이터 가공
        refineData(response.data);

        // 옵션데이터가공
        optionRefine(response.data);

        setSelectTitle({
          scope: { label: "Scope", value: "" },
          region: { label: "Region", value: "" },
          year: { label: "Data year", value: "" },
          unit: { label: "Unit", value: "" },
          source: { label: "Source", value: "" },
          gwp: { label: "GWP100", value: "" },
        });
      } else {
        dispatch(setLoading({ viewOption: false }));
      }
    }
  };

  useEffect(() => {
    // 깊은 복사로 기존 original 데이터에 영향을 주지 않는다.
    let cloneOriginalData = structuredClone(dataList);

    if (cloneOriginalData) {
      const filters = [
        { key: "scope", value: selectTitle.scope.value },
        { key: "nationId", value: selectTitle.region.value },
        { key: "yyyy", value: selectTitle.year.value },
        { key: "unit", value: selectTitle.unit.value },
        { key: "srcInfo", value: selectTitle.source.value },
        { key: "rawCaculationMethod", value: selectTitle.gwp.value },
      ];

      filters.forEach((filter) => {
        if (filter.value) {
          cloneOriginalData = cloneOriginalData.filter(
            (filterData) => filterData[filter.key] === filter.value
          );
        }
      });

      // // 넣냐 안넣냐의 차이
      // optionRefine(cloneOriginalData);
      setViewDataList(cloneOriginalData);
    }
  }, [selectTitle, dataList]);

  // myBookmarkEmissionData에서 타고온 데이터
  useEffect(() => {
    if (recentFindData?.factItemAllName) {
      handleSearchData(recentFindData.factItemAllName).then(() => {
        document.getElementById("searchKeyword").value =
          recentFindData.factItemAllName;

        // 연도 /GWP 는 필터링 X
        setSelectTitle({
          scope: {
            label: recentFindData.scope,
            value: recentFindData.scope,
          },
          region: {
            label: recentFindData.nationId,
            value: recentFindData.nationId,
          },
          year: {
            label: "Data year",
            value: "",
          },
          unit: {
            label: recentFindData.unit,
            value: recentFindData.unit,
          },
          source: {
            label: recentFindData.srcInfo,
            value: recentFindData.srcInfo,
          },
          gwp: {
            label: "GWP100",
            value: "",
          },
        });
      });
      dispatch(setRecentFindData(null));
    }
  }, [recentFindData]);

  const refineData = (dataList) => {
    dataList.forEach((data) => {
      // factItemName 가공
      let splitFactName = data.factItemAllName.split(" / ");

      data.level1 = splitFactName[0] ? splitFactName[0] : null;
      data.level2 = splitFactName[1] ? splitFactName[1] : null;
      data.level3 = splitFactName[2] ? splitFactName[2] : null;
      data.level4 = splitFactName[3] ? splitFactName[3] : null;

      // 데이터 fcator val 복호화
      data.factorVal = Number(decrypt(data.factorVal));
      data.factorViewControl = false;
    });
    setDataList(dataList);
    setViewDataList(dataList);
  };

  const optionRefine = (dataList) => {
    const extractAndProcess = (key) => {
      const uniqueValues = [
        ...new Set(dataList.map((data) => data[key])),
      ].filter((e) => e != null);

      // 년도일 경우 내림차순
      const sortedValues =
        key === "yyyy"
          ? uniqueValues.sort((a, b) => b - a)
          : uniqueValues.sort();

      return sortedValues.map((e) => ({ value: e, label: e }));
    };

    const optionData = {
      scope: extractAndProcess("scope"),
      region: extractAndProcess("nationId"),
      year: extractAndProcess("yyyy"),
      unit: extractAndProcess("unit"),
      source: extractAndProcess("srcInfo"),
      gwp: extractAndProcess("rawCaculationMethod"),
    };

    setOptionList(optionData);
  };

  const onClickBookmark = (indexData) => {
    let cpDataList = structuredClone(dataList);
    const params = {
      userNo: userInfo.userNo,
      factId: indexData.factId,
      factValId: indexData.factValId,
      gasId: indexData.gasId,
      condExp: indexData.condExp,
      regDtm: nowInsertDate(),
      useYn: "Y",
    };

    if (indexData.bookmarkYn === 1) {
      deleteBookmark(cpDataList, params, indexData);
    } else if (indexData.bookmarkYn === 0) {
      addBookmark(cpDataList, params, indexData);
    }
  };

  const deleteBookmark = async (cpDataList, params, indexData) => {
    const response = await deleteEmssionDataBookmark(params);
    if (response.status === 200) {
      cpDataList.forEach((data) => {
        if (
          data.level1 === indexData.level1 &&
          data.level2 === indexData.level2 &&
          data.level3 === indexData.level3 &&
          data.level4 === indexData.level4 &&
          data.unit === indexData.unit &&
          data.gasId === indexData.gasId &&
          data.year === indexData.year &&
          data.srcInfo === indexData.srcInfo &&
          data.rawCaculationMethod === indexData.rawCaculationMethod &&
          data.factValId === indexData.factValId
        ) {
          data.bookmarkYn = 0;
        }
      });

      setDataList(cpDataList);
    }
  };

  const addBookmark = async (cpDataList, params, indexData) => {
    const response = await addEmssionDataBookmark(params);
    if (response.status === 200) {
      cpDataList.forEach((data) => {
        if (
          data.level1 === indexData.level1 &&
          data.level2 === indexData.level2 &&
          data.level3 === indexData.level3 &&
          data.level4 === indexData.level4 &&
          data.unit === indexData.unit &&
          data.gasId === indexData.gasId &&
          data.year === indexData.year &&
          data.srcInfo === indexData.srcInfo &&
          data.rawCaculationMethod === indexData.rawCaculationMethod &&
          data.factValId === indexData.factValId
        ) {
          data.bookmarkYn = 1;
        }
      });

      setDataList(cpDataList);
    }
  };

  const clickCheckData = (data, index) => {
    let cpViewList = structuredClone(viewDataList);
    let cpDataList = structuredClone(dataList);

    cpDataList.forEach((result) => {
      if (
        result.level1 === data.level1 &&
        result.level2 === data.level2 &&
        result.level3 === data.level3 &&
        result.level4 === data.level4 &&
        result.unit === data.unit &&
        result.gasId === data.gasId &&
        result.year === data.year &&
        result.srcInfo === data.srcInfo &&
        result.rawCaculationMethod === data.rawCaculationMethod &&
        result.factValId === data.factValId
      ) {
        result.factorViewControl = true;
      }
    });
    setDataList(cpDataList);

    cpViewList[index].factorViewControl = true;
    setViewDataList(cpViewList);
  };

  const handleClick = (selectName) => {
    setOpenSelect(openSelect === selectName ? null : selectName);
  };
  return (
    <>
      <div className="pageTitle">{t("FINDDATA.title")}</div>
      <div className="cbSpBox">
        <div className="findKeyword">
          <div>
            <input
              id="searchKeyword"
              type="text"
              placeholder={t("FINDDATA.search")}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearchData(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="findGrid">
          <dl className="filter">
            <dt>Filter</dt>
            <dd>
              <SelectObj
                title={selectTitle.scope}
                options={optionList?.scope}
                isOpen={openSelect === "scope"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("scope")}
              />
              <SelectObj
                title={selectTitle.region}
                options={optionList?.region}
                isOpen={openSelect === "region"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("region")}
              />
              <SelectObj
                title={selectTitle.year}
                options={optionList?.year}
                isOpen={openSelect === "year"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("year")}
              />
              <SelectObj
                title={selectTitle.unit}
                options={optionList?.unit}
                isOpen={openSelect === "unit"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("unit")}
              />
              <SelectObj
                title={selectTitle.source}
                options={optionList?.source}
                isOpen={openSelect === "source"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("source")}
              />
              <SelectObj
                title={selectTitle.gwp}
                options={optionList?.gwp}
                isOpen={openSelect === "gwp"}
                opneData={{ openSelect, setOpenSelect }}
                titleData={{ selectTitle, setSelectTitle }}
                onClick={() => handleClick("gwp")}
              />

              <div className="ResetBtn">
                <button
                  onClick={() => {
                    setSelectTitle({
                      scope: { label: "Scope", value: "" },
                      region: { label: "Region", value: "" },
                      year: { label: "Data year", value: "" },
                      unit: { label: "Unit", value: "" },
                      source: { label: "Source", value: "" },
                      gwp: { label: "GWP100", value: "" },
                    });
                  }}
                >
                  <span>{t("FINDDATA.reset")}</span>
                </button>
              </div>
            </dd>
          </dl>
          <div className="dataExpStickyTable">
            <table>
              <colgroup>
                <col width="12%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="17%" />
                <col width="15%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <div>
                      <b>Activity Level1</b>
                    </div>
                  </th>
                  <th>
                    <div>
                      <b>Activity Level2</b>
                    </div>
                  </th>
                  <th>
                    <div>
                      <b>Activity Level3</b>
                    </div>
                  </th>
                  <th>
                    <div>
                      <b>Activity Level4</b>
                    </div>
                  </th>
                  <th>
                    <div className="right">
                      <b>Emission Factor</b>
                    </div>
                  </th>
                  <th>
                    <div className="right">
                      <b>LCA Activity</b>
                    </div>
                  </th>
                  <th>
                    <div>
                      <b>Bookmark</b>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!viewDataList && (
                  <tr>
                    <td colSpan={7}>
                      <div className="noData">
                        {t("FINDDATA.searchExplore")}
                      </div>
                    </td>
                  </tr>
                )}
                {viewDataList &&
                  viewDataList.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div>{data.level1}</div>
                      </td>
                      <td>
                        <div>{data.level2}</div>
                      </td>
                      <td>
                        <div>{data.level3}</div>
                      </td>
                      <td>
                        <div>{data.level4}</div>
                      </td>
                      <td>
                        <div className="right">
                          {data.factorViewControl && (
                            <span
                              className="chkData on"
                              style={{ textAlign: "right" }}
                            >
                              {/* 줄바꿈 확인(길이가 너무 길다) */}
                              {data.factorVal} <br />
                              kgCO2e of {data.gasId}
                            </span>
                          )}
                          {!data.factorViewControl && (
                            <span
                              style={{ cursor: "pointer" }}
                              className="chkData"
                              onClick={() => {
                                clickCheckData(data, index);
                              }}
                            >
                              Check Data
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="right">--</div>
                      </td>
                      <td>
                        <div className="center">
                          <button
                            className={data.bookmarkYn === 1 ? "fav on" : "fav"}
                            onClick={() => {
                              onClickBookmark(data);
                            }}
                          ></button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

const SelectObj = ({
  title,
  options,
  isOpen,
  opneData,
  titleData,
  onClick,
}) => {
  const handleOptionClick = (data) => {
    opneData.setOpenSelect(false);
    const { openSelect } = opneData;

    const selectTitleKeyMap = {
      scope: "scope",
      region: "region",
      year: "year",
      unit: "unit",
      source: "source",
      gwp: "gwp",
    };

    if (selectTitleKeyMap[openSelect]) {
      titleData.setSelectTitle({
        ...titleData.selectTitle,
        [selectTitleKeyMap[openSelect]]: data,
      });
    }
  };

  return (
    <div className="selectObj">
      <p onClick={onClick}>
        <span style={{ color: title.value ? "#4E75FF" : "#000000" }}>
          {title?.label}
        </span>
      </p>
      <ul style={{ display: isOpen ? "block" : "none" }}>
        {options?.map((data, i) => (
          <li key={i} onClick={() => handleOptionClick(data)}>
            {data.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FindDataExplorer2024;
