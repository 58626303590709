import { useParams } from "react-router-dom";
import CalcuExplorer from "./CalcuExplorer";
import Bookmark from "./Bookmark";
import SignIn from "./SignIn";
import DataExplorer from "./DataExplorer";
import DataOverview from "./DataOverview";
import DataBookmark from "./DataBookmark";

import Home from "./Home";
import Home_Kr from "./Home_Kr";

import CalcuOverview from "./CalcuOverview";
import CalcuOverview_Kr from "./CalcuOverview_Kr";

import RequsetTemp from "./RequsetTemp";
import CreateAccount from "./CreateAccount";
import HelpSupport from "./HelpSupport";
import DataSource from "./DataSource";
import Pricing from "./Pricing";
import AccountEdit from "./AccountEdit";
import AccountInfo from "./AccountInfo";
import ActivityCategoryBookmark from "./ActivityCategoryBookmark";
import PrivacyPolicy from "./PrivacyPolicy";
import Password_Step1 from "./Password_Step1";
import Password_Step2 from "./Password_Step2";

import Find from "./Find";
import Integrate from "./Integrate";
import IntegrateCalcu from "./IntegrateCalcu";
import IntegrateData from "./IntegrateData";
import HomeNew from "./HomeNew";

const PUBFile = () => {
  const { pageID } = useParams();
  const loadPage = (pageID) => {
    switch (pageID) {
      case "calcExp":
        return <CalcuExplorer />;
      case "bookmark" :
        return <Bookmark />;
      case "signin" :
        return <SignIn />;
      case "dExp" :
        return <DataExplorer />;
      case "dOverview" :
        return <DataOverview />;
      case "dBookmark" :
        return <DataBookmark />;
      case "home" :
        return <Home />;
      case "homeKr" :
        return <Home_Kr />;
      case "calcOverview" :
        return <CalcuOverview />;
      case "calcOverviewKr" :
        return <CalcuOverview_Kr />;
      case "reqTemp" :
        return <RequsetTemp />;
      case "createacc" :
        return <CreateAccount />;
      case "help" :
        return <HelpSupport />;
      case "dSource" :
        return <DataSource />;
      case "price" :
        return <Pricing />;
      case "accInfo" :
        return <AccountInfo />;
      case "accEdit" :
        return <AccountEdit />;
      case "aBookmark" :
        return <ActivityCategoryBookmark />;
      case "policy" :
        return <PrivacyPolicy />;
      case "passstep1" :
        return <Password_Step1 />;
      case "passstep2" :
        return <Password_Step2 />;
      case "find" :
        return <Find />;
      case "integrate" :
        return <Integrate />;
      case "integrateCal" :
        return <IntegrateCalcu />;
      case "integrateData" :
        return <IntegrateData />;
      case "homeNew" :
        return <HomeNew />;
      default:
        return <></>;
    }
  };
  return <>{loadPage(pageID)}</>;
};

export default PUBFile;
