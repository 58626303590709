import { GET, POST } from "./fetch-auth-action.ts";
import { createTokenHeader } from "../util/authUtil.js";

export const getFirstActivityLevel = async () => {
  const URL = "/api/bookmark/getActivityLevel";
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getFirstActivityChildLevel = async (factCatgId) => {
  const URL = "/api/bookmark/getFirstActivityChildLevel/" + factCatgId;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getActivityChildLevel = async (factId) => {
  const URL = "/api/bookmark/getActivityChildLevel/" + factId;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getDataExplorerList = async (factId) => {
  const URL = "/api/bookmark/getDataExplorerList/" + factId;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getActivityCategoryCount = async (factId, userNo) => {
  const URL = "/api/bookmark/getActivityCategoryCount/" + factId + "/" + userNo;
  let header = await createTokenHeader();

  const response = GET(URL, header);
  return response;
};

// 2차 기획안
// Data Explorer 검색 api
export const findEmissionData = async (param) => {
  const URL = "/api/dataStatus/findEmissionData";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const addEmssionDataBookmark = async (param) => {
  const URL = "/api/dataStatus/addEmssionDataBookmark";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const deleteEmssionDataBookmark = async (param) => {
  const URL = "/api/dataStatus/deleteEmssionDataBookmark";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const getMyEmissionData = async (userNo) => {
  const URL = "/api/dataStatus/getMyEmissionData/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};
