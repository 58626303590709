import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LnbBottom = ({ currentMain, setCurrentMain, setViewDetail }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userJSON = localStorage.getItem("user");
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (userJSON) {
      const userInfo = jwt_decode(JSON.parse(userJSON)?.accessToken);
      setUserData(userInfo);
    }
  }, [userJSON]);
  return (
    <>
      <div className="btm">
        {/*<p className={currentMain === "notifi" ? "line on" : "line"}>
          <span
            className="notifi"
            onClick={() => {
              setCurrentMain("notifi");
              setViewDetail(true);
            }}
          >
            <b>
              <u className="new"></u>
            </b>
            <em>{t(`LNB.note`)}</em>
          </span>
        </p>*/}

        {!userData && (
          <p
            className={
              currentMain === "signin" ? "accountWrap on" : "accountWrap"
            }
          >
            <span
              className="signin"
              onClick={() => {
                setCurrentMain("signin");
                setViewDetail(true);
                navigate("/login2024");
              }}
            >
              <b></b>
              <em>{t(`LNB.signin`)}</em>
            </span>
          </p>
        )}

        {userData && (
          <p
            className={
              currentMain === "account" ? "accountWrap on" : "accountWrap"
            }
          >
            <span
              className="account"
              onClick={() => {
                setCurrentMain("account");
                setViewDetail(true);
              }}
            >
              <b>{userData.userName[0]}</b>
              <em>{t(`LNB.profile`)}</em>
            </span>
          </p>
        )}
      </div>
    </>
  );
};

export default LnbBottom;
