import React from "react";

function SignIn(){
    return (
        <>
            <div className="pageTitle">
                Sign in
            </div>
            <div className="cbSpBox accountBox">
                <div className="accountWrap">
                    <div className="accountInput">
                        <div className="accInp">
                            <div>
                                <label>Company Email</label>
                                <p>
                                    <input type="text" value="jaehongkim@thinspire.com"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Password</label>
                                <p>
                                    <input type="password"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Password</label>
                                <p className="error">
                                    <input type="password"/>
                                </p>
                                <span className="errorText">Incorrect data. Please check your info.</span>
                            </div>
                        </div>
                    </div>
                    <div className="accountBtn">
                        <button>Sign in</button>
                        <button className="forgot">Forgot Password?</button>
                        <p>If you don't have account, <a href="#">Sign Up.</a></p>
                    </div>
                </div>

            </div>
        </>
    );
}

export default SignIn;