import { GET, POST } from "./fetch-auth-action.ts";
import { createTokenHeader } from "../util/authUtil.js";

export const getIndustryCatg = async () => {
  const URL = "/api/userRequest/getIndustryCatg";
  const response = GET(URL, null);
  return response;
};

export const insertRequestTemplate = async (param) => {
  const URL = "/api/userRequest/postRequestTemplate";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const insertRequestContactus = async (param) => {
  const URL = "/api/userRequest/postRequestContactus";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const getMyInfo = async (userNo) => {
  const URL = "/api/userRequest/getUserInfo/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const updateMyInfo = async (param) => {
  const URL = "/api/userRequest/updateMyInfo";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};

export const updatePassword = async (param) => {
  const URL = "/api/userRequest/updatePassword";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);
  return response;
};
