import React from "react";

function HelpSupport() {
    return (
        <>
            <div className="pageTitle">
                Need help? Contact us
            </div>
            <div className="cbSpBox accountBox">
                <div className="requestWrap">
                    <div className="accountInput">
                        <div className="accSelect">
                            <label>Select the items that you are interested in*</label>
                            <ul>
                                <li>
                                    <input type="checkbox" checked/>
                                    <label><em></em>Price Payment Process</label>
                                </li>
                                <li>
                                    <input type="checkbox"/>
                                    <label><em></em>Emissions Calculator & Data API integration</label>
                                </li>
                                <li>
                                    <input type="checkbox"/>
                                    <label><em></em>Carbon Accounting consulting for your business</label>
                                </li>
                                <li>
                                    <input type="checkbox"/>
                                    <label><em></em>Partnering with us</label>
                                </li>
                                <li>
                                    <input type="checkbox"/>
                                    <label><em></em>Explore career opportunities</label>
                                </li>
                                <li>
                                    <input type="checkbox"/>
                                    <label><em></em>Press/Media request</label>
                                </li>
                            </ul>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>First name*</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>
                            <div>
                                <label>Last name*</label>
                                <p>
                                    <input type="text" value="Kim"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Work Email*</label>
                                <p>
                                    <input type="text" value="jaehong"/>
                                </p>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Are you interested in*</label>
                                <div className="selectObj">
                                    <p><span className="placeholder">Please Select</span></p>
                                    <ul>
                                        <li>Emission Data & Calculator API integration</li>
                                        <li>Carbon Accounting consulting for your business</li>
                                        <li>Partnering with Us</li>
                                        <li>Explore career opportunities</li>
                                        <li>Press/Media request</li>
                                        <li>Something else</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>How can we help?</label>
                                <p>
                                    <textarea/>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="accountMsg flex">
                        We need the contact information you provide to us to contact you about our products and
                        services. Please review our Privacy Policy.
                        <button>Check our Privacy Policy</button>
                    </div>
                    <div className="accountBtn">
                        <button>Submit</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HelpSupport;