import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordResetPop from "../../popup2024/PasswordResetPop2024";
import { updateMyInfo } from "../../../service/userRequestService";
import { alertData } from "../../../util/alertData";
import { nowInsertDate } from "../../../util/common";
import AlertAndConfirmPop from "../../popup2024/AlertAndConfirmPop2024";

function AccountEdit2024({ prop }) {
  const [alertStatus, setAlertStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: alertStatus,
    confirmText: null,
    setPopupStatus: setAlertStatus,
  });

  const { t } = useTranslation();
  const { myInfo, setMyInfo, setEditMode, industryList } = prop;
  const [copyData, setCopyData] = useState({});
  const [dataError, setDataError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    industryCd: "",
    userRole: "",
  });
  const [selectTitle, setSelectTitle] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);

  useEffect(() => {
    if (myInfo) {
      setCopyData(structuredClone(myInfo));
      setSelectTitle({
        cd: myInfo?.industryCd,
        nm: myInfo?.cdName,
      });
    }
  }, [myInfo]);

  const handleSelectOnclick = (data) => {
    setSelectTitle({
      cd: data.cdCode,
      nm: data.cdName,
    });

    setCopyData({
      ...copyData,
      industryCd: data.cdCode,
      cdName: data.cdName,
    });

    setOpenSelect(false);
  };

  const handleSubmit = async () => {
    // 데이터가 바뀌지 않았다면
    if (
      myInfo.firstName === copyData.firstName &&
      myInfo.lastName === copyData.lastName &&
      myInfo.userRole === copyData.userRole &&
      myInfo.industryCd === copyData.industryCd
    ) {
      setEditMode(false);
      return;
    }

    if (isNullCheck()) {
      const param = {
        userNo: copyData.userNo,
        firstName: copyData.firstName,
        lastName: copyData.lastName,
        industryCd: copyData.industryCd,
        userRole: copyData.userRole,
        chgDtm: nowInsertDate(),
      };
      try {
        const response = await updateMyInfo(param);
        if (response.status === 200) {
          if (response.data === 1) {
            setEditMode(false);
            setMyInfo(structuredClone(copyData));
          } else {
            setPopupData((prevData) => ({
              ...prevData,
              popupStatus: true,
              content: "ALERT.failEditAlert",
            }));
            setAlertStatus(true);
          }
        }
      } catch (error) {
        console.error("updateMyInfo Error:", error);
      }
    }
  };

  const isNullCheck = () => {
    let errorList = { ...dataError };
    if (!copyData.firstName) {
      errorList = { ...errorList, firstName: "First Name is required" };
    } else {
      errorList = { ...errorList, firstName: "" };
    }
    if (!copyData.lastName) {
      errorList = { ...errorList, lastName: "Last Name is required" };
    } else {
      errorList = { ...errorList, lastName: "" };
    }
    if (!copyData.userRole) {
      errorList = { ...errorList, userRole: "Role is required" };
    } else {
      errorList = { ...errorList, userRole: "" };
    }
    setDataError(errorList);
    if (errorList.firstName || errorList.lastName || errorList.userRole) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="boxCont">
        <ul>
          <li>
            <span>{t(`MYINFO.email`)}</span>
            <input type="text" value={copyData?.email || ""} disabled />
          </li>
          <li>
            <span>{t(`MYINFO.firstName`)}</span>
            <input
              type="text"
              defaultValue={copyData?.firstName || ""}
              onInput={(e) => {
                setCopyData({ ...copyData, firstName: e.target.value });
              }}
            />
          </li>
          <li>
            <span>{t(`MYINFO.lastName`)}</span>
            <input
              type="text"
              defaultValue={copyData?.lastName || ""}
              onChange={(e) => {
                setCopyData({ ...copyData, lastName: e.target.value });
              }}
            />
          </li>
          <li>
            <span>{t(`MYINFO.company`)}</span>
            <input type="text" value={copyData?.companyName || ""} disabled />
          </li>
          <li>
            <span>{t(`MYINFO.industry`)}</span>
            <div className="selectObj">
              <p
                className="d"
                onClick={() => {
                  setOpenSelect(!openSelect);
                }}
              >
                <span>{selectTitle?.nm}</span>
              </p>
              <ul style={{ display: openSelect ? "block" : "none" }}>
                {industryList.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleSelectOnclick(data);
                    }}
                  >
                    {data?.cdName}
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li>
            <span>{t(`MYINFO.role`)}</span>
            <input
              type="text"
              defaultValue={copyData?.userRole || ""}
              onInput={(e) => {
                setCopyData({ ...copyData, userRole: e.target.value });
              }}
            />
          </li>
          <li>
            <span>{t(`MYINFO.pwd`)}</span>
            <button
              className="insBtn"
              onClick={() => {
                setPopupStatus(true);
              }}
            >
              {t(`MYINFO.chgPwd`)}
            </button>
          </li>
        </ul>
        <button
          onClick={() => {
            handleSubmit();
          }}
        >
          {t(`MYINFO.save`)}
        </button>
      </div>
      {popupStatus && (
        <PasswordResetPop
          prop={{
            popupStatus: popupStatus,
            setPopupStatus,
            userNo: myInfo.userNo,
          }}
        />
      )}

      {alertStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default AccountEdit2024;
