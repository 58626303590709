import React from "react";

function IntegrateData() {
    return (
        <>
            <div className="pageTitle">
                <button className="back"></button>
                My Bookmarked Emission Data
            </div>
            <div className="CalcuBookmark">
                <div className="cbSpBox bmDataBox">
                    <button className="delete"></button>
                    <div className="flag">
                        <button></button>
                    </div>
                    <div className="dataWrap">
                        <div className="stepData">
                            <p>Fuel</p>
                            <p>Diesel</p>
                            <p>Marine Diesel</p>
                            <p>Marine Diesel/Gas Oil (ISO 8217 Grades DMX)</p>
                        </div>
                        <div className="detailData">
                            <ul>
                                <li>
                                    <b>Emissions Factor</b> <span>3.12E-2 kgCO2e of CO2</span>
                                </li>
                                <li>
                                    <b>Scope</b> <span>1</span>
                                </li>
                                <li>
                                    <b>Region</b> <span>KOR</span>
                                </li>
                                <li>
                                    <b>Data Year</b> <span>2021</span>
                                </li>
                                <li>
                                    <b>Unit</b> <span>kg</span>
                                </li>
                                <li>
                                    <b>Source</b> <span>MOE/KOR</span>
                                </li>
                                <li>
                                    <b>GWP100</b> <span>SAR</span>
                                </li>
                                <li>
                                    <b>LCA Activity</b> <span>--</span>
                                </li>
                            </ul>
                            <div className="calcuApi">
                                <label>API Code Request</label>
                                <div className="ApiResult">
                                    <div className="code">
                                        curl --request POST/<br/>
                                        -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                        -- header 'Authorization : Bearer $API Ket/<br/>
                                        -- data (<br/>
                                        "emission factor"<br/>
                                        "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                        "source" : "MOE/KOR"<br/>
                                        <br/>
                                        "parameters"<br/>
                                        "regActivityAmt" : 100,<br/>
                                        "regActivityUnit" : "kWh"

                                    </div>
                                </div>

                            </div>
                            <div className="calcuApi">
                                <label>API Code Response</label>
                                <div className="ApiResult">
                                    <div className="code">
                                        curl --request POST/<br/>
                                        -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                        -- header 'Authorization : Bearer $API Ket/<br/>
                                        -- data (<br/>
                                        "emission factor"<br/>
                                        "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                        "source" : "MOE/KOR"<br/>
                                        <br/>
                                        "parameters"<br/>
                                        "regActivityAmt" : 100,<br/>
                                        "regActivityUnit" : "kWh"

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="verCheck">
                            <button>Check updated version</button>
                        </div>
                    </div>

                </div>

                <div className="cbSpBox bmDataBox">
                    <button className="delete"></button>
                    <div className="flag open">
                        <button></button>
                    </div>
                    <div className="dataWrap">
                        <div className="stepData">
                            <p>Fuel</p>
                            <p>Diesel</p>
                            <p>Marine Diesel</p>
                            <p>Marine Diesel/Gas Oil (ISO 8217 Grades DMX, DMA, DMZ And DMB Maximum 0.10 % S); MDO/MGO(ULSFO) Marine Diesel/Gas Oil (ISO 8217 Grades DMX, DMA, DMZ And DMB Maximum 0.10 % S); MDO/MGO(ULSFO)</p>
                        </div>
                        <div className="detailData">
                            <ul>
                                <li>
                                    <b>Emissions Factor</b> <span>3.12E-2 kgCO2e of CO2</span>
                                </li>
                                <li>
                                    <b>Scope</b> <span>1</span>
                                </li>
                                <li>
                                    <b>Region</b> <span>KOR</span>
                                </li>
                                <li>
                                    <b>Data Year</b> <span>2021</span>
                                </li>
                                <li>
                                    <b>Unit</b> <span>kg</span>
                                </li>
                                <li>
                                    <b>Source</b> <span>MOE/KOR</span>
                                </li>
                                <li>
                                    <b>GWP100</b> <span>SAR</span>
                                </li>
                                <li>
                                    <b>LCA Activity</b> <span>--</span>
                                </li>
                            </ul>
                            <div className="calcuApi">
                                <label>API Code Request</label>
                                <div className="ApiResult">
                                    <div className="code">
                                        curl --request POST/<br/>
                                        -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                        -- header 'Authorization : Bearer $API Ket/<br/>
                                        -- data (<br/>
                                        "emission factor"<br/>
                                        "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                        "source" : "MOE/KOR"<br/>
                                        <br/>
                                        "parameters"<br/>
                                        "regActivityAmt" : 100,<br/>
                                        "regActivityUnit" : "kWh"

                                    </div>
                                </div>

                            </div>
                            <div className="calcuApi">
                                <label>API Code Response</label>
                                <div className="ApiResult">
                                    <div className="code">
                                        curl --request POST/<br/>
                                        -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                        -- header 'Authorization : Bearer $API Ket/<br/>
                                        -- data (<br/>
                                        "emission factor"<br/>
                                        "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                        "source" : "MOE/KOR"<br/>
                                        <br/>
                                        "parameters"<br/>
                                        "regActivityAmt" : 100,<br/>
                                        "regActivityUnit" : "kWh"

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="verCheck">
                            <button>Check updated version</button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default IntegrateData;