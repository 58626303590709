import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DataIntegrate2024() {
  const { t } = useTranslation();
  const nav = useNavigate();
  return (
    <>
      <div className="pageTitle">{t("INTEGRATE.title")}</div>
      <div className="integrateWrap">
        <strong>{t("INTEGRATE.implService")}</strong>
        <div className="integrate">
          <div className="cbSpBox">
            <div className="calcu">
              <strong>
                {t("INTEGRATE.bookmarkCal")
                  .split("\n")
                  .map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
              </strong>
              <button
                onClick={() => {
                  nav("/myBookmarkEmissionCalculator2024");
                }}
              >
                {t("INTEGRATE.clickTooltip")}
              </button>
            </div>
          </div>
          <div className="cbSpBox">
            <div className="data">
              <strong>
                {t("INTEGRATE.bookmarkData")
                  .split("\n")
                  .map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
              </strong>
              <button
                onClick={() => {
                  nav("/myBookmarkEmissionData2024");
                }}
              >
                {t("INTEGRATE.clickTooltip")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataIntegrate2024;
