import React, { useState, useEffect, useCallback, useMemo } from "react";
import SelectBox from "../common/SelectBox2024";
import EmissionResult from "./EmissionResult2024";
import {
  getSectorAndTemplate,
  postTemplateUserProcess,
  postTemplateCalculator,
  insertTemplate,
} from "../../service/calculatorService";
import LevelComponent from "./LevelComponent2024";
import { Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../util/loadingSlice";
import { nowInsertDate } from "../../util/common";
import {
  insertAllEmissionActivityCategoryBookmark,
  updateActCatgBookmarYn,
} from "../../service/activityCategoryService";
import { alertData } from "../../util/alertData";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";

export default function Emission() {
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    sector: null,
    template: null,
    level1: null,
    level2: null,
    level3: null,
    level4: null,
    unit: null,
    totalValue: 0,
    needValMap: [],
  });
  const [comboStates, setComboStates] = useState(new Array(4).fill("none"));
  // const [bookmarkCheckList, setBookmarkCheckList] = useState();
  const [rowSector, setRowSector] = useState([{}]);
  const [sectorOption, setSectorOption] = useState([{}]); // 업종 옵션
  const [templateOption, setTemplateOption] = useState([{}]); // 템플릿 옵션

  const [templateLevelInfo, setTemplateLevelInfo] = useState([]);
  const [templateFirstLevelInfo, setTemplateFirstLevelInfo] = useState([]);
  const [templateSecondLevelInfo, setTemplateSecondLevelInfo] = useState([]);
  const [templateThirdLevelInfo, setTemplateThirdLevelInfo] = useState([]);
  const [templateFourthLevelInfo, setTemplateFourthLevelInfo] = useState([]);
  const [bookmarkControl, setBookmarkControl] = useState(false);

  const [resultData, setResultData] = useState();

  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const userInfo = useMemo(() => {
    if (token) {
      return jwt_decode(token);
    } else {
      return null;
    }
  }, [token]);

  useEffect(() => {
    // Step1 - 업종 옵션 세팅
    if (userInfo?.userNo) {
      // getSectorAndTemplate(userInfo.userNo).then((response) => {
      // 임시 : 전체 데이터 조회를 위한 마스터 계정 북마크 데이터 호출
      getSectorAndTemplate(1).then((response) => {
        if (response?.status === 200) {
          const responseSector = response.data.reduce((acc, current) => {
            const x = acc.find((item) => item.value === current.curtNo);
            if (!x) {
              return acc.concat([
                { value: current.curtNo, label: current.curtName },
              ]);
            } else {
              return acc;
            }
          }, []);

          setSectorOption(responseSector);
          setRowSector(response.data);
        }
      });

      // getCheckBookmark(userInfo.userNo).then((result) => {
      //   if (result?.status === 200) {
      //     setBookmarkCheckList(result.data);
      //   }
      // });
    } else {
      // 임시로 비로그인 차단
      navigate("/login2024");
    }
  }, [userInfo]);

  // Step2 - 업종 선택
  const handleSectorType = useCallback(
    async (input) => {
      // Step3 - 템플릿 옵션 세팅
      let templeData = [];
      rowSector.forEach((data) => {
        if (input.value === data.curtNo) {
          templeData.push({ value: data.curtCatgNo, label: data.curtCatgName });
        }
      });

      setTemplateOption(templeData);
      setTemplateLevelInfo([]);
      setTemplateFirstLevelInfo([]);
      setTemplateSecondLevelInfo([]);
      setTemplateThirdLevelInfo([]);
      setTemplateFourthLevelInfo([]);

      setFormData({
        sector: input,
        template: null,
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        unit: null,
        totalValue: 0,
        needValMap: [],
      });
      setBookmarkControl(false);
    },
    [rowSector]
  );

  // Step4 - 템플릿 선택
  const handleTemplateType = async (input) => {
    const param = {
      // userNo: userInfo.userNo,
      // userNo: null, // 전체 데이터 조회
      // 임시 : 전체 데이터 조회를 위한 마스터 계정 북마크 데이터 호출
      userNo: 1, // 전체 데이터 조회
      curtCatgNo: input.value,
      langCd: 0,
      nationId: "KOR",
    };

    // Step5 - 유저 템플릿 프로세스 조회
    dispatch(setLoading({ viewOption: true }));
    try {
      const response = await postTemplateUserProcess(param);
      if (response.status === 200) {
        dispatch(setLoading({ viewOption: false }));
        setTemplateLevelInfo(response.data);
      } else {
        dispatch(setLoading({ viewOption: false }));
        return;
      }
    } catch (error) {
      dispatch(setLoading({ viewOption: false }));
      console.error("postTemplateUserProcessError : ", error);
    }

    setTemplateFirstLevelInfo([]);
    setTemplateSecondLevelInfo([]);
    setTemplateThirdLevelInfo([]);
    setTemplateFourthLevelInfo([]);

    setFormData((prev) => ({
      ...prev,
      template: input,
      level1: null,
      level2: null,
      level3: null,
      level4: null,
      unit: null,
      totalValue: 0,
      needValMap: [],
    }));

    setBookmarkControl(false);
  };

  // Step6 - 레벨 값 세팅
  const handleLevelType = async (input, level) => {
    let parentLabel =
      level === 4
        ? templateThirdLevelInfo.name
        : level === 3
        ? templateSecondLevelInfo.name
        : level === 2
        ? templateFirstLevelInfo.name
        : null;

    if (level === 4) {
      setTemplateFourthLevelInfo([]);
    }
    if (level === 3) {
      setTemplateThirdLevelInfo([]);
      setTemplateFourthLevelInfo([]);
    }
    if (level === 2) {
      setTemplateSecondLevelInfo([]);
      setTemplateThirdLevelInfo([]);
      setTemplateFourthLevelInfo([]);
    }
    if (level === 1) {
      setTemplateFirstLevelInfo([]);
      setTemplateSecondLevelInfo([]);
      setTemplateThirdLevelInfo([]);
      setTemplateFourthLevelInfo([]);
    }

    findInputValues(templateLevelInfo, input.label, level, parentLabel);
    setFormData((prev) => {
      const newFormData = { ...prev };
      newFormData[`level${level}`] = input;
      newFormData[`unit`] = null;
      newFormData[`totalValue`] = 0;
      newFormData[`needValMap`] = [];
      for (let i = level + 1; i <= 4; i++) {
        newFormData[`level${i}`] = null;
      }
      return newFormData;
    });
  };

  // // rowData에 현재 선택 레벨 선택 정보를 재귀적으로 탐색 -> 해당 정보를 state에 저장
  function findInputValues(templateLevelInfo, label, level, parentLabel) {
    function findChild(template, labels, currentLevel) {
      if (currentLevel === level) {
        const found = template.children?.find((child) => child.name === label);
        if (found) {
          if (level === 1) setTemplateFirstLevelInfo(found);
          else if (level === 2) setTemplateSecondLevelInfo(found);
          else if (level === 3) setTemplateThirdLevelInfo(found);
          else if (level === 4) setTemplateFourthLevelInfo(found);
        }
        return;
      }

      for (let child of template.children) {
        if (currentLevel === level - 1 && child.name === parentLabel) {
          findChild(child, labels, currentLevel + 1);
          return;
        } else if (currentLevel < level - 1) {
          findChild(child, labels, currentLevel + 1);
        }
      }
    }

    setBookmarkControl(false);
    findChild(templateLevelInfo, [label, parentLabel], 1);
  }

  // 카테고리가 변경될 때 마다 가장 마지막 카테고리를 체크 : 마지막 카테고리를 선택 시 유닛 박스를 그려줘야하는 용도
  useEffect(() => {
    // 카테고리 입력 값 반환
    const getLastLevelInfo = (levels) => {
      // 'level'이 포함된 키만 필터링하여 배열로 저장
      const levelKeys = Object.keys(levels)
        .filter((key) => key.includes("level")) // 'level'이 포함된 키만 필터링
        .sort((a, b) => b.localeCompare(a)); // 내림차순으로 정렬

      for (let key of levelKeys) {
        if (levels[key]) {
          return levels[key];
        }
      }
      return null; // 모든 레벨이 null
    };

    let info = formData;
    // 레벨 카테고리 입력값 반환
    if (info.level1) {
      getLastLevelInfo(formData);
    }
  }, [formData]);

  /* handle */
  // 콤보박스 클릭 이벤트 = 클릭한 콤보박스 이외에 닫기
  const handleComboClick = useCallback(
    (index) => {
      const updatedComboStates = [...comboStates];

      if (updatedComboStates[index] === "none") {
        updatedComboStates[index] = "block";
      } else {
        updatedComboStates[index] = "none";
      }

      for (let i = 0; i < updatedComboStates.length; i++) {
        if (i !== index && updatedComboStates[i]) {
          updatedComboStates[i] = "none";
        }
      }
      setComboStates(updatedComboStates);
    },
    [comboStates]
  );

  const handleUnitType = (input) => {
    setBookmarkControl(true);
    setFormData((prev) => ({
      ...prev,
      unit: input,
      totalValue: null,
    }));
  };

  const handleOnChangeValue = (input) => {
    setFormData((prev) => ({
      ...prev,
      totalValue: input,
    }));
  };

  const handleOnChangeNeedValMap = (name, value, index) => {
    let valArray = refineVarArray();
    valArray[name] = value;
    setFormData((prev) => ({ ...prev, needValMap: valArray }));
  };

  function refineVarArray() {
    let valArray = structuredClone(formData.needValMap);
    let selectData = [
      templateFirstLevelInfo,
      templateSecondLevelInfo,
      templateThirdLevelInfo,
      templateFourthLevelInfo,
    ];

    if (valArray.length === 0) {
      for (let i = selectData.length - 1; i >= 0; i--) {
        if (selectData[i].length !== 0) {
          if (
            selectData[i].needVarMap &&
            selectData[i].needVarMap.length !== 0
          ) {
            if (selectData[i].catgItemNo) {
              selectData[i].needVarMap[selectData[i].catgItemNo].forEach(
                (data) => {
                  valArray = {
                    ...valArray,
                    [data.name]: data.value,
                  };
                }
              );
            }
          }
        }
      }

      for (let i = selectData.length - 1; i >= 0; i--) {
        if (selectData[i].length !== 0) {
          if (
            selectData[i].needVarMap &&
            selectData[i].needVarMap.length !== 0
          ) {
            if (selectData[i + 1].catgItemNo) {
              selectData[i].needVarMap[selectData[i + 1].catgItemNo].forEach(
                (data) => {
                  valArray = {
                    ...valArray,
                    [data.name]: data.value,
                  };
                }
              );
            }
          }
        }
      }
    }

    return valArray;
  }

  // button onClick
  const onClickCalculator = () => {
    if (templateLevelInfo.length === 0) {
      return;
    }

    // 계산 param 정제
    // 선택값 -> 로우 데이터 재귀 셋팅
    let refineValue = [];

    function findCommonItems(templateInfo, formData, level, path = []) {
      const formDataValue = formData[`level${level}`]?.value;

      for (let i = 0; i < templateInfo.children.length; i++) {
        const child = templateInfo.children[i];

        const newPath = [...path, child.name];
        if (child.name === formDataValue) {
          refineValue[`level${level}`] = child;

          if (child.children) {
            findCommonItems(child, formData, level + 1, newPath);
          }
          // 선택 요소만 순회하도록 루프 종료
          break;
        }
      }

      return {
        ...refineValue,
        sector: formData?.sector ? formData.sector.value : null,
        template: formData?.template ? formData.template.value : null,
        unit: formData?.unit ? formData.unit.value : null,
        totalValue: formData?.totalValue ? formData.totalValue : null,
      };
    }

    calculatorProcessing(findCommonItems(templateLevelInfo, formData, 1));
  };

  // 계산
  const calculatorProcessing = async (refineValue) => {
    // 임시 예외
    if (!refineValue.level1) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.allCheckValue",
      }));
      setPopupStatus(true);

      return;
    }

    let [userFactId, userCurtItemId, vars] = refineParamsId(refineValue);
    let refineValMap;

    if (Object.keys(formData.needValMap).length === 0) {
    }

    if (vars) {
      refineValMap = vars;
    } else {
      refineValMap =
        Object.keys(formData.needValMap).length !== 0
          ? formData.needValMap
          : Object.keys(refineVarArray()).length !== 0
          ? refineVarArray()
          : null;
    }

    if (refineValMap) {
      const keys = Object.keys(refineValMap);

      for (let key of keys) {
        if (refineValMap[key] === "") {
          refineValMap[key] = 0;
        }

        // refineValMap이 String으로 들어가는 경우가 있음.
        refineValMap[key] = Number(refineValMap[key]);
      }
    }
    const param = {
      userFactId: userFactId,
      userCurtItemId: userCurtItemId,
      unit: refineValue.unit,
      nationId: "KOR",
      yyyy: "2024",
      useVal: refineValue.totalValue,
      // useVal: 1,
      vars: refineValMap,
    };

    console.log("param : ", param);

    // 임시 예외
    if (!param.userCurtItemId || !param.useVal || !param.unit) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.allCheckValue",
      }));
      setPopupStatus(true);

      return;
    }

    try {
      const response = await postTemplateCalculator(param);
      // 임시 예외
      if (response.status === 200) {
        if (response.data === undefined) {
          setPopupData((prevData) => ({
            ...prevData,
            popupStatus: true,
            content: "ALERT.noDataCal",
          }));
          setPopupStatus(true);
          return;
        }
        setResultData(response.data);
      }
    } catch (error) {
      console.error("postTemplateCalculator error : ", error);
    }
  };

  const refineParamsId = (refineValue) => {
    let index = 0;
    if (refineValue.level4) {
      index = 4;
    } else if (refineValue.level3) {
      index = 3;
    } else if (refineValue.level2) {
      index = 2;
    } else if (refineValue.level1) {
      index = 1;
    }

    // 자식 요소가 있을 때
    if (refineValue[`level${index}`]?.children) {
      // 레벨의 자식 요소의 타입이 FactId라면 자기자신의 isFactId = true를 체크한다.
      if (refineValue[`level${index}`]?.children[0]?.type === "FactId") {
        return [
          refineValue[`level${index}`].isFactId
            ? refineValue[`level${index}`].userFactId
            : null,
          refineValue[`level${index}`].userCurtItemId,
          null,
        ];
      }
    }

    // 자식 요소가 없을 때
    else if (!refineValue[`level${index}`]?.children) {
      // 자기 자신이 varName를 포함한다면
      if (refineValue[`level${index}`]?.varName) {
        let userCurtItemId;
        let varName = refineValue[`level${index}`].varName;
        let varValue = refineValue[`level${index}`].varValue;

        // 부모요소의 FactId와 상위레벨을 체크하면서 가장 근접한 userCurtItemId 사용
        for (let i = index; i >= 1; i--) {
          if (index === 4) {
            if (refineValue[`level${i}`]?.userCurtItemId) {
              userCurtItemId = refineValue[`level${i}`]?.userCurtItemId;
            }
          }
        }

        return [
          refineValue[`level${index}`].userFactId
            ? refineValue[`level${index}`].userFactId
            : refineValue[`level${index - 1}`].userFactId,
          userCurtItemId === undefined || null
            ? refineValue[`level${index - 1}`].userCurtItemId
            : userCurtItemId,
          { [varName]: varValue },
        ];
      }
      // varName을 포함하지 않고 자신 레벨에 userCurtItemId가 없다면,
      else if (
        !refineValue[`level${index}`]?.varName &&
        !refineValue[`level${index}`].userCurtItemId
      ) {
        return [
          refineValue[`level${index}`].userFactId,
          refineValue[`level${index - 1}`].userCurtItemId,
          null,
        ];
      }
      // 자신 레벨에 isFactId, userCurtItemId, isFactId가 모두 있을 경우
      else if (
        refineValue[`level${index}`].isFactId &&
        refineValue[`level${index}`].userCurtItemId &&
        refineValue[`level${index}`].isFactId
      ) {
        return [
          refineValue[`level${index}`].userFactId,
          refineValue[`level${index}`].userCurtItemId,
          null,
        ];
      }
    }
    return [null, null, null];
  };

  function handleOnClickResetBtn() {
    setFormData({
      sector: null,
      template: null,
      level1: null,
      level2: null,
      level3: null,
      level4: null,
      unit: null,
      totalValue: 0,
      needValMap: [],
    });

    setTemplateOption([{}]);

    setTemplateLevelInfo([]);
    setTemplateFirstLevelInfo([]);
    setTemplateSecondLevelInfo([]);
    setTemplateThirdLevelInfo([]);
    setTemplateFourthLevelInfo([]);
    setResultData();
    setBookmarkControl(false);
  }

  const handleBookmarkClick = async () => {
    /* 북마크 프로세스
      1. 템플릿 / 배출계수 북마크
      2. 전체 FCA_CF_ACT_CATG 데이터 추가
      3. 선택 FCA_CF_ACT_CATG  데이터 업데이트
      사업부 요청 사항으로 인해 기존 단계별로 나뉘어져 있던 프로세스들이 한 로직에서 실행이 되어야 함
    */
    if (bookmarkControl) {
      dispatch(setLoading({ viewOption: true }));
      const param = {
        userNo: userInfo.userNo,
        curtNo: formData.sector.value,
        curtCatgNo: formData.template.value,
        regDtm: nowInsertDate(),
        regUserNo: userInfo.userNo,
        chgDtm: nowInsertDate(),
        chgUserNo: userInfo.userNo,
      };

      /* 템플릿 북마크 / 선택계수 북마크 */
      const response = await insertTemplate(param);
      if (response.status === 200) {
        const param2 = {
          userNo: userInfo.userNo,
          curtCatgNo: formData.template.value,
          nationId: "KOR",
          langCd: null,
          // langCd: "ko",
        };

        /* 전체 FCA_CF_ACT_CATG 데이터 추가 */
        const response2 = await insertAllEmissionActivityCategoryBookmark(
          param2
        );
        if (response2.status === 200) {
          let param3 = {
            userNo: userInfo.userNo,
            curtCatgNo: formData.template.value,
            actCatg1: formData.level1?.value,
            actCatg2: formData.level2?.value,
            actCatg3: formData.level3?.value,
            actCatg4: formData.level4?.value,
            bookmarkYn: "Y",
          };

          /* 선택 FCA_CF_ACT_CATG  데이터 업데이트 */
          const response3 = await updateActCatgBookmarYn(param3);
          if (response3.status === 200) {
            setPopupData((prevData) => ({
              ...prevData,
              popupStatus: true,
              content: "ALERT.complateBookmark",
            }));
            setPopupStatus(true);
            dispatch(setLoading({ viewOption: false }));
          } else {
            dispatch(setLoading({ viewOption: false }));
          }
        } else {
          dispatch(setLoading({ viewOption: false }));
        }
      } else {
        dispatch(setLoading({ viewOption: false }));
      }
    }
  };

  return (
    <>
      <div className="pageTitle">{t(`EXPLORERCAL.emissionCal`)}</div>
      <Grid container className="calcuExpGrid" spacing={2.5}>
        <Grid item xs={7} className="calcInput">
          <div className="cbSpBox">
            <div className="inputDataWrap">
              <div className="inputData">
                <div className="inputObj">
                  <label>업종</label>
                  <SelectBox
                    value={formData.sector}
                    options={sectorOption}
                    isOpen={comboStates[0]}
                    onClick={() => handleComboClick(0)}
                    onChange={handleSectorType}
                  />
                </div>

                {formData.sector && (
                  <div className="inputObj">
                    <label>배출활동</label>
                    <SelectBox
                      value={formData.template}
                      options={templateOption}
                      isOpen={comboStates[1]}
                      onClick={() => handleComboClick(1)}
                      onChange={handleTemplateType}
                    />
                  </div>
                )}

                {templateLevelInfo &&
                  templateLevelInfo?.children?.length > 0 && (
                    <LevelComponent
                      levelInfo={templateLevelInfo}
                      formData={formData}
                      level={1}
                      comboState={comboStates[2]}
                      handleComboClick={handleComboClick}
                      handleLevelType={handleLevelType}
                      handleUnitType={handleUnitType}
                      handleOnChangeValue={handleOnChangeValue}
                      activityLevelText={templateLevelInfo.activityLevelText}
                      selectData={[
                        templateFirstLevelInfo,
                        templateSecondLevelInfo,
                        templateThirdLevelInfo,
                        templateFourthLevelInfo,
                      ]}
                      handleOnChangeNeedValMap={handleOnChangeNeedValMap}
                    />
                  )}

                {templateLevelInfo &&
                  templateLevelInfo?.children?.length > 0 && (
                    <LevelComponent
                      levelInfo={templateFirstLevelInfo}
                      formData={formData}
                      level={2}
                      comboState={comboStates[3]}
                      handleComboClick={handleComboClick}
                      handleLevelType={handleLevelType}
                      handleUnitType={handleUnitType}
                      handleOnChangeValue={handleOnChangeValue}
                      parent={templateLevelInfo}
                      activityLevelText={templateLevelInfo.activityLevelText}
                      selectData={[
                        templateFirstLevelInfo,
                        templateSecondLevelInfo,
                        templateThirdLevelInfo,
                        templateFourthLevelInfo,
                      ]}
                      handleOnChangeNeedValMap={handleOnChangeNeedValMap}
                    />
                  )}

                {templateLevelInfo &&
                  templateLevelInfo?.children?.length > 0 && (
                    <LevelComponent
                      levelInfo={templateSecondLevelInfo}
                      formData={formData}
                      level={3}
                      comboState={comboStates[4]}
                      handleComboClick={handleComboClick}
                      handleLevelType={handleLevelType}
                      handleUnitType={handleUnitType}
                      handleOnChangeValue={handleOnChangeValue}
                      parent={templateFirstLevelInfo}
                      activityLevelText={templateLevelInfo.activityLevelText}
                      selectData={[
                        templateFirstLevelInfo,
                        templateSecondLevelInfo,
                        templateThirdLevelInfo,
                        templateFourthLevelInfo,
                      ]}
                      handleOnChangeNeedValMap={handleOnChangeNeedValMap}
                    />
                  )}

                {templateLevelInfo &&
                  templateLevelInfo?.children?.length > 0 && (
                    <LevelComponent
                      levelInfo={templateThirdLevelInfo}
                      formData={formData}
                      level={4}
                      comboState={comboStates[5]}
                      handleComboClick={handleComboClick}
                      handleLevelType={handleLevelType}
                      handleUnitType={handleUnitType}
                      handleOnChangeValue={handleOnChangeValue}
                      parent={templateSecondLevelInfo}
                      activityLevelText={templateLevelInfo.activityLevelText}
                      selectData={[
                        templateFirstLevelInfo,
                        templateSecondLevelInfo,
                        templateThirdLevelInfo,
                        templateFourthLevelInfo,
                      ]}
                      handleOnChangeNeedValMap={handleOnChangeNeedValMap}
                    />
                  )}

                {templateLevelInfo &&
                  templateLevelInfo?.children?.length > 0 && (
                    <LevelComponent
                      levelInfo={templateFourthLevelInfo}
                      formData={formData}
                      level={5}
                      comboState={comboStates[6]}
                      handleComboClick={handleComboClick}
                      handleLevelType={handleLevelType}
                      handleUnitType={handleUnitType}
                      handleOnChangeValue={handleOnChangeValue}
                      parent={templateThirdLevelInfo}
                      activityLevelText={templateLevelInfo.activityLevelText}
                      selectData={[
                        templateFirstLevelInfo,
                        templateSecondLevelInfo,
                        templateThirdLevelInfo,
                        templateFourthLevelInfo,
                      ]}
                      handleOnChangeNeedValMap={handleOnChangeNeedValMap}
                    />
                  )}

                {templateLevelInfo?.children?.length < 1 && (
                  <div className="inputObj">데이터가 없습니다.</div>
                )}
              </div>
              <div className="inputBtn">
                <div className="half">
                  <button className="reset" onClick={handleOnClickResetBtn}>
                    <span>{t(`EXPLORERCAL.reset`)}</span>
                  </button>
                  <button
                    className={bookmarkControl ? "bookmark" : "bookmarkNone"}
                    onClick={handleBookmarkClick}
                  >
                    <span>{t(`EXPLORERCAL.bookmark`)}</span>
                  </button>
                </div>

                <button onClick={onClickCalculator}>
                  <span>{t(`EXPLORERCAL.calculate`)}</span>
                </button>
              </div>
            </div>
          </div>
        </Grid>
        <EmissionResult resultData={resultData} userInfo={userInfo} />
      </Grid>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}
