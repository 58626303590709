/* LogoImg */
import BEIS from "../../images/Source/BEIS.png";
import Canada from "../../images/Source/canada.png";
import Climate from "../../images/Source/climate.png";
import DISER from "../../images/Source/DISER.png";
import Enerymarket from "../../images/Source/energymarket.png";
import EPA from "../../images/Source/EPA.png";
import European from "../../images/Source/european.png";
import ExioBase from "../../images/Source/ExioBase.png";
import GLEC from "../../images/Source/GLEC.png";
import Inecc from "../../images/Source/inecc.png";
import Instituto from "../../images/Source/instituto.png";
import IPCC from "../../images/Source/IPCC.png";
import KEA from "../../images/Source/KEA.png";
import Keiti from "../../images/Source/keiti.png";
import KoreaDistrict from "../../images/Source/koreaDistrict.png";
import MCTI from "../../images/Source/MCTI.png";
import MFE from "../../images/Source/MFE.png";
import Ministry from "../../images/Source/ministry.png";
import JAP from "../../images/Source/MOE(JAP).png";
import KOR from "../../images/Source/MOE(KOR).png";
import Peru from "../../images/Source/peru.png";
import TGO from "../../images/Source/TGO.png";
import WorldBank from "../../images/Source/The_World_Bank.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";

export default function HomeSwiperLogo2024() {
  const { t } = useTranslation();

  return (
    <div className="cbSpBox swiperLogo">
      <strong>{t("HOME.based")}</strong>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={60}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <img src={BEIS} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Canada} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Climate} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={DISER} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Enerymarket} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={EPA} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={European} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ExioBase} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={GLEC} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Inecc} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Instituto} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={IPCC} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={KEA} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Keiti} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={KoreaDistrict} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MCTI} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MFE} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Ministry} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={JAP} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={KOR} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Peru} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={TGO} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={WorldBank} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
