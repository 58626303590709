import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  getActivityChildLevel,
  getDataExplorerList,
  getFirstActivityChildLevel,
  getFirstActivityLevel,
} from "../../service/dataExplorerService";
import DataExplorerList from "./BeforeDataExplorerList2024";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DataExplorer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // select view control
  const [selectView, setSelectView] = useState([false, false, false, false]);

  // selected title
  const [selectTilte, setSelectTilte] = useState([null, null, null, null]);

  // activity level
  const [levelInfo, setLevelInfo] = useState([]);

  // select data
  const [selectedData, setSelectData] = useState({
    selectedData1: null,
    selectedData2: null,
    selectedData3: null,
    selectedData4: null,
  });

  // 배출계수 조회 데이터 리스트
  const [factorDataList, setFactorDataList] = useState(null);
  const userJSON = localStorage.getItem("user");
  let userInfo;
  if (!userJSON) {
    userInfo = null;
  } else {
    userInfo = jwt_decode(JSON.parse(userJSON)?.accessToken);
  }

  // 초기 level1 세팅
  useEffect(() => {
    if (userInfo?.userNo) {
      firstLevel1();
    } else {
      navigate("/login2024");
    }
  }, []);

  const firstLevel1 = async () => {
    try {
      const response = await getFirstActivityLevel();
      if (response.status === 200) {
        setLevelInfo((prev) => [
          ...prev,
          {
            Level1: response.data,
          },
        ]);
      }
    } catch (error) {
      console.error("getFirstActivityLevel Error:", error);
    }
  };

  // Level 1이 변경될 때 마다, level1 child 조회
  useEffect(() => {
    if (selectedData.selectedData1) {
      // 정보 초기화
      initData(1);
      //   getFactorDataList(selectedData.selectedData1);
      getFirstChildLevel(selectedData.selectedData1);
    }
  }, [selectedData.selectedData1]);

  const getFirstChildLevel = async (selectedLevel1) => {
    try {
      const response = await getFirstActivityChildLevel(
        selectedLevel1.factCatgId
      );
      if (response.status === 200 && response.data.length > 0) {
        setLevelInfo((prev) => [
          ...prev,
          {
            Level2: response.data,
          },
        ]);
      }
    } catch (error) {
      console.error("getFirstChild Error:", error);
    }
  };

  // Level2 변경 시 배출계수 리스트를 조회 하거나 / next level을 동시에 조회한다.
  useEffect(() => {
    if (selectedData.selectedData2) {
      initData(2);
      getFactorDataList(selectedData.selectedData2);
      getChildeLevel(selectedData.selectedData2, 3);
    }
  }, [selectedData.selectedData2]);

  // Level3 변경 시 ...
  useEffect(() => {
    if (selectedData.selectedData3) {
      initData(3);
      getFactorDataList(selectedData.selectedData3);
      getChildeLevel(selectedData.selectedData3, 4);
    }
  }, [selectedData.selectedData3]);

  // Level4 변경 시 ...
  useEffect(() => {
    if (selectedData.selectedData4) {
      initData(4);
      getFactorDataList(selectedData.selectedData4);
      getChildeLevel(selectedData.selectedData4, 5);
    }
  }, [selectedData.selectedData4]);

  // get chlid
  const getChildeLevel = async (selectedLevel, levelIndex) => {
    try {
      const response = await getActivityChildLevel(selectedLevel.factItemId);
      if (response.status === 200 && response.data.length > 0) {
        setLevelInfo((prev) => [
          ...prev,
          {
            [`Level${levelIndex}`]: response.data,
          },
        ]);
      }
    } catch (error) {
      console.error("getChildeLevel Error:", error);
    }
  };

  const getFactorDataList = async (inData) => {
    try {
      const response = await getDataExplorerList(inData.factItemId);
      if (response.status === 200) {
        setFactorDataList(response.data);
      }
    } catch (error) {
      console.error("getFactorDataList Error:", error);
    }
  };

  // 초기화 함수
  const initData = (level) => {
    initLevel(); // 레벨 정보 초기화
    initSelected(); // 선택한 정보 초기화
    initTitle(); // 선택한 셀렉트 타이틀 초기화
    setFactorDataList(null);

    function initLevel() {
      let initLevelData;
      initLevelData = levelInfo.filter((item) => {
        for (let i = 1; i <= level; i++) {
          if (item[`Level${i}`] !== undefined) {
            return true;
          }
        }
        return false;
      });
      setLevelInfo(initLevelData);
    }

    function initSelected() {
      let initSelectedData;
      initSelectedData = selectedData;
      for (let i = 1; i <= 4; i++) {
        if (i > level) {
          initSelectedData[`selectedData${i}`] = null;
        }
      }
      setSelectData(initSelectedData);
    }

    function initTitle() {
      let initTitleData;
      initTitleData = selectTilte;
      for (let i = 0; i < 4; i++) {
        if (i > level - 1) {
          initTitleData[i] = null;
        }
      }
      setSelectTilte(initTitleData);
    }
  };
  // ------------------------------------------------------------------------------------------------------------------------
  // 셀렉트 박스 클릭 시
  const selectOnClcik = (selectIndex) => {
    let copySelectViewControl = [...selectView];
    copySelectViewControl[selectIndex] = !copySelectViewControl[selectIndex];

    // 자기 자신 외 모두 닫기
    for (let i = 0; i < 4; i++) {
      if (i !== selectIndex) {
        copySelectViewControl[i] = false;
      }
    }
    setSelectView(copySelectViewControl);
  };

  // 셀렉트 옵션 클릭 시
  const optionOnClick = (optionData, ontionIndex, selectIndex) => {
    titleSetting();
    selectedDataSetting();

    function selectedDataSetting() {
      // 데이터 셋팅
      let copySelectedData = selectedData;
      copySelectedData[`selectedData${selectIndex + 1}`] = optionData;
      setSelectData(copySelectedData);

      // 옵션 컨트롤
      let copySelectViewControl = [...selectView];
      copySelectViewControl[selectIndex] = false;
      setSelectView(copySelectViewControl);
    }

    // 셀렉트 박스 선택 시 보여지는 타이틀 세팅
    function titleSetting() {
      let copySelectTitle = [...selectTilte];
      if (selectIndex === 0) {
        copySelectTitle[selectIndex] =
          optionData.factCatgName + "(" + optionData.cbScopeId + ")";
      } else if (selectIndex > 0) {
        copySelectTitle[selectIndex] = optionData.factItemName;
      }

      setSelectTilte(copySelectTitle);
    }
  };

  return (
    <>
      <div className="pageTitle">{t(`EXPLORERDATA.dataExplorer`)}</div>
      <div className="cbSpBox innerScroll">
        <div className="dataExpInput">
          {/* */}
          {levelInfo && (
            <>
              {levelInfo?.map((data, selectIndex) => (
                <div className="inputObj" key={selectIndex}>
                  <div>
                    {selectTilte && (
                      <p
                        onClick={() => {
                          selectOnClcik(selectIndex);
                        }}
                      >
                        {selectTilte[selectIndex] ? (
                          <b className="ellipsis">{selectTilte[selectIndex]}</b>
                        ) : (
                          <b className="ellipsis placeholder">
                            Select Lv{selectIndex + 1}
                          </b>
                        )}

                        <button>열림</button>
                      </p>
                    )}

                    <ul
                      style={{
                        display: selectView[selectIndex] ? "block" : "none",
                      }}
                    >
                      {data[`${"Level" + (selectIndex + 1)}`]?.map(
                        (optionData, ontionIndex) => (
                          <li
                            key={ontionIndex}
                            onClick={() => {
                              optionOnClick(
                                optionData,
                                ontionIndex,
                                selectIndex
                              );
                            }}
                          >
                            {selectIndex === 0
                              ? optionData.factCatgName +
                                "(" +
                                optionData.cbScopeId +
                                ")"
                              : optionData.factItemName}
                            {}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <DataExplorerList
          dataList={factorDataList}
          selectedData={selectedData}
          userInfo={userInfo}
        />
      </div>
    </>
  );
}

export default DataExplorer;
