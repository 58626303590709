import React from "react";
import { useTranslation } from "react-i18next";

import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";

import iso01 from "../../images/iso01.svg";
import iso02 from "../../images/iso02.svg";
import iso03 from "../../images/iso03.svg";
import iso04 from "../../images/iso04.svg";
import HomeAreaChart2024 from "./HomeAreaChart2024";
import HomeSwiperLogo2024 from "./HomeSwiperLogo2024";
import HomePieAndBarChart2024 from "./HomePieAndBarChart2024";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

function Home() {
  const { t } = useTranslation();
  HighchartsMap(Highcharts);

  return (
    <>
      <div className="welcomMsg">
        <b>{t("HOME.welcome")}</b>
        <strong>
          {t("HOME.welcomeH2")
            .split("\n")
            .map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        </strong>
      </div>
      <div className="homeWrap">
        <dl className="solution">
          <dt>{t("HOME.ourSolution")}</dt>
          <dd>
            <div className="cbSpBox">
              <div>
                {t(`HOME.organizations`)}&nbsp;
                <b>{t(`HOME.organizations2`)}</b>
              </div>
            </div>
            <div className="cbSpBox">
              <div>
                {t(`HOME.companies`)}&nbsp;
                <b>{t(`HOME.companies2`)}</b>
              </div>
            </div>
            <div className="cbSpBox">
              <div>
                {t(`HOME.companiesNeed`)}&nbsp;<b>{t(`HOME.companiesNeed2`)}</b>
              </div>
            </div>
          </dd>
        </dl>
        <dl className="calculator">
          <dt>
            {t("HOME.implWord")
              .split("\n")
              .map((line, index) => (
                <div key={index}>{line}</div>
              ))}
          </dt>
          <dd>
            <div className="cbSpBox">
              <dl>
                <dt>
                  <em className="general"></em>
                  {t(`HOME.general`)}
                </dt>
                <dd>
                  {t(`HOME.buildsUpon`)}
                  <ul>
                    <li>IPCC Guidelines</li>
                    <li>GHG Protocol</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="cbSpBox">
              <dl>
                <dt>
                  <em className="manufac"></em>
                  {t(`HOME.manufacturing`)}
                </dt>
                <dd>
                  {t(`HOME.buildsUpon`)}
                  <ul>
                    <li>World Steel Association reporting framework</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="cbSpBox">
              <dl>
                <dt>
                  <em className="logist"></em>
                  {t(`HOME.logistics`)}
                </dt>
                <dd>
                  {t(`HOME.buildsUpon`)}
                  <ul>
                    <li>IGLEC standards</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="cbSpBox">
              <dl>
                <dt>
                  <em className="hospital"></em>
                  {t(`HOME.hospitality`)}
                </dt>
                <dd>
                  {t(`HOME.buildsUpon`)}
                  <ul>
                    <li>GSTC and HCMI standards</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="cbSpBox">
              <dl>
                <dt>
                  <em className="ship"></em>
                  {t(`HOME.shipbuilding`)}
                </dt>
                <dd>
                  {t(`HOME.buildsUpon`)}
                  <ul>
                    <li>ISO 14064</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </dd>
        </dl>
        <dl className="overview">
          <dt>
            {t("HOME.accounting")
              .split("\n")
              .map((line, index) => (
                <div key={index}>{line}</div>
              ))}
          </dt>
          <dd>
            <div className="chartGrid">
              <HomePieAndBarChart2024 Highcharts={Highcharts} />
              <HomeAreaChart2024 Highcharts={Highcharts} />
            </div>
            <HomeSwiperLogo2024 />

            <div className="cOverviewGrid">
              <div className="cbSpBox">
                <b>{t(`HOME.customizable`)}</b>
                {t(`HOME.customizable2`)}
              </div>
              <div className="cbSpBox">
                <b>{t(`HOME.harmonization`)}</b>
                {t("HOME.harmonization2")
                  .split("\n")
                  .map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
              </div>
              <div className="cbSpBox">
                <b>{t(`HOME.easeOf`)}</b>
                {t(`HOME.easeOf2`)}
              </div>
              <div className="cbSpBox">
                <b>{t(`HOME.readiness`)}</b>
                {t(`HOME.readiness2`)}
              </div>
            </div>
          </dd>
        </dl>
        <dl className="iso">
          <dt>{t(`HOME.madeBy`)}</dt>
          <dd>
            <div className="cbSpBox">
              <div>
                <img src={iso01} alt="" />
              </div>
              <div>
                <img src={iso02} alt="" />
              </div>
              <div>
                <img src={iso03} alt="" />
              </div>
              <div>
                <img src={iso04} alt="" />
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </>
  );
}

export default Home;
