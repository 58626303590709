import React from "react";
import Terms2024KOR from "./Terms2024KOR";
import Terms2024ENG from "./Terms2024ENG";

export default function Terms2024() {
  return (
    <>
        <div className="pageTitle">Terms of Use</div>
        <div className="cbSpBox innerScroll">
            <div className="policyContWrap">
                <Terms2024KOR />
                <Terms2024ENG />
            </div>
        </div>
    </>
  );
}
