import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { getUserCalTemplate } from "../../service/calculatorService";
import MyBookmarkCalApi2024 from "./MyBookmarkCalApi2024";
import { updateActCatgBookmarYn } from "../../service/activityCategoryService";
import { alertData } from "../../util/alertData";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";
import { getMyInfo } from "../../service/userRequestService";

function MyBookmarkEmissionCal2024() {
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  const token = localStorage.getItem("user");
  const userInfo = token ? jwt_decode(token) : null;
  const nav = useNavigate();
  const [dataList, setDataList] = useState(null);
  const [myInfo, setMyInfo] = useState(null);

  useEffect(() => {
    if (userInfo?.userNo) {
      getDataList(userInfo.userNo);
      searchMyInfo();
    } else {
      nav("/login2024");
    }
  }, []);

  const searchMyInfo = async () => {
    try {
      const response = await getMyInfo(userInfo.userNo);
      if (response.status === 200) {
        setMyInfo(response.data);
      }
    } catch (error) {
      console.error("getMyInfo Error:", error);
    }
  };

  const getDataList = async (userNo) => {
    const response = await getUserCalTemplate(userNo);
    if (response.status === 200 && response.data.length > 0) {
      const formatUnits = (units) =>
        units
          .replace(/[\[\]]/g, "")
          .split(", ")
          .map((unit) => ({ value: unit, label: unit }));

      // 속성값이 Json String이라면 Json 형태로 변환해야함.
      const parseCategory = (category) =>
        category?.includes('":{"') ? JSON.parse(category) : category;

      // Json 타입인지 체크 여부
      const isJsonType = (category) => category?.includes('":{"');
      const formattedData = response.data.map((data) => ({
        ...data,
        units: formatUnits(data.units),
        opneSelectUnitOptions: false,
        selectUnit: "Please select a unit",
        actJsonType1: isJsonType(data.actCatg1),
        actJsonType2: isJsonType(data.actCatg2),
        actJsonType3: isJsonType(data.actCatg3),
        actJsonType4: isJsonType(data.actCatg4),
        actJsonType5: isJsonType(data.actCatg5),
        actJsonType6: isJsonType(data.actCatg6),
        actCatg1: parseCategory(data.actCatg1),
        actCatg2: parseCategory(data.actCatg2),
        actCatg3: parseCategory(data.actCatg3),
        actCatg4: parseCategory(data.actCatg4),
        actCatg5: parseCategory(data.actCatg5),
        actCatg6: parseCategory(data.actCatg6),
      }));

      setDataList(formattedData);
    }
  };

  const handleDelete = async (data, index) => {
    let param = {
      userNo: userInfo.userNo,
      curtCatgNo: data.curtCatgNo,
      actCatg1: data.actCatg1
        ? typeof data.actCatg1 === "object"
          ? Object.keys(data.actCatg1)[0]
          : data.actCatg1
        : null,
      actCatg2: data.actCatg2
        ? typeof data.actCatg2 === "object"
          ? Object.keys(data.actCatg2)[0]
          : data.actCatg2
        : null,
      actCatg3: data.actCatg3
        ? typeof data.actCatg3 === "object"
          ? Object.keys(data.actCatg3)[0]
          : data.actCatg3
        : null,
      actCatg4: data.actCatg4
        ? typeof data.actCatg4 === "object"
          ? Object.keys(data.actCatg4)[0]
          : data.actCatg4
        : null,
      bookmarkYn: "N",
    };

    const response = await updateActCatgBookmarYn(param);
    if (response.status === 200) {
      setPopupData((prevData) => ({
        ...prevData,
        popupStatus: true,
        content: "ALERT.deleteAlert",
      }));
      setPopupStatus(true);

      let cpDataList = structuredClone(dataList);
      cpDataList.splice(index, 1); // 삭제 값 제거
      setDataList(cpDataList);
    }
  };
  return (
    <>
      <div className="pageTitle">
        <button
          className="back"
          onClick={() => {
            nav("/dataIntegrate2024");
          }}
        ></button>
        My Bookmarked Emission Calculator
      </div>

      {dataList && (
        <div className="CalcuBookmark">
          <div className="CalcuWrap">
            <div className="calcuList">
              {/* */}
              {dataList.map((data, index) => (
                <div className="calcuBox" key={index}>
                  <div className="calcuCont">
                    <div className="calcuTop">
                      <button
                        className="delete"
                        onClick={() => {
                          handleDelete(data, index);
                        }}
                      />
                    </div>
                    <div className="calcuBtm">
                      <dl>
                        <dt>업종</dt>
                        <dd>{data.curtName}</dd>
                        <dt>배출 활동</dt>
                        <dd>{data.curtCatgName}</dd>

                        {[
                          // type = 타이틀
                          // catg = 데이터 값
                          { type: data.actCatgType1, catg: data.actCatg1 },
                          { type: data.actCatgType2, catg: data.actCatg2 },
                          { type: data.actCatgType3, catg: data.actCatg3 },
                          { type: data.actCatgType4, catg: data.actCatg4 },
                          { type: data.actCatgType5, catg: data.actCatg5 },
                          { type: data.actCatgType6, catg: data.actCatg6 },
                        ].map(
                          (item, idx) =>
                            item.type && (
                              <React.Fragment key={idx}>
                                <dt>{item.type}</dt>
                                <dd>
                                  {/* json일 때 키(속성) 값을 보여줘야함. */}
                                  {typeof item.catg === "object"
                                    ? Object.keys(item.catg)[0]
                                    : item.catg}
                                </dd>
                              </React.Fragment>
                            )
                        )}
                        <>
                          {/* {data.actCatgType1 && (
                          <>
                            <dt>{data.actCatgType}</dt>
                            <dd>
                              {typeof data.actCatg1 === "object"
                                ? Object.keys(data.actCatg1)[0]
                                : data.actCatg1}
                            </dd>
                          </>
                        )}

                        {data.actCatgType2 && (
                          <>
                            <dt>{data.actCatgType2}</dt>
                            <dd>
                              {typeof data.actCatg2 === "object"
                                ? Object.keys(data.actCatg2)[0]
                                : data.actCatg2}
                            </dd>
                          </>
                        )}

                        {data.actCatgType3 && (
                          <>
                            <dt>{data.actCatgType3}</dt>
                            <dd>
                              {typeof data.actCatg3 === "object"
                                ? Object.keys(data.actCatg3)[0]
                                : data.actCatg3}
                            </dd>
                          </>
                        )}

                        {data.actCatgType4 && (
                          <>
                            <dt>{data.actCatgType4}</dt>
                            <dd>
                              {typeof data.actCatg4 === "object"
                                ? Object.keys(data.actCatg4)[0]
                                : data.actCatg4}
                            </dd>
                          </>
                        )}

                        {data.actCatgType5 && (
                          <>
                            <dt>{data.actCatgType5}</dt>
                            <dd>
                              {typeof data.actCatg5 === "object"
                                ? Object.keys(data.actCatg5)[0]
                                : data.actCatg5}
                            </dd>
                          </>
                        )}

                        {data.actCatgType6 && (
                          <>
                            <dt>{data.actCatgType6}</dt>
                            <dd>
                              {typeof data.actCatg6 === "object"
                                ? Object.keys(data.actCatg6)[0]
                                : data.actCatg6}
                            </dd>
                          </>
                        )} */}
                        </>
                        <dt>단위</dt>
                        <dd>
                          <div className="inputObj">
                            <div>
                              <p
                                onClick={() => {
                                  let cpData = structuredClone(dataList);
                                  cpData[index].opneSelectUnitOptions =
                                    !cpData[index].opneSelectUnitOptions;
                                  setDataList(cpData);
                                }}
                              >
                                <b>{data.selectUnit}</b>
                                <button>열림</button>
                              </p>
                              <ul
                                style={{
                                  display: data.opneSelectUnitOptions
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {data.units.map((unit, j) => (
                                  <li
                                    key={j}
                                    onClick={() => {
                                      let cpData = structuredClone(dataList);
                                      cpData[
                                        index
                                      ].opneSelectUnitOptions = false;
                                      cpData[index].selectUnit = unit.value;
                                      setDataList(cpData);
                                    }}
                                  >
                                    {unit.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <MyBookmarkCalApi2024 data={data} userInfo={myInfo} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {!dataList && (
        <div className="CalcuBookmark">
          <div className="cbSpBox noData">
            <Link to="/emission2024">
              <span>Go to browse and bookmark emission calculator</span>
            </Link>
          </div>
        </div>
      )}
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default MyBookmarkEmissionCal2024;
