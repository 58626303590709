import React from "react";

function ActivityCategoryBookmark(){
    return (
        <>
            <div className="pageTitle">
                My Emission Activity Category Bookmark
            </div>
            <div className="cbSpBox innerScroll">
                <div className="dataExpInput">
                    <div className="inputObj wide">
                        <div>
                            <p>
                                <b className="ellipsis placeholder">Select Lv1</b>
                                <button>열림</button>
                            </p>
                            <ul>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="dataExpStickyTable">
                    <table>
                        <colgroup>
                            <col width="8%"/>
                            <col width="19%"/>
                            <col width="19%"/>
                            <col width="19%"/>
                            <col width="19%"/>
                            <col width="8%"/>
                            <col width="8%"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <b>Scope</b>
                                </div>

                            </th>
                            <th>
                                <div>
                                    <b>Activity Level1</b>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Activity Level2</b>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Activity Level3</b>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Activity Level4</b>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Factor Count</b>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Bookmark</b>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div>1</div>
                            </td>
                            <td>
                                <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S);
                                    MDO/MGO(ULSFO)</div>
                            </td>
                            <td>
                                <div>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</div>
                            </td>
                            <td>
                                <div>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로 효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</div>
                            </td>
                            <td>
                                <div>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S);
                                    MDO/MGO(ULSFO)</div>
                            </td>
                            <td>
                                <div>27</div>
                            </td>
                            <td>
                                <div>
                                    <button className="fav"></button>
                                </div>
                            </td>
                        </tr>
                        {[...Array(10)].map((value, index) => {
                            let fav = index === 1 ? "fav on" : "fav";
                            return (
                                <tr>
                                    <td>
                                        <div>1</div>
                                    </td>
                                    <td>
                                        <div>Level1</div>
                                    </td>
                                    <td>
                                        <div>Level2</div>
                                    </td>
                                    <td>
                                        <div>Level3</div>
                                    </td>
                                    <td>
                                        <div>Level4</div>
                                    </td>
                                    <td>
                                        <div>27</div>
                                    </td>
                                    <td>
                                        <div>
                                            <button className={fav}></button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ActivityCategoryBookmark