import { DELETE, GET, POST } from "./fetch-auth-action.ts";
import { createTokenHeader } from "../util/authUtil.js";

export const getCheckBookmark = async (userNo) => {
  const URL = "/api/calculator/getCheckBookmark/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getSectorAndTemplate = async (userNo) => {
  const URL = "/api/calculator/getSectorAndTemplate/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const postTemplateUserProcess = async (param) => {
  const URL = "/api/calculator/template";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);

  return response;
};

export const postTemplateCalculator = async (param) => {
  const URL = "/api/calculator/amount";
  let header = await createTokenHeader();
  const response = POST(URL, param, header);

  return response;
};

export const getEmissionBookmarkMyList = async (userNo) => {
  const URL = "/api/bookmark/getUserTemplateList/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getEmissionBookmarkSector = async () => {
  const URL = "/api/bookmark/getCurtBiz";
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getEmissionBookmarkTemplate = async (userNo, curtNo) => {
  const URL = "/api/bookmark/getCurtBizCatg/" + userNo + "/" + curtNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const updateTemplate = async (params) => {
  const URL = "/api/bookmark/updateTemplateBookmark";
  let header = await createTokenHeader();
  const response = POST(URL, params, header);
  return response;
};

export const deleteTemplateBookmark = async (
  userCurtCatgId,
  userNo,
  curtCatgNo
) => {
  const URL =
    "/api/bookmark/deleteTemplateBookmark/" +
    userCurtCatgId +
    "/" +
    userNo +
    "/" +
    curtCatgNo;
  let header = await createTokenHeader();
  const response = DELETE(URL, header);
  return response;
};

export const insertTemplate = async (params) => {
  const URL = "/api/bookmark/insertTemplateBookmark";
  let header = await createTokenHeader();
  const response = POST(URL, params, header);
  return response;
};

export const getUserCalTemplate = async (userNo) => {
  const URL = "/api/bookmark/getUserCalTemplate/" + userNo;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};
