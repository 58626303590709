import React, { useState } from "react";

import { CommonDialog } from "../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
//import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { updatePassword } from "../../service/userRequestService";
import { alertData } from "../../util/alertData";

function CloseIconSvg() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" stroke="#000" fill="#000"></path>
      </svg>
  )
}

export default function PasswordResetPop({prop}) {
  const {t} = useTranslation();
  // 비밀번호 유효성 검사
  const pwdRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  const {popupStatus, setPopupStatus, userNo} = prop;
  const [formData, setFormData] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [formError, setFormError] = useState({
    password: "",
    passwordConfirm: "",
  });

  const handleSubmit = async () => {
    if (isNullCheck()) {
      const param = {
        password: formData.password,
        userNo: userNo,
      };
      try {
        const response = await updatePassword(param);
        if (response.status === 200) {
          if (response.data === 1) {
            alert(alertData.editAlert);
            setPopupStatus(false);
          }
        }
      } catch (error) {
        console.error("updatePassword Error:", error);
      }
    }
  };

  const isNullCheck = () => {
    let error = {};
    if (!formData.password) {
      error.password = "Password is required";
    } else if (!pwdRegex.test(formData.password)) {
      error.password =
        "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    } else {
      error.password = "";
    }

    if (!formData.passwordConfirm) {
      error.passwordConfirm = "Confirm Password is required";
    }
    // else if (formData.password !== formData.passwordConfirm) {
    //   error.passwordConfirm =
    //     "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    // }
    else if (formData.password !== formData.passwordConfirm) {
      error.passwordConfirm = "Please check the password you entered.";
    } else {
      error.passwordConfirm = "";
    }

    setFormError(error);
    if (error.password || error.passwordConfirm) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <CommonDialog
        open={popupStatus}
        onClose={() => {
          setPopupStatus(false);
        }}
      >
        <DialogTitle>{t(`MYINFO.chgPwd`)}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setPopupStatus(false);
          }}
          className="dialogClose"
        >
          <CloseIconSvg />
        </IconButton>
        <DialogContent>
          <div className="dialogCont">
            <div className="accountInput">
              <div className="accInp">
                <div>
                  <label>{t(`MYINFO.createPwd`)}</label>
                  <p>
                    <input
                      type="password"
                      onInput={(e) => {
                        setFormData({ ...formData, password: e.target.value });
                      }}
                    />
                  </p>
                  <span className="errorText">{formError?.password}</span>
                </div>
              </div>
              <div className="accInp">
                <div>
                  <label>{t(`MYINFO.confirmPwd`)}</label>
                  <p>
                    <input
                      type="password"
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          passwordConfirm: e.target.value,
                        });
                      }}
                    />
                  </p>
                  <span className="errorText">
                    {formError?.passwordConfirm}
                  </span>
                </div>
              </div>
            </div>
            <div className="dialogBtn">
              <button onClick={handleSubmit}>{t(`MYINFO.confirm`)}</button>
            </div>
          </div>
        </DialogContent>
      </CommonDialog>
    </>
  );
}
