export const formatDate = (data) => {
  const year = data.toString().slice(0, 4);
  const month = data.toString().slice(4, 6);
  const day = data.toString().slice(6, 8);

  return `${year}.${month}.${day}`;
};

export const nowInsertDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;

  const dateTimeInt = parseInt(dateTimeString, 10);
  return dateTimeInt;
};
