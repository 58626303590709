import React from "react";

function DataBookmark(){
    return (
        <>
            <div className="pageTitle">
                My Emission Data Bookmark
                <div className="btnArea">
                    <button>Check updated Data</button>
                    <button><span className="back">Back to list</span></button>
                </div>
            </div>
            <div className="DataBookMarkWrap">
                <div className="DataBookMarkList">
                    <div className="DataBookMark">
                        <button className="delete"></button>
                        <div>
                            <ul>
                                <li>
                                    <label>- Activity Level</label>
                                    <div>
                                        <p>철강 생산(iron and Steel Production)</p>
                                        <p>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 %
                                            S);
                                            MDO/MGO(ULSFO)</p>
                                        <p>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</p>
                                        <p>코크스 및 철 생산 coke & Iron Production - Tier 1 method</p>
                                    </div>
                                </li>
                                <li>
                                    <label>- GHG Conversion Factor</label>
                                    <span className="chkData">Check Data</span>
                                </li>
                                <li>
                                    <label>- Last update</label>
                                    <b>2023.09.03</b>
                                </li>
                            </ul>
                            <div className="Result">
                                <span><b>Scope1</b></span>
                                <span>Region <b>KOR</b></span>
                                <span>Data year <b>2021</b></span>
                                <span>Unit <b>kg</b></span>
                                <span>GHG/Unit <b>kgCO2e of CO2</b></span>
                                <span>Source <b>MOE/KOR</b></span>
                                <span>Calculation method <b>SAR</b></span>
                                <span>LCA Activity <b>--</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="DataBookMark History">
                        <p className="chkWrap">
                            <input type="radio" checked />
                            <em></em>
                        </p>
                        <div>
                            <ul>
                                <li>
                                    <label>- Activity Level</label>
                                    <div>
                                        <p>철강 생산(iron and Steel Production)</p>
                                        <p>Level2</p>
                                        <p>Level3</p>
                                        <p>코크스 및 철 생산 coke & Iron Production - Tier 1 method</p>
                                    </div>
                                </li>
                                <li>
                                    <label>- GHG Conversion Factor</label>
                                    <span className="chkData on">3.9451</span>
                                </li>
                                <li>
                                <label>- Last update</label>
                                    <b>2023.09.03</b>
                                </li>
                            </ul>
                            <div className="Result">
                                <span><b>Scope1</b></span>
                                <span>Region <b>KOR</b></span>
                                <span>Data year <b>2021</b></span>
                                <span>Unit <b>kg</b></span>
                                <span>GHG/Unit <b>kgCO2e of CO2</b></span>
                                <span>Source <b>MOE/KOR</b></span>
                                <span>Calculation method <b>SAR</b></span>
                                <span>LCA Activity <b>--</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="DataBookMark History">
                        <p className="chkWrap">
                            <input type="radio"/>
                            <em></em>
                        </p>
                        <div>
                            <ul>
                                <li>
                                    <label>- Activity Level</label>
                                    <div>
                                        <p>철강 생산(iron and Steel Production)</p>
                                        <p>Level2</p>
                                        <p>Level3</p>
                                        <p>코크스 및 철 생산 coke & Iron Production - Tier 1 method</p>
                                    </div>
                                </li>
                                <li>
                                    <label>- GHG Conversion Factor</label>
                                    <span className="chkData">Check Data</span>
                                </li>
                                <li>
                                <label>- Last update</label>
                                    <b>2023.09.03</b>
                                </li>
                            </ul>
                            <div className="Result">
                                <span><b>Scope1</b></span>
                                <span>Region <b>KOR</b></span>
                                <span>Data year <b>2021</b></span>
                                <span>Unit <b>kg</b></span>
                                <span>GHG/Unit <b>kgCO2e of CO2</b></span>
                                <span>Source <b>MOE/KOR</b></span>
                                <span>Calculation method <b>SAR</b></span>
                                <span>LCA Activity <b>--</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="addBook">
                        <button>Add to new bookmark</button>
                    </div>
                </div>
                <div className="DataBookMarkList">
                    <div className="DataBookMark">
                        <button className="delete"></button>
                        <div>
                            <ul>
                                <li>
                                    <label>- Activity Level</label>
                                    <div>
                                        <p>Level1</p>
                                        <p>Level2</p>
                                        <p>Level3</p>
                                        <p>Level4</p>
                                    </div>
                                </li>
                                <li>
                                    <label>- GHG Conversion Factor</label>
                                    <span className="chkData">Check Data</span>
                                </li>
                                <li>
                                <label>- Last update</label>
                                    <b>2023.09.03</b>
                                </li>
                            </ul>
                            <div className="Result">
                                <span><b>Scope1</b></span>
                                <span>Region <b>KOR</b></span>
                                <span>Data year <b>2021</b></span>
                                <span>Unit <b>kg</b></span>
                                <span>GHG/Unit <b>kgCO2e of CO2</b></span>
                                <span>Source <b>MOE/KOR</b></span>
                                <span>Calculation method <b>SAR</b></span>
                                <span>LCA Activity <b>--</b></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default DataBookmark;