import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  deleteTemplateBookmark,
  getEmissionBookmarkMyList,
  getEmissionBookmarkSector,
  getEmissionBookmarkTemplate,
  updateTemplate,
} from "../../service/calculatorService";
import { nowInsertDate } from "../../util/common";
import AddCalculatorBookmarkRaw from "./BeforeAddCalculatorBookmarkRaw2024";
import { alertData } from "../../util/alertData";
import { insertAllEmissionActivityCategoryBookmark } from "../../service/activityCategoryService";
import { useNavigate } from "react-router-dom";

function Bookmark() {
  const navigate = useNavigate();
  const [oriData, setOriData] = useState();
  const [copyData, setCopyData] = useState();
  const [sector, setSector] = useState();
  const [template, setTemplate] = useState();
  const [title, setTitle] = useState([{}]);
  const [addRowCheck, setAddrowCheck] = useState(false);
  const scrollElement = document.querySelector(".calcuList");
  const userJSON = localStorage.getItem("user");
  let userInfo;
  if (userJSON) {
    userInfo = jwt_decode(JSON.parse(userJSON)?.accessToken);
  }

  useEffect(() => {
    if (userInfo?.userName) {
      getMyList();
      getBizOption();
    } else {
      navigate("/login2024");
    }
  }, []);

  useEffect(() => {
    if (oriData) {
      setCopyData(structuredClone(oriData)); // 깊은 복사 해야함
    }
  }, [oriData]);

  const getBizOption = async () => {
    try {
      const response = await getEmissionBookmarkSector();
      if (response.status === 200) {
        setSector(response.data);
      }
    } catch (error) {
      console.error("getBizOption Error:", error);
    }
  };

  const getMyList = async () => {
    try {
      const response = await getEmissionBookmarkMyList(userInfo.userNo);
      if (response.status === 200) {
        let titleInfo = [];

        response.data.forEach((data) => {
          let titleTemp = {
            curtNo: data.curtNo,
            curtName: data.curtName,
            curtCatgNo: data.curtCatgNo,
            curtCatgName: data.curtCatgName,
          };

          titleInfo.push(titleTemp);
          data.editMode = false;
          data.bizOpen = false;
          data.catgOpen = false;
        });

        setTitle(titleInfo);
        setOriData(response.data);
      }
    } catch (error) {
      console.error("getMyList Error:", error);
    }
  };
  // ----------------------------------------------------------------
  // 셀렉트 클릭 시
  const clickSelect = (index, type) => {
    otherSelectViewInit(index);

    if (!editCheck(index)) {
      let data = [...copyData];
      if (type === "biz") {
        data[index].bizOpen = !data[index].bizOpen;
        data[index].catgOpen = false;
      } else {
        getTemplate(data[index]);
        data[index].bizOpen = false;
        data[index].catgOpen = !data[index].catgOpen;
      }
      setCopyData(data);
    } else {
      alert(alertData.editWarning);
    }
  };

  // 열려있는 셀렉트 박스 닫기
  function otherSelectViewInit(index) {
    let cpData = [...copyData];
    for (let i = 0; i < cpData.length; i++) {
      if (index !== i) {
        cpData[i].bizOpen = false;
        cpData[i].catgOpen = false;
      }
      setCopyData(cpData);
    }
  }

  // 수정모드 체크
  function editCheck(index) {
    let check = false;
    for (let i = 0; i < copyData.length; i++) {
      if (index != i) {
        if (copyData[i].editMode === true) {
          check = copyData[i].editMode;
        }
      }
    }
    return check;
  }

  // 템플릿 조회
  const getTemplate = async (data) => {
    try {
      const response = await getEmissionBookmarkTemplate(
        userInfo.userNo,
        data.curtNo
      );
      if (response.status === 200) {
        if (response.data.length < 1) {
          // 데이터가 없을 때
          let tepm = [
            {
              curtCatgName: alertData.allAndNull,
              curtCatgNo: null,
              curtName: null,
              curtNo: null,
            },
          ];
          setTemplate(tepm);
        } else {
          setTemplate(response.data);
        }
      }
    } catch (error) {
      console.error("getTemplate error => ", error);
    }
  };

  // ----------------------------------------------------------------
  // 업종 셀렉트 "옵션" 클릭 시
  const clickBizOption = (titleData, index) => {
    bizDataSetting(titleData, index); // 데이터 셋팅
    bizTitleSetting(titleData, index); // 타이틀 셋팅
  };

  function bizDataSetting(data, index) {
    let cpData = [...copyData];
    cpData[index].bizOpen = false; // option view 초기화
    cpData[index].curtNo = data.curtNo; // 값 셋팅
    cpData[index].curtName = data.curtName; // 값 셋팅
    cpData[index].curtCatgNo = null; // 값 셋팅
    cpData[index].curtCatgName = null; // 값 셋팅

    // editMode 셋팅
    if (
      cpData[index].curtNo !== oriData[index].curtNo ||
      cpData[index].curtCatgNo !== oriData[index].curtCatgNo
    ) {
      cpData[index].editMode = true;
    } else {
      cpData[index].editMode = false;
    }
    setCopyData(cpData);
  }

  function bizTitleSetting(data, index) {
    let copyTitle = [...title];
    copyTitle[index].curtNo = data.curtNo;
    copyTitle[index].curtName = data.curtName;
    copyTitle[index].curtCatgNo = null;
    copyTitle[index].curtCatgName = null;
    setTitle(copyTitle);
  }

  // ----------------------------------------------------------------
  // 템플릿 셀렉트 "옵션" 클릭 시
  const clickCatgOption = (titleData, index) => {
    if (titleData.curtCatgName === alertData.allAndNull) {
      return;
    } else {
      catgDataSetting(titleData, index); // 데이터 셋팅
      catgTitleSetting(titleData, index); // 타이틀 셋팅
    }
  };

  function catgDataSetting(data, index) {
    let cpData = [...copyData];
    cpData[index].catgOpen = false; // option view 초기화
    cpData[index].curtCatgNo = data.curtCatgNo; // 값 셋팅
    cpData[index].curtCatgName = data.curtCatgName; // 값 셋팅

    // editMode 셋팅
    if (
      cpData[index].curtNo !== oriData[index].curtNo ||
      cpData[index].curtCatgNo !== oriData[index].curtCatgNo
    ) {
      cpData[index].editMode = true;
    } else {
      cpData[index].editMode = false;
    }
    setCopyData(cpData);
  }

  function catgTitleSetting(data, index) {
    let copyTitle = [...title];
    copyTitle[index].curtCatgNo = data.curtCatgNo;
    copyTitle[index].curtCatgName = data.curtCatgName;
    setTitle(copyTitle);
  }
  // ----------------------------------------------------------------
  function updateClick(index) {
    if (!copyData[index].curtNo || !copyData[index].curtCatgNo) {
      alert(alertData.allCheckValue);
      return;
    } else {
      updateBookmark(copyData[index], index);
    }
  }

  const updateBookmark = async (data, index) => {
    const param = {
      userNo: userInfo.userNo,
      curtCatgNo: data.curtCatgNo,
      chgDtm: nowInsertDate(),
      userCurtCatgId: data.userCurtCatgId,
      deleteCurtCatgNo: oriData[index].curtCatgNo,
    };

    try {
      const response = await updateTemplate(param);
      if (response.status === 200) {
        let cpData = [...copyData];
        cpData[index].editMode = false;
        cpData[index].chgDtm = nowInsertDate();
        setCopyData(cpData);
        setOriData(cpData);
        alert(alertData.editAlert);

        // 해당 템플릿에 모든 activity Category 북마크
        try {
          const param = {
            userNo: userInfo.userNo,
            curtCatgNo: data.curtCatgNo,
            nationId: "KOR",
            langCd: null,
            // langCd: "ko",
          };

          await insertAllEmissionActivityCategoryBookmark(param);
        } catch (error) {
          console.error(
            "insertAllEmissionActivityCategoryBookmark error: ",
            error
          );
        }
      }
    } catch (error) {
      console.error("updateBookmark error => ", error);
    }
  };

  function resetClick() {
    setCopyData(structuredClone(oriData));
    let titleInfo = [];
    oriData.forEach((data) => {
      let titleTemp = {
        curtNo: data.curtNo,
        curtName: data.curtName,
        curtCatgNo: data.curtCatgNo,
        curtCatgName: data.curtCatgName,
      };

      titleInfo.push(titleTemp);
      data.editMode = false;
      data.bizOpen = false;
      data.catgOpen = false;
    });

    setTitle(titleInfo);
  }
  const deleteClick = (userCurtCatgId, curtCatgNo) => {
    /* eslint-disable no-restricted-globals */
    if (confirm(alertData.deleteConfirm)) {
      deleteBookmark(userCurtCatgId, curtCatgNo);
    }
  };

  const deleteBookmark = async (userCurtCatgId, curtCatgNo) => {
    try {
      const response = await deleteTemplateBookmark(
        userCurtCatgId,
        userInfo.userNo,
        curtCatgNo
      );

      if (response.status === 200) {
        // 데이터 필터링
        let cpData = [...copyData];
        let filterData = cpData.filter(
          (data) => data.userCurtCatgId !== userCurtCatgId
        );
        setCopyData(filterData);
        setOriData(filterData);

        // 타이틀 필터링
        let cpTitle = [...title];
        let filterTitle = cpTitle.filter(
          (data) => data.curtCatgNo !== curtCatgNo
        );
        setTitle(filterTitle);

        alert(alertData.deleteAlert);
      }
    } catch (error) {
      console.error("deleteTemplateBookmark error =>", error);
    }
  };

  const addRowClick = () => {
    setAddrowCheck(true);
  };
  return (
    <>
      <div className="pageTitle">My Emission Calculator Bookmark</div>
      <div className="cbSpBox CalcuBookmark">
        <div className="CalcuWrap">
          <div className="calcuList">
            {/*  */}
            {copyData && (
              <>
                {copyData.map((data, index) => (
                  <div className="calcuBox" key={index}>
                    <div className="calcuCont">
                      <div className="calcuTop">
                        <label>최종수정</label>
                        <b>
                          {data.userName}({data.chgDtm.toString().slice(2, 4)}.
                          {data.chgDtm.toString().slice(4, 6)}.
                          {data.chgDtm.toString().slice(6, 8)}{" "}
                          {data.chgDtm.toString().slice(8, 10)}:
                          {data.chgDtm.toString().slice(10, 12)})
                          {/* 24.07.31 23:57) */}
                        </b>
                        <button
                          className="delete"
                          onClick={() => {
                            deleteClick(data.userCurtCatgId, data.curtCatgNo);
                          }}
                        ></button>
                      </div>
                      <div className="calcuBtm">
                        {/* */}
                        <div className="inputObj">
                          <label>업종</label>
                          <div>
                            <>
                              <p
                                onClick={() => {
                                  clickSelect(index, "biz");
                                }}
                              >
                                <b>{title[index]?.curtName}</b>
                                <button>열림</button>
                              </p>
                              <ul
                                style={{
                                  display: data.bizOpen ? "block" : "none",
                                  top: "47px",
                                }}
                              >
                                {sector && (
                                  <>
                                    {sector.map((data, childIndex) => (
                                      <li
                                        key={childIndex}
                                        onClick={() => {
                                          clickBizOption(data, index);
                                        }}
                                      >
                                        {data.curtName}
                                      </li>
                                    ))}
                                  </>
                                )}
                              </ul>
                            </>
                          </div>
                        </div>
                        {/* */}
                        <div className="inputObj">
                          <label>Template</label>
                          <div>
                            <p
                              onClick={() => {
                                clickSelect(index, "catg");
                              }}
                            >
                              <b>{title[index]?.curtCatgName}</b>
                              <button>열림</button>
                            </p>
                            <ul
                              style={{
                                display: data.catgOpen ? "block" : "none",
                                top: "47px",
                              }}
                            >
                              {template && (
                                <>
                                  {template.map((data, childIndex) => (
                                    <li
                                      key={childIndex}
                                      onClick={() => {
                                        clickCatgOption(data, index);
                                      }}
                                    >
                                      {data.curtCatgName}
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                        {/* */}
                      </div>
                      {data.editMode === true && (
                        <div className="calcuButton">
                          <button
                            className="save"
                            onClick={() => {
                              updateClick(index);
                            }}
                          >
                            Update
                          </button>
                          <button onClick={resetClick}>Cancel</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}

            {addRowCheck && (
              <AddCalculatorBookmarkRaw
                scrollElement={scrollElement}
                setAddrowCheck={setAddrowCheck}
                userInfo={userInfo}
                oriData={oriData}
                setOriData={setOriData}
                oriTitle={title}
                setOriTitle={setTitle}
              />
            )}
          </div>
          {!addRowCheck && (
            <div
              className="calcuAdd"
              onClick={() => {
                addRowClick();
              }}
            >
              +
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Bookmark;
