export const alertData = {
  allAndNull: "템플릿이 모두 북마크 되어 있거나, 데이터가 없습니다.", // 변경되기 전 화면에서 사용함
  addCalulatorBookmarkDataNull: "업종과 템플릿을 모두 선택해주세요.", // 변경되기 전 화면에서 사용함
  insertAlert: "저장이 완료되었습니다", // 변경되기 전 화면에서 사용함
  editWarning: "수정작업 완료 후 다른 템플릿 수정이 가능합니다.", // 변경되기 전 화면에서 사용함
  deleteConfirm: "데이터를 삭제하시겠습니까?", // 변경되기 전 화면에서 사용함
  cancelBookmark: "북마크 해제가 완료 되었습니다.", // 변경되기 전 화면에서 사용함

  editAlert: "수정이 완료되었습니다.",
  failEditAlert: "수정이 실패되었습니다. 관리자에게 문의 바랍니다.",
  deleteAlert: "삭제가 완료되었습니다.",
  allCheckValue: "값을 모두 입력해주세요.",
  writeComplete: "작성이 완료되었습니다.",
  signUpComplete: "회원가입이 완료되었습니다.",
  passwordResetComplete: "패스워드 재설정이 완료되었습니다.",
  complateBookmark: "북마크 등록이 완료되었습니다.",
  searchDataNull: "입력한 데이터가 없습니다.",
  searchDataOver:
    "검색결과가 너무 많습니다. 더 상세한 키워드를 입력해주시기 바랍니다.",
  notEmissionUpdateDate: "최신 데이터입니다.",
  notReaction: "반응이 감지되지 않아 자동 로그아웃 되었습니다.",
  expirationToken: "토큰이 만료되었습니다. 다시 로그인 후 이용해주세요.",
  noDataCal: "계산결과가 없습니다.",
  serverError: "서버 에러 관리자에게 문의주세요.",
  plzLogin: "로그인을 진행해주세요.",
};
