import React from "react";

export default function Terms2024ENG() {
  return (
    <>
        <div className="policyCont eng">
            <ol>
                <li>
                    <b>Acceptance of Terms</b>
                    Welcome to Thingspire. By accessing or using our Thingspire Carbon API platform ("Service"), you agree to comply with and be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, you should not use the Service.
                </li>
                <li>
                    <b>Description of Service</b>
                    The Service provided by the Company allows users to [describe the primary function of the service, e.g., "manage and analyze business data"]. The Service is accessible through the website [carbonscope.io] ("Site").
                </li>
                <li>
                    <b>User Account and Responsibilities</b>
                    <ol>
                        <li>
                            <b>Account Registration</b>
                            To use certain features of the Service, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as needed.
                        </li>
                        <li>
                            <b>Account Security</b>
                            You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                        </li>
                        <li>
                            <b>User Conduct</b>
                            You agree not to use the Service to:
                            <ul>
                                <li>Violate any local, state, national, or international law.</li>
                                <li>Infringe upon the intellectual property rights of others.</li>
                                <li>Transmit any viruses or other harmful code.</li>
                                <li>Harass, abuse, or harm another person.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Acceptable Use Policy</b>
                    The Service must be used in accordance with all applicable laws and regulations. You agree not to:
                    <ul>
                        <li>Interfere with or disrupt the integrity or performance of the Service.</li>
                        <li>Attempt to gain unauthorized access to the Service or its related systems.</li>
                        <li>Use the Service in a way that could damage, disable, overburden, or impair any server or network.</li>
                    </ul>
                </li>
                <li>
                    <b>Intellectual Property</b>
                    <ol>
                        <li>
                            <b>Ownership</b>
                            All content, features, and functionality on the Service, including but not limited to software, text, graphics, logos, and images, are the exclusive property of the Company and its licensors.
                        </li>
                        <li>
                            <b>License to Use</b>
                            The Company grants you a limited, non-exclusive, non-transferable license to access and use the Service in accordance with these Terms. You may not copy, modify, distribute, sell, or lease any part of the Service.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Payment Terms</b>
                    If the Service requires payment, you agree to provide accurate and complete billing information and to pay all fees or charges associated with your account. All payments are non-refundable unless otherwise stated.
                </li>
                <li>
                    <b>Termination</b>
                    We reserve the right to suspend or terminate your access to the Service at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or our business interests.
                </li>
                <li>
                    <b>Limitation of Liability</b>
                    To the fullest extent permitted by law, the Company, its affiliates, and their respective officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                    <ul>
                        <li>Your use of the Service or inability to use the Service.</li>
                        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                    </ul>
                </li>
                <li>
                    <b>Disclaimer of Warranties</b>
                    The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Company makes no warranties, express or implied, regarding the Service, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </li>
                <li>
                    <b>Governing Law and Dispute Resolution</b>
                    These Terms will be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or related to these Terms or the Service will be resolved exclusively in the courts located in [Your Jurisdiction].
                </li>
                <li>
                    <b>Changes to Terms</b>
                    The Company reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our Site. You are advised to review these Terms periodically for any changes. Your continued use of the Service following the posting of changes constitutes your acceptance of those changes.
                </li>
                <li>
                    <b>Contact Information</b>
                    If you have any questions about these Terms, please contact us at [contact@thingspire.com].
                </li>
            </ol>
        </div>
    </>
  );
}
