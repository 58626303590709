import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Pricing() {
  const nav = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="pageTitle">{t("PRICING.title")}</div>
      <div className="priceWrap">
        <div className="priceGrid">
          <div className="cbSpBox">
            <div className="priceList priceData">
              <div></div>
              <div>
                <dl>
                  <dt></dt>
                  <dd>
                    <b>{t("PRICING.free")}</b>
                    <strong>{t("PRICING.free2")}</strong>
                  </dd>
                </dl>
              </div>
              <div>
                <dl>
                  <dt></dt>
                  <dd>
                    <b>{t("PRICING.monthly")}</b>
                    <strong>$1,000</strong>
                  </dd>
                </dl>
              </div>
              <div>
                <dl>
                  <dt>{t("PRICING.most")}</dt>
                  <dd>
                    <b>{t("PRICING.annually")}</b>
                    <strong>$10,000</strong>
                    <em>{t("PRICING.save")}</em>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.current")}</div>
              <div>O</div>
              <div></div>
              <div></div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.explore")}</div>
              <div>{t("PRICING.only")}</div>
              <div>{t("PRICING.unlimited")}</div>
              <div>{t("PRICING.unlimited")}</div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.bookmarks")}</div>
              <div>X</div>
              <div>{t("PRICING.unlimited")}</div>
              <div>{t("PRICING.unlimited")}</div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.request")}</div>
              <div>X</div>
              <div>O</div>
              <div>O</div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.upload")}</div>
              <div>X</div>
              <div>O</div>
              <div>O</div>
            </div>
            <div className="priceList">
              <div>{t("PRICING.update")}</div>
              <div>X</div>
              <div>O</div>
              <div>O</div>
            </div>
            <div className="priceList btn">
              <div></div>
              <div>{t("PRICING.default")}</div>
              <div
                onClick={() => {
                  nav("/help2024/1");
                }}
              >
                <button>{t("PRICING.choose")}</button>
              </div>
              <div
                onClick={() => {
                  nav("/help2024/1");
                }}
              >
                <button>{t("PRICING.choose")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
