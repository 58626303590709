import React from "react";

function Bookmark() {
    return (
        <>
            <div className="pageTitle">
                My Emission Calculator Bookmark
            </div>
            <div className="CalcuBookmark">
                <div className="CalcuWrap">
                    <div className="calcuList">

                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <label>최종수정</label>
                                    <b>홍길동(24.07.31 23:57)</b>
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <div className="inputObj">
                                        <label>업종</label>
                                        <div>
                                            <p>
                                                <b>철강 생산(iron and Steel Production)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>Template</label>
                                        <div>
                                            <p>
                                                <b>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB
                                                    maximum 0.10 % S); MDO/MGO(ULSFO)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul style={{display: "none"}}>
                                                <li>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB
                                                    maximum 0.10 % S); MDO/MGO(ULSFO)
                                                </li>
                                                <li>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>연료/원료</label>
                                        <div>
                                            <p>
                                                <b>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로 효율이 높고
                                                    용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>단위</label>
                                        <div>
                                            <p>
                                                <b>kg</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="calcuButton">
                                    <button className="save">Save</button>
                                    <button>Reset</button>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <label>최종수정</label>
                                    <b>홍길동(24.07.31 23:57)</b>
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <div className="inputObj">
                                        <label>업종</label>
                                        <div>
                                            <p>
                                                <b>일반</b>
                                                <button>열림</button>
                                            </p>
                                            <ul style={{display: "none"}}>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>Template</label>
                                        <div>
                                            <p>
                                                <b>연료 사용량 - 고정연소</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>연료/원료</label>
                                        <div>
                                            <p>
                                                <b>부탄 (LPG 3호)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>단위</label>
                                        <div>
                                            <p>
                                                <b>kg</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <label>최종수정</label>
                                    <b>홍길동(24.07.31 23:57)</b>
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <div className="inputObj">
                                        <label>업종</label>
                                        <div>
                                            <p>
                                                <b>일반</b>
                                                <button>열림</button>
                                            </p>
                                            <ul style={{display: "none"}}>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>Template</label>
                                        <div>
                                            <p>
                                                <b>연료 사용량 - 고정연소</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>연료/원료</label>
                                        <div>
                                            <p>
                                                <b>부탄 (LPG 3호)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>단위</label>
                                        <div>
                                            <p>
                                                <b>kg</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <label>최종수정</label>
                                    <b>홍길동(24.07.31 23:57)</b>
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <div className="inputObj">
                                        <label>업종</label>
                                        <div>
                                            <p>
                                                <b>일반</b>
                                                <button>열림</button>
                                            </p>
                                            <ul style={{display: "none"}}>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>Template</label>
                                        <div>
                                            <p>
                                                <b>연료 사용량 - 고정연소</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>연료/원료</label>
                                        <div>
                                            <p>
                                                <b>부탄 (LPG 3호)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>단위</label>
                                        <div>
                                            <p>
                                                <b>kg</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <label>최종수정</label>
                                    <b>홍길동(24.07.31 23:57)</b>
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <div className="inputObj">
                                        <label>업종</label>
                                        <div>
                                            <p>
                                                <b>일반</b>
                                                <button>열림</button>
                                            </p>
                                            <ul style={{display: "none"}}>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>Template</label>
                                        <div>
                                            <p>
                                                <b>연료 사용량 - 고정연소</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>연료/원료</label>
                                        <div>
                                            <p>
                                                <b>부탄 (LPG 3호)</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inputObj">
                                        <label>단위</label>
                                        <div>
                                            <p>
                                                <b>kg</b>
                                                <button>열림</button>
                                            </p>
                                            <ul>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                                <li>철강 생산(iron and Steel Production)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="calcuAdd">+</div>
                </div>
            </div>

        </>
    );
}

export default Bookmark;