import React from 'react';

function DataSource() {
  return (
      <>
        <div className="DSourceGrid">
          <div className="cbSpBox">
            <dl>
              <dt className="beis">BEIS</dt>
              <dd>
                영국 비즈니스, 에너지 및 산업 전략부(BEIS)는 영국 및 국제기구가 온실가스 배출을 보고하는 데 사용할 배출 환산 계수를 발행합니다. 이 계수는 매년 발표되며 이를 통해 조직은 다양한 활동에서 발생하는 온실가스 배출량을 계산할 수 있습니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="epa">EPA</dt>
              <dd>
                미국 환경 보호국(EPA)은 조직의 온실가스 보고를 위해 정기적으로 업데이트되고 사용하기 쉬운 기본 배출 계수 세트를 제공합니다. 배출계수의 주요 출처는 다음과 같습니다.
                <div className="line">
                  EPA의 GHG 보고 프로그램<br/>
                  EPA의 배출 및 발전 자원 통합 데이터베이스(eGRID)<br/>
                  미국 온실가스 배출 및 흡수원 목록<br/>
                  EPA의 폐기물 감소 모델(WARM)<br/>
                  IPCC의 온실가스 배출계수(AR4)
                </div>
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="moe_kor">MOE(KOR)</dt>
              <dd>
                한국 환경부(MOE)와 산하 기관인 한국환경산업기술원(KEITI)은 전과정 영향 평가를 통해 조직의 온실가스 보고에 대한 배출 계수를 제공합니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="moe_jp">MOE(JAP)</dt>
              <dd>
                일본 환경부(MOE)는 조직의 온실가스 보고를 위한 배출 계수 및 추정 방법을 제공합니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="diser">DISER</dt>
              <dd>
                산업과학에너지자원부(DISER)는 회사와 개인이 온실가스 배출량을 추정하는 데 사용할 수 있는 국가 온실가스 계수(NGA)를 제공하는 호주 정부 기관입니다. NGA 요소는 매년 업데이트되고 게시됩니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="mfe">MFE</dt>
              <dd>
                환경부(MFE)는 GHG 인벤토리 준비, 배출 계수 및 이를 활동 데이터에 적용하는 방법에 대한 정보를 제공하는 뉴질랜드 정부 기관입니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="tgo">TGO</dt>
              <dd>
                태국 온실가스 관리기구(TGO)는 조직이 사용할 배출 계수를 발행하는 태국 정부 기관입니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="exiobase">ExioBase</dt>
              <dd>
                EXIOBASE는 글로벌하고 상세한 MR-SUT(Multi-Regional Environmentally Extended Supply-Use Table) 및 MR-IOT(Input-Output Table)입니다. Ignite Procurement는 EXIOBASE 데이터베이스의 원본 데이터를 변경하여 이상값을 조정하고 지역(가중) 평균을 추가했습니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="glec">GLEC</dt>
              <dd>
                글로벌 물류 배출 협의회(Global Logistics Emissions Council)는 다중 모드 공급망 전반에 걸쳐 물류 GHG 배출 계산 및 보고를 조화시키기 위해 GLEC 프레임워크를 개발했습니다. 이는 ISO 14083 구현을 지원하는 주요 업계 지침이며 배송업체, 운송업체 및 물류 서비스 제공업체가 구현할 수 있습니다.
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt className="ipcc">IPCC</dt>
              <dd>
                2006년 국가 온실가스 인벤토리에 대한 IPCC 지침(2006 IPCC 지침)은 온실가스 배출원별 인위적 배출과 온실가스 흡수원별 제거에 대한 국가 인벤토리를 추정하는 방법론을 제공합니다.
              </dd>
            </dl>
          </div>
        </div>
      </>
  );
}

export default DataSource;
