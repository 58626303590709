import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { color } from "highcharts";
import HighchartsMap from 'highcharts/modules/map';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules";

/* LogoImg */
import BEIS from "../../images/Source/BEIS.png";
import Canada from "../../images/Source/canada.png";
import Climate from "../../images/Source/climate.png";
import DISER from "../../images/Source/DISER.png";
import Enerymarket from "../../images/Source/energymarket.png";
import EPA from "../../images/Source/EPA.png";
import European from "../../images/Source/european.png";
import ExioBase from "../../images/Source/ExioBase.png";
import GLEC from "../../images/Source/GLEC.png";
import Inecc from "../../images/Source/inecc.png";
import Instituto from "../../images/Source/instituto.png";
import IPCC from "../../images/Source/IPCC.png";
import KEA from "../../images/Source/KEA.png";
import Keiti from "../../images/Source/keiti.png";
import KoreaDistrict from "../../images/Source/koreaDistrict.png";
import MCTI from "../../images/Source/MCTI.png";
import MFE from "../../images/Source/MFE.png";
import Ministry from "../../images/Source/ministry.png";
import JAP from "../../images/Source/MOE(JAP).png";
import KOR from "../../images/Source/MOE(KOR).png";
import Peru from "../../images/Source/peru.png";
import TGO from "../../images/Source/TGO.png";
import WorldBank from "../../images/Source/The_World_Bank.png";
import iso01 from "../../images/iso01.svg";
import iso02 from "../../images/iso02.svg";
import iso03 from "../../images/iso03.svg";
import iso04 from "../../images/iso04.svg";

Highcharts.setOptions({
    lang: {
        thousandsSep: ",",
    },
});


const chart1option = {
    chart: {
        backgroundColor: "transparent",
        type: "pie",
        height: "260px",
        width: 460,
        align: "center",
    },
    title: {
        text: null,
    },
    subtitle: {
        useHTML: true,
        text: `<span style="display: block;text-align: center;font-size: 20px;color: #000;">Total</span><b style="display: block;text-align: center;font-size: 40px;font-weight: 800;color: #000;">25,342</b>`,
        floating: true,
        verticalAlign: "middle",
        align: "center",
        x: 80,
    },
    tooltip: {
        enabled: false
    },
    legend: {
        align: "left",
        verticalAlign: "middle",
        labelFormat:
            '<span style="color:{color}">{name}</span> : <b>{y:,.0f}</b>',
        layout: "vertical",
        itemStyle: {
            color: "#000",
            fontSize: "14px",
            fontWeight: "700",
            textOverflow: "ellipsis",
        },
        itemMarginBottom: 15,
        x: 0,
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            size: 180,
        },
        series: {
            borderWidth: 0,
            borderRadius: 0,
            colorByPoint: true,
            type: "pie",
            size: "100%",
            innerSize: "85%",
            dataLabels: {
                enabled: false,
            },
        },
    },
    series: [{
        type: 'pie',
        data: [
            {
                y: 5416,
                name: "Scope 1",
                color: "#4e75ff",
            },
            {
                y: 2017,
                name: "Scope 2",
                color: "#3e5ecc",
            },
            {
                y: 17711,
                name: "Scope 3",
                color: "#2f4699",
            }
        ],
    }],
    exporting: {
        buttons: {
            contextButton: {
                enabled: false
            }
        }
    },
}

const chart3option = {
    chart: {
        backgroundColor: "transparent",
        type: "bar",
        height: 250,
        spacingBottom: 150,
    },
    title: {
        text: null,
    },
    xAxis: {
        categories: [""],
        title: {
            text: null
        },
        labels: {
            enabled: false
        },
        gridLineWidth: 0,
        lineWidth: 0
    },
    yAxis: {
        labels: {
            enabled: false
        },
        title: {
            text: null
        },
        gridLineWidth: 0
    },
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        series: {
            stacking: "normal",
            borderWidth: 0,
            height: "40px",
        }
    },
    series: [
            {
                data: [1099],
                name: "Australia",
                color: "#EF9D00",
            },
            {
                data: [7218],
                name: "UK",
                color: "#4E75FF",
            },
            {
                data: [724],
                name: "Korea",
                color: "#40C0DC",
            },
            {
                data: [1621],
                name: "Japan",
                color: "#C00000",
            },
            {
                data: [2182],
                name: "New Zealand",
                color: "#F45C5C",
            },
            {
                data: [9393],
                name: "Other Countries",
                color: "#919191",
            }
    ],
    legend: {
        verticalAlign: 'top',
        floating: true,
        margin: 0,
        padding: 0,
        align: "center",
        labelFormat: '<span style="color:{color}">{name}</span> : <b>{yData:,.0f}</b>',
        itemStyle : {"color": "#000", "fontSize": "14px", "fontWeight": "500", "textOverflow": "ellipsis"},
        itemMarginBottom: 5,
        y: 90,
    },
    exporting: {
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
};

const mapChartOption = {
    chart: {
        map: mapDataWorld
    },
    title: {
        text: null,
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom'
        }
    },
    colorAxis: {
        min: 100,
        max: 220,
        minColor: '#E1E8FF',
        maxColor: '#4e75ff',
        stops: [
            [0, '#E1E8FF'],
            [0.67, '#A7BAFF'],
            [1, '#4e75ff']
        ]
    },
    series: [{
        dataLabels: {
            enabled: true,
            format: "{point.value}",
            style: {
                fontWeight: 'normal',

            }
        },
        allAreas: true,
        data: [
            ["fo", 0],
            ["um", 1],
            ["us", 2],
            ["jp", 3],
            ["sc", 4],
            ["in", 5],
            ["fr", 6],
            ["fm", 7],
            ["cn", 8],
            ["pt", 9],
            ["sw", 10],
            ["sh", 11],
            ["br", 12],
            ["ki", 13],
            ["ph", 14],
            ["mx", 15],
            ["es", 16],
            ["bu", 17],
            ["mv", 18],
            ["sp", 19],
            ["gb", 20],
            ["gr", 21],
            ["as", 22],
            ["dk", 23],
            ["gl", 250],
            ["gu", 25],
            ["mp", 26],
            ["pr", 27],
            ["vi", 28],
            ["ca", 29],
            ["st", 30],
            ["cv", 31],
            ["dm", 32],
            ["nl", 33],
            ["jm", 34],
            ["ws", 35],
            ["om", 36],
            ["vc", 37],
            ["tr", 38],
            ["bd", 39],
            ["lc", 40],
            ["nr", 41],
            ["no", 42],
            ["kn", 43],
            ["bh", 44],
            ["to", 45],
            ["fi", 46],
            ["id", 47],
            ["mu", 48],
            ["se", 49],
            ["tt", 50],
            ["my", 51],
            ["pa", 52],
            ["pw", 53],
            ["tv", 54],
            ["mh", 55],
            ["cl", 56],
            ["th", 57],
            ["gd", 58],
            ["ee", 59],
            ["ag", 60],
            ["tw", 61],
            ["bb", 62],
            ["it", 63],
            ["mt", 64],
            ["vu", 65],
            ["sg", 66],
            ["cy", 67],
            ["lk", 68],
            ["km", 69],
            ["fj", 70],
            ["ru", 171],
            ["va", 72],
            ["sm", 73],
            ["kz", 74],
            ["az", 75],
            ["tj", 76],
            ["ls", 77],
            ["uz", 78],
            ["ma", 79],
            ["co", 80],
            ["tl", 81],
            ["tz", 82],
            ["ar", 83],
            ["sa", 84],
            ["pk", 85],
            ["ye", 86],
            ["ae", 87],
            ["ke", 88],
            ["pe", 89],
            ["do", 90],
            ["ht", 91],
            ["pg", 92],
            ["ao", 93],
            ["kh", 94],
            ["vn", 95],
            ["mz", 96],
            ["cr", 97],
            ["bj", 98],
            ["ng", 99],
            ["ir", 100],
            ["sv", 101],
            ["sl", 102],
            ["gw", 103],
            ["hr", 104],
            ["bz", 105],
            ["za", 106],
            ["cf", 107],
            ["sd", 108],
            ["cd", 109],
            ["kw", 110],
            ["de", 111],
            ["be", 112],
            ["ie", 113],
            ["kp", 114],
            ["kr", 115],
            ["gy", 116],
            ["hn", 117],
            ["mm", 118],
            ["ga", 119],
            ["gq", 120],
            ["ni", 121],
            ["lv", 122],
            ["ug", 123],
            ["mw", 124],
            ["am", 125],
            ["sx", 126],
            ["tm", 127],
            ["zm", 128],
            ["nc", 129],
            ["mr", 130],
            ["dz", 131],
            ["lt", 132],
            ["et", 133],
            ["er", 134],
            ["gh", 135],
            ["si", 136],
            ["gt", 137],
            ["ba", 138],
            ["jo", 139],
            ["sy", 140],
            ["mc", 141],
            ["al", 142],
            ["uy", 143],
            ["cnm", 144],
            ["mn", 145],
            ["rw", 146],
            ["so", 147],
            ["bo", 148],
            ["cm", 149],
            ["cg", 150],
            ["eh", 151],
            ["rs", 152],
            ["me", 153],
            ["tg", 154],
            ["la", 155],
            ["af", 156],
            ["ua", 157],
            ["sk", 158],
            ["jk", 159],
            ["bg", 160],
            ["qa", 161],
            ["li", 162],
            ["at", 163],
            ["sz", 164],
            ["hu", 165],
            ["ro", 166],
            ["ne", 167],
            ["lu", 168],
            ["ad", 169],
            ["ci", 170],
            ["lr", 171],
            ["bn", 172],
            ["iq", 173],
            ["ge", 174],
            ["gm", 175],
            ["ch", 176],
            ["td", 177],
            ["kv", 178],
            ["lb", 179],
            ["dj", 180],
            ["bi", 181],
            ["sr", 182],
            ["il", 183],
            ["ml", 184],
            ["sn", 185],
            ["gn", 186],
            ["zw", 187],
            ["pl", 188],
            ["mk", 189],
            ["py", 190],
            ["by", 191],
            ["cz", 192],
            ["bf", 193],
            ["na", 194],
            ["ly", 195],
            ["tn", 196],
            ["bt", 197],
            ["md", 198],
            ["ss", 199],
            ["bw", 200],
            ["bs", 201],
            ["nz", 202],
            ["cu", 203],
            ["ec", 204],
            ["au", 205],
            ["ve", 206],
            ["sb", 207],
            ["mg", 208],
            ["is", 209],
            ["eg", 210],
            ["kg", 211],
            ["np", 212]
        ]
    }]
};

HighchartsMap(Highcharts);

function HomeNew(){
    return (
        <>
            <div className="welcomMsg">
                <b>Welcome to Thingspire Carbon API</b>
                <strong>The API for reliable<br/>carbon emission calculations</strong>
            </div>
            <div className="homeWrap">
                <dl className="solution">
                    <dt>Our solution is relevant to</dt>
                    <dd>
                        <div className="cbSpBox">
                            <div>
                                Organizations with their own carbon accounting solutions that require <b>continuous
                                emission data management</b>
                            </div>
                        </div>
                        <div className="cbSpBox">
                            <div>
                                Companies that need data management to <b>fulfill to a variety of local compliances
                                across the global supply chain</b>
                            </div>
                        </div>
                        <div className="cbSpBox">
                            <div>Companies that need to <b>manage private emission data</b></div>
                        </div>
                    </dd>
                </dl>
                <dl className="calculator">
                    <dt>Implement the world’s easiest and sophisticated<br/>emission calculator into your software</dt>
                    <dd>
                        <div className="cbSpBox">
                            <dl>
                                <dt>
                                    <em className="general"></em>General
                                </dt>
                                <dd>
                                    template builds upon
                                    <ul>
                                        <li>ISO 14064</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                        <div className="cbSpBox">
                            <dl>
                                <dt>
                                    <em className="manufac"></em>Manufacturing
                                </dt>
                                <dd>
                                    template builds upon
                                    <ul>
                                        <li>ISO 14064</li>
                                        <li>IPCC Guidelines</li>
                                        <li>World Steel Association reporting framework</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                        <div className="cbSpBox">
                            <dl>
                                <dt>
                                    <em className="logist"></em>Logistics
                                </dt>
                                <dd>
                                    template builds upon
                                    <ul>
                                        <li>ISO 14064</li>
                                        <li>IGLEC standards</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                        <div className="cbSpBox">
                            <dl>
                                <dt>
                                    <em className="hospital"></em>Hospitality
                                </dt>
                                <dd>
                                    template builds upon
                                    <ul>
                                        <li>ISO 14064</li>
                                        <li>GSTC and HCMI standards</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                        <div className="cbSpBox">
                            <dl>
                                <dt>
                                    <em className="ship"></em>Shipbuilding
                                </dt>
                                <dd>
                                    template builds upon
                                    <ul>
                                        <li>ISO 14064</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    </dd>
                </dl>
                <dl className="overview">
                    <dt>
                        Explore and implement the emission factors<br/>into your carbon accounting software
                    </dt>
                    <dd>
                        <div className="chartGrid">
                            <div className="basicChart">
                                <div className="cbSpBox">
                                    <strong>Our Data in Number</strong>
                                    <HighchartsReact highcharts={Highcharts} options={chart1option}/>
                                </div>
                                <div className="cbSpBox">
                                    <strong>Major coverage by Emission Activities</strong>
                                    <HighchartsReact highcharts={Highcharts} options={chart3option}/>
                                </div>
                            </div>
                            <div className="worldchart">
                                <div className="cbSpBox">
                                    <strong>Strong regional coverage on Asia-Pacific</strong>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'mapChart'}
                                        options={mapChartOption}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="cbSpBox swiperLogo">
                            <strong>Our Data is based on Science</strong>
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={60}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                            >
                                <SwiperSlide><img src={BEIS} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Canada} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Climate} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={DISER} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Enerymarket} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={EPA} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={European} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={ExioBase} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={GLEC} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Inecc} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Instituto} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={IPCC} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={KEA} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Keiti} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={KoreaDistrict} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={MCTI} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={MFE} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Ministry} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={JAP} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={KOR} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={Peru} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={TGO} alt=""/></SwiperSlide>
                                <SwiperSlide><img src={WorldBank} alt=""/></SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="cOverviewGrid">
                            <div className="cbSpBox">
                                <b>Easily customizable</b>
                                for different user environments such as Currency, Unit, Language Optimization
                            </div>
                            <div className="cbSpBox">
                                <b>Data Harmonization</b>
                                across various sources
                                around the world
                                <b>Row by Row inspection</b>
                            </div>
                            <div className="cbSpBox">
                                <b>Ease of Use</b>
                                by self-developed supplementary data for emission calculations
                            </div>
                            <div className="cbSpBox">
                                <b>Audit-Readiness</b>
                                by specific data source, transparent calculation methods and assumptions
                            </div>
                        </div>
                    </dd>
                </dl>
                <dl className="iso">
                    <dt>Made by sustainable-certified Thingspire Corporation</dt>
                    <dd>
                        <div className="cbSpBox">
                            <div><img src={iso01} alt=""/></div>
                            <div><img src={iso02} alt=""/></div>
                            <div><img src={iso03} alt=""/></div>
                            <div><img src={iso04} alt=""/></div>
                        </div>
                    </dd>
                </dl>
            </div>
        </>
    );
}

export default HomeNew;