import { GET, POST, DELETE } from "./fetch-auth-action.ts";
import { createTokenHeader } from "../util/authUtil.js";

export const getBizCategory = async () => {
  const URL = "/api/bookmark/getBizCategory";
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const getEmissionActivityCategoryList = async (userNo, catgId) => {
  const URL =
    "/api/bookmark/getEmissionActivityCategoryList/" + userNo + "/" + catgId;
  let header = await createTokenHeader();
  const response = GET(URL, header);
  return response;
};

export const insertEmissionActivityCategoryBookmark = async (params) => {
  const URL = "/api/bookmark/insert/EmissionActivityCategory/bookmark";
  let header = await createTokenHeader();
  const response = POST(URL, params, header);
  return response;
};

export const deleteEmissionActivityCategoryBookmark = async (
  factId,
  userNo
) => {
  const URL =
    "/api/bookmark/delete/EmissionActivityCategory/bookmark/" +
    factId +
    "/" +
    userNo;

  let header = await createTokenHeader();
  const response = DELETE(URL, header);
  return response;
};

export const insertAllEmissionActivityCategoryBookmark = async (params) => {
  const URL = "/api/bookmark/all/insert/EmissionActivityCategory/bookmark";
  let header = await createTokenHeader();
  const response = POST(URL, params, header);
  return response;
};

export const updateActCatgBookmarYn = async (params) => {
  const URL = "/api/bookmark/updateActCatgBookmarYn";
  let header = await createTokenHeader();
  const response = POST(URL, params, header);
  return response;
};
