import React, { useState } from "react";

import { CommonDialog, TitleDialog } from "../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
// import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

export default function AlertAndConfirmPop({ prop }) {
  const { t } = useTranslation();
  const { content, popupStatus, confirmText, setPopupStatus } = prop;

  function CloseIconSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          stroke="#000"
          fill="#000"
        ></path>
      </svg>
    );
  }

  return (
    <>
      <CommonDialog
        open={popupStatus}
        onClose={() => {
          setPopupStatus(false);
        }}
      >
        {/* <DialogTitle>{t(`ALERT.alert`)}</DialogTitle> */}
        <DialogTitle>
          {t(`${content}`)
            .split("\n")
            .map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setPopupStatus(false);
          }}
          className="dialogClose"
        >
          <CloseIconSvg />
          {/* <CloseIcon /> */}
        </IconButton>
        <DialogContent>
          <div className="dialogCont">
            <p className="dialogText">
              {/* {t(`${content}`)} */}
              {/* {content} */}
            </p>
            <div className="dialogBtn">
              {confirmText ? <button>{confirmText}</button> : null}
              <button
                className="cancel"
                onClick={() => {
                  setPopupStatus(false);
                }}
              >
                {t(`ALERT.cancel`)}
              </button>
            </div>
          </div>
        </DialogContent>
      </CommonDialog>
    </>
  );
}
