import React from "react";

function Password_Step1(){
    return (
        <>
            <div className="pageTitle">Find Password</div>
            <div className="cbSpBox accountBox">
                <div className="accountWrap inCenter">
                    <div className="accountInput">
                        <div className="accInp">
                            <div>
                                <label>Company Email</label>
                                <div>
                                    <p>
                                        <input type="text" value="jaehong"/>
                                    </p>
                                    <button>Verification</button>
                                </div>
                            </div>

                        </div>
                        <div className="accInp">
                            <div>
                                <label>Verification Code</label>

                                <p className="verifi">
                                    <input type="text" value="jaehong"/>
                                    <em>10:00</em>
                                </p>
                                <p className="success">
                                    <input type="text" value="jaehong"/>
                                </p>
                                <p className="error">
                                    <input type="text" value="jaehong"/>
                                </p>
                                <span className="errorText">error msg</span>
                            </div>
                        </div>
                    </div>
                    <div className="accountBtn">
                        <button>Confirm</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Password_Step1;