import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import EN from "./en/translation.json";
import KR from "./ko/translation.json";
const langCd = localStorage.getItem("langCd");
const resource = {
  ko: {
    translation: KR,
  },
  en: {
    translation: EN,
  },
};
i18n
  // .use(LanguageDetector) // 브라우저 언어로 자동 설정
  .use(initReactI18next)
  .init({
    resources: resource,
    fallbackLng: langCd ? langCd : "en", // 기본감지 언어
    // debug: true,
    interpolation: {
      escapeValue: false, // 특수문자를 이스케이프(escape)하지 않도록 하는 옵션
    },
    // react: {
    //     bindI18n: '', // prevent react-i18next rerender
    // },
  });
export default i18n;
