import React from "react";

function AccountInfo(){
    return (
        <>
            <div className="pageTitle">
                My Account detail
            </div>
            <div className="accountDetail">
                <div className="left">
                    <div className="cbSpBox">
                        <div className="boxTitle">General Information</div>
                        <div className="boxCont">
                            <ul>
                                <li>
                                    <span>Name</span>
                                    <b>Jaehong, Kim</b>
                                </li>
                                <li>
                                    <span>Company</span>
                                    <b>Thingspire, Ltd.</b>
                                </li>
                                <li>
                                    <span>Industry</span>
                                    <b>Professional, Scientific, and Technical Services</b>
                                </li>
                                <li>
                                    <span>Role</span>
                                    <b>Product Designer</b>
                                </li>
                                <li>
                                    <span>Work Email</span>
                                    <b>john@thinspire.com</b>
                                </li>
                                <li>
                                    <span>Password</span>
                                    <b>*************</b>
                                </li>
                            </ul>
                            <button>Edit</button>
                        </div>


                    </div>
                </div>
                <div className="right">
                    <div className="cbSpBox">
                        <div className="boxTitle">My API Key</div>
                        <div className="boxCont">
                            <p>Use this API Key: do not share this code to others</p>
                            <div className="key">N9T293-HJM29N-MYXXDP-RX13TF</div>
                            <div className="date">Issued on <b>August 1, 2022</b></div>
                        </div>
                    </div>
                    <div className="cbSpBox">
                    <div className="boxTitle">My Subscription Status</div>
                        <div className="boxCont">
                            <ul>
                                <li>
                                    <span>Subscription Plan</span>
                                    <b>Billed Annually ($10,000)</b>
                                </li>
                                <li>
                                    <span>Subscription Period</span>
                                    <b>2023.01.01 - 2023.12.31</b>
                                </li>
                            </ul>
                            <button>Change my subscription plan</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountInfo;