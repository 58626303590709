import React from "react";
import {Grid} from "@mui/material";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function CalcuExplorer(){
    return (
        <>
            <div className="pageTitle">
                Emission Calculator Explorer
            </div>
            <Grid container className="calcuExpGrid" spacing={2.5}>
                <Grid item xs={7} className="calcInput">
                    <strong>Implement the world’s most easiest and sophisticated emission calculator into your software.</strong>
                    <div className="cbSpBox">
                        <div className="inputDataWrap">
                            <div className="inputData">
                                <div className="inputObj">
                                    <label>업종</label>
                                    <div>
                                        <p>
                                            <b>철강 생산(iron and Steel Production)</b>
                                            <button>열림</button>
                                        </p>
                                        <ul>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inputObj">
                                    <label>Template</label>
                                    <div>
                                        <p>
                                            <b>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</b>
                                            <button>열림</button>
                                        </p>
                                        <ul style={{display : "none"}}>
                                            <li>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</li>
                                            <li>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inputObj">
                                    <label>생산 공정</label>
                                    <div>
                                        <p>
                                            <b>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로 효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</b>
                                            <button>열림</button>
                                        </p>
                                        <ul>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                            <li>철강 생산(iron and Steel Production)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inputObj">
                                    <label>생산량(kg)</label>
                                    <div>
                                        <p>
                                            <input type="text" value="1000" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="inputBtn">
                                <div className="half">
                                    <button className="bookmark"><span>Bookmark</span></button>
                                    <button className="reset"><span>Reset</span></button>
                                </div>
                                <button><span>Calculate</span></button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={5} className="calcData">
                    <dl className="calcResult">
                        <dt>
                            Calculation Result
                        </dt>
                        <dd className="cbSpBox">
                            <strong>200.1680 <em>kgCO2e</em></strong>
                        </dd>
                    </dl>
                    <dl className="calcDetail">
                        <dt>Calculation Detail</dt>
                        <dd className="cbSpBox">
                            {/*<div className="paidWrap">
                                <strong>This content is for<br/>paid members only.</strong>
                                <button>Check my pricing plan</button>
                            </div>*/}
                            <div className="calcDetailWrap">
                                <div className="summary">
                                    <b>Summary</b>
                                    <ul>
                                        <li>
                                            Emissions = CO2 + N2O + CH4
                                        </li>
                                    </ul>
                                </div>
                                <
                                    Swiper className="calDetailData"
                                           slidesPerView={1}
                                           pagination={{clickable: true}}
                                           navigation={true}
                                           modules={[Pagination, Navigation]}
                                >

                                    <SwiperSlide className="calDetailSlide">
                                        <strong>
                                            <b>Data 1</b> 0.67236396&nbsp;CO2 of kgCO2e
                                        </strong>

                                        <ul>
                                        <li>
                                                <b>Emissions Activity</b>
                                                <div>
                                                    <p>Fuel</p>
                                                    <p>Diesel</p>
                                                    <p>Marine Diesel</p>
                                                    <p>Marine Diesel/Gas Oil (ISO 8217 Grades DMX)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <b>Scope</b> <span>1</span>
                                            </li>
                                            <li>
                                                <b>Region</b> <span>KOR</span>
                                            </li>
                                            <li>
                                                <b>Data Year</b> <span>2021</span>
                                            </li>
                                            <li>
                                                <b>Unit</b> <span>kg</span>
                                            </li>
                                            <li>
                                                <b>Source</b> <span>MOE/KOR</span>
                                            </li>
                                            <li>
                                                <b>GWP100</b> <span>SAR</span>
                                            </li>
                                            <li>
                                                <b>LCA Activity</b> <span>--</span>
                                            </li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="calDetailSlide">
                                        <strong>
                                            <b>Data 2</b> 0.67236396&nbsp;CO2 of kgCO2e
                                        </strong>

                                        <ul>
                                            <li>
                                                <b>Emissions Activity</b>
                                                <div>
                                                    <p>Fuel</p>
                                                    <p>Diesel</p>
                                                    <p>Marine Diesel</p>
                                                    <p>Marine Diesel/Gas Oil (ISO 8217 Grades DMX)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <b>Scope</b> <span>1</span>
                                            </li>
                                            <li>
                                                <b>Region</b> <span>KOR</span>
                                            </li>
                                            <li>
                                                <b>Data Year</b> <span>2021</span>
                                            </li>
                                            <li>
                                                <b>Unit</b> <span>kg</span>
                                            </li>
                                            <li>
                                                <b>Source</b> <span>MOE/KOR</span>
                                            </li>
                                            <li>
                                                <b>GWP100</b> <span>SAR</span>
                                            </li>
                                            <li>
                                                <b>LCA Activity</b> <span>--</span>
                                            </li>
                                        </ul>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </dd>
                    </dl>
                </Grid>
            </Grid>
        </>
    );
}

export default CalcuExplorer;