import React from "react";

function CalcuOverview() {
    return (
        <>
            <div className="pageTitle">
                Welcome to the world’s most streamlined Emission Calculator API
            </div>
            <div className="cOverview">
                <strong>Implement the world’s easiest and sophisticated emission calculator into your software.</strong>
                <div className="cbSpBox">
                    At Thingspire, we have developed 5 types of template to date.<br/>
                    Our universal template is designed to cover a wide array of industries for general use,<br/>
                    whereas four industry-specific templates are designed to facilitate and<br/>
                    streamline emissions calculation for shipbuilding, hospitality, logistics and
                    manufacturing.<br/>
                    Our industry-specific templates were built upon relevant industry standards and protocols,<br/>
                    in order to empower non-ESG professionals to calculate their organizational carbon footprint.
                </div>
                <div className="cOverviewGrid">
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="general"></em>General
                            </dt>
                            <dd>
                                template builds upon
                                <ul>
                                    <li>ISO 14064</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="manufac"></em>Manufacturing
                            </dt>
                            <dd>
                                template builds upon
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>IPCC Guidelines</li>
                                    <li>World Steel Association reporting framework</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="logist"></em>Logistics
                            </dt>
                            <dd>
                                template builds upon
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>IGLEC standards</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="hospital"></em>Hospitality
                            </dt>
                            <dd>
                                template builds upon
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>GSTC and HCMI standards</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="ship"></em>Shipbuilding
                            </dt>
                            <dd>
                                template builds upon
                                <ul>
                                    <li>ISO 14064</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CalcuOverview