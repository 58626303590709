import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  auth: null,
  exp: null,
  inloNo: null,
  sessionId: null,
  sub: null,
  ugrpNo: null,
  userId: null,
  userMail: null,
  userName: null,
  userNo: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
