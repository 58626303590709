import React from 'react';

function DataExplorer() {
    return (
        <>
            <div className="pageTitle">
                Data Explorer
            </div>
            <div className="cbSpBox innerScroll">
                <div className="dataExpInput">
                    <div className="inputObj">
                        <div>
                            <p>
                                <b className="ellipsis">철강 생산(iron and Steel Production)</b>
                                <button>열림</button>
                            </p>
                            <ul>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="inputObj">
                        <div>
                            <p>
                                <b className="ellipsis placeholder">Select Lv2</b>
                                <button>열림</button>
                            </p>
                            <ul style={{display: "none"}}>
                                <li>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S);
                                    MDO/MGO(ULSFO)
                                </li>
                                <li>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="inputObj">
                        <div>
                            <p>
                                <b className="ellipsis">효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로 효율이 높고 용량제어 기능이 갖추어진
                                    종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</b>
                                <button>열림</button>
                            </p>
                            <ul>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="inputObj">
                        <div>
                            <p>
                                <b className="ellipsis">철강 생산</b>
                                <button>열림</button>
                            </p>
                            <ul>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                                <li>철강 생산(iron and Steel Production)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="dataExpStickyTable">
                    <table>
                        <colgroup>
                            <col width="7%"/>
                            <col width="7%"/>
                            <col width="8%"/>
                            <col width="6%"/>
                            <col width="12%"/>
                            <col/>
                            <col width="8%"/>
                            <col width="8%"/>
                            <col width="9%"/>
                            <col width="8%"/>
                            <col width="8%"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <b>Scope</b>
                                    <span className="orderby asc"></span>
                                </div>

                            </th>
                            <th>
                                <div>
                                    <b>Region</b>
                                    <span className="orderby desc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Data year</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Unit</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>GHG/Unit</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>GHG Conversion Factor</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Source</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Last<br/>Update</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Calculation<br />method</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>LCA<br/>Activity</b>
                                    <span className="orderby asc"></span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <b>Bookmark</b>
                                    <span className="orderby asc"></span>
                                </div>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={11}>
                                <div className="noData">Please select all activity levels of the data to explore more.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>1</div>
                            </td>
                            <td>
                                <div>KOR</div>
                            </td>
                            <td>
                                <div>2021</div>
                            </td>
                            <td>
                                <div>kg</div>
                            </td>
                            <td>
                                <div>kgCO2e of CO2</div>
                            </td>
                            <td>
                                <div className="right"><span className="chkData">Check Data</span></div>
                            </td>
                            <td>
                                <div>MOE/KOR</div>
                            </td>
                            <td>
                                <div>2023.09.03</div>
                            </td>
                            <td>
                                <div>SAR</div>
                            </td>
                            <td>
                                <div>--</div>
                            </td>
                            <td>
                                <div>
                                    <button className="fav"></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>1</div>
                            </td>
                            <td>
                                <div>KOR</div>
                            </td>
                            <td>
                                <div>2021</div>
                            </td>
                            <td>
                                <div>kg</div>
                            </td>
                            <td>
                                <div>kgCO2e of CO2</div>
                            </td>
                            <td>
                                <div className="right"><span className="chkData on">3.018682</span></div>
                            </td>
                            <td>
                                <div>MOE/KOR</div>
                            </td>
                            <td>
                                <div>2023.09.03</div>
                            </td>
                            <td>
                                <div>SAR</div>
                            </td>
                            <td>
                                <div>--</div>
                            </td>
                            <td>
                                <div>
                                    <button className="fav on"></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>1</div>
                            </td>
                            <td>
                                <div>KOR</div>
                            </td>
                            <td>
                                <div>2021</div>
                            </td>
                            <td>
                                <div>kg</div>
                            </td>
                            <td>
                                <div>kgCO2e of CO2</div>
                            </td>
                            <td>
                                <div className="right"><span className="chkData on">3.01</span></div>
                            </td>
                            <td>
                                <div>MOE/KOR</div>
                            </td>
                            <td>
                                <div>2023.09.03</div>
                            </td>
                            <td>
                                <div>SAR</div>
                            </td>
                            <td>
                                <div>--</div>
                            </td>
                            <td>
                                <div>
                                    <button className="fav on"></button>
                                </div>
                            </td>
                        </tr>
                        {[...Array(10)].map((value, index) => {
                            let fav = index === 1 ? "fav on" : "fav";
                            return (
                                <tr>
                                    <td>
                                        <div>1</div>
                                    </td>
                                    <td>
                                        <div>KOR</div>
                                    </td>
                                    <td>
                                        <div>2021</div>
                                    </td>
                                    <td>
                                        <div>kg</div>
                                    </td>
                                    <td>
                                        <div>kgCO2e of CO2</div>
                                    </td>
                                    <td>
                                        <div className="right"><span className="chkData on">3.018682</span></div>
                                    </td>
                                    <td>
                                        <div>MOE/KOR</div>
                                    </td>
                                    <td>
                                        <div>2023.09.03</div>
                                    </td>
                                    <td>
                                        <div>SAR</div>
                                    </td>
                                    <td>
                                        <div>--</div>
                                    </td>
                                    <td>
                                        <div>
                                            <button className={fav}></button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default DataExplorer;