import React from "react";

function Password_Step1(){
    return (
        <>
            <div className="pageTitle">Find Password</div>
            <div className="cbSpBox accountBox">
                <div className="accountWrap inCenter">
                    <div className="accountInput">
                        <div className="accInp">
                            <div>
                                <label>Create Password</label>
                                <p>
                                    <input type="password" value="jaehong"/>
                                </p>
                            </div>
                        </div>
                        <div className="accInp">
                            <div>
                                <label>Confirm Password</label>
                                <p>
                                    <input type="password" value="jaehong"/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accountBtn">
                        <button>Confirm</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Password_Step1;