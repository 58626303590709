import React from "react";

import Privacy2024KOR from "./Privacy2024KOR";
import Privacy2024ENG from "./Privacy2024ENG";

export default function Privacy2024() {
  return (
      <>
          <div className="pageTitle">Privacy Policy</div>
          <div className="cbSpBox innerScroll">
              <div className="policyContWrap">
                  <Privacy2024KOR />
                  <Privacy2024ENG />
              </div>
          </div>
      </>
  );
}
