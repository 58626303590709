import React, { useEffect, useState } from "react";
import Verification from "../common/Verification2024";
import SecondCreateAccount from "./SecondCreateAccount2024";
import { Link, useNavigate } from "react-router-dom";
import { getExistEmail, mailSend, postSignUp } from "../../service/signService";
import { alertData } from "../../util/alertData";
import { decrypt } from "../../util/crypto";
import { useTranslation } from "react-i18next";
import AlertAndConfirmPop from "../popup2024/AlertAndConfirmPop2024";

function FirstCreateAccount() {
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  // 비밀번호 유효성 검사
  const pwdRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  const { t } = useTranslation();
  const nav = useNavigate();
  const [clickCount, setClickCount] = useState(0);
  const [verifiView, setVerifiView] = useState(false);
  const [secondView, setSecondView] = useState(false);
  const [disabledSendBtn, setDisabledSendBtn] = useState(false);
  const [formData, setFormData] = useState({
    inputFirstName: "",
    inputLastName: "",
    inputCompanyName: "",
    inputEmail: "",

    inputVerification: "",

    inputIndustry: "",
    inputPassword: "",
    inputConfirmPassword: "",
    inputAgreeCheck: false,
  });

  const [formErrors, setFormErrors] = useState({
    inputFirstName: "",
    inputLastName: "",
    inputCompanyName: "",
    inputEmail: "",

    inputVerification: "",

    inputIndustry: "",
    inputPassword: "",
    inputConfirmPassword: "",
    inputAgreeCheck: "",
  });

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const prop = {
    formData: formData,
    setFormData: setFormData,
    formErrors: formErrors,
    setFormErrors: setFormErrors,
    setSecondView: setSecondView,
    clickCount: clickCount,
  };

  const firstInfoCheck = async () => {
    try {
      // 입력한 이메일이 있으면
      if (formData.inputEmail) {
        const response = await getExistEmail(formData.inputEmail);
        if (response.status === 200) {
          if (response.data === true) {
            setFormErrors({
              ...formErrors,
              inputEmail: "This email has already been registered.",
            });
          } else {
            fieldCheck();
          }
        }
      } else {
        // 이메일 값이 없으면
        setFormErrors({
          ...formErrors,
          inputEmail: "Email is required",
        });
      }
    } catch (error) {
      console.error("getExistEmail Error => : " + error);
    }
  };

  function fieldCheck() {
    const requiredFields = [
      { field: "inputFirstName", error: "First Name is required" },
      { field: "inputLastName", error: "Last Name is required" },
      { field: "inputCompanyName", error: "Company Name is required" },
      { field: "inputEmail", error: "Email is required" },
    ];

    let formErrors = {};

    requiredFields.forEach(({ field, error }) => {
      if (!formData[field]) {
        formErrors[field] = error;
      }
    });

    if (!validateEmail(formData.inputEmail)) {
      formErrors.inputEmail = formErrors.inputEmail || "Invalid email format";
    }

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      setFormErrors({});
      setClickCount(clickCount + 1);

      setDisabledSendBtn(true);
      sendEmailCode();
    }
  }

  const sendEmailCode = async () => {
    setVerifiView(true);
    try {
      // 여기에 메일전송 api
      const response = await mailSend(
        formData.inputEmail,
        null
        // formData.inputFirstName + " " + formData.inputLastName
      );

      if (response.status === 200) {
        setFormData({
          ...formData,
          inputVerification: String(decrypt(response.data)),
        });
      }
      setDisabledSendBtn(false);
    } catch (error) {
      setDisabledSendBtn(false);
      setVerifiView(false);
    }
  };

  const onClickSignup = () => {
    if (inputCheck()) {
      signUp();
    }
  };
  const inputCheck = () => {
    const fields = [
      "inputFirstName",
      "inputLastName",
      "inputCompanyName",
      "inputEmail",
      "inputIndustry",
      "inputPassword",
      "inputConfirmPassword",
    ];
    const firstErrorInfo = {};

    // null 체크
    fields.forEach((field) => {
      firstErrorInfo[field] = formData[field]
        ? ""
        : `${field
            .replace("input", "")
            .replace(/([A-Z])/g, " $1")
            .trim()} is required`;
    });

    // 이용약관
    if (formData.inputAgreeCheck === false) {
      firstErrorInfo.inputAgreeCheck =
        "Please agree to the terms and conditions.";
    } else {
      firstErrorInfo.inputAgreeCheck = "";
    }

    // 패스워드 비교
    if (formData.inputPassword !== formData.inputConfirmPassword) {
      firstErrorInfo.inputConfirmPassword =
        "Please check the password you entered.";
    } else {
      if (formData.inputConfirmPassword) {
        firstErrorInfo.inputConfirmPassword = "";
      }
    }

    if (!pwdRegex.test(formData.inputPassword)) {
      firstErrorInfo.inputPassword =
        "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    }
    if (!pwdRegex.test(formData.inputConfirmPassword)) {
      firstErrorInfo.inputConfirmPassword =
        "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    }

    setFormErrors({
      ...formErrors,
      ...firstErrorInfo,
    });

    if (
      firstErrorInfo.inputFirstName ||
      firstErrorInfo.inputLastName ||
      firstErrorInfo.inputCompanyName ||
      firstErrorInfo.inputEmail ||
      firstErrorInfo.inputIndustry ||
      firstErrorInfo.inputPassword ||
      firstErrorInfo.inputConfirmPassword ||
      firstErrorInfo.inputAgreeCheck
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (popupStatus === false && popupData.content) {
      nav("/login2024");
    }
  }, [popupStatus]);

  const signUp = async () => {
    try {
      const params = {
        firstName: formData.inputFirstName,
        lastName: formData.inputLastName,
        email: formData.inputEmail,
        password: formData.inputPassword,
        id: formData.inputEmail,
        appKey: null,
        nationId: "KOR",
        userName: formData.inputFirstName + " " + formData.inputLastName,
        industryCd: formData.inputIndustry,
        companyName: formData.inputCompanyName,
      };
      const response = await postSignUp(params);
      if (response.status === 200) {
        setPopupData((prevData) => ({
          ...prevData,
          popupStatus: true,
          content: "ALERT.signUpComplete",
        }));
        setPopupStatus(true);
      }
    } catch (error) {
      console.error("postSignUp error => ", error);
    }
  };

  return (
    <>
      <div className="pageTitle">{t(`SIGNUP.signUpTitle`)}</div>
      <div className="cbSpBox accountBox">
        <div className="accountWrap">
          <div className="accountInput">
            <div className="accInp">
              <div>
                <label>{t(`SIGNUP.firstNm`)}</label>
                <p className={formErrors.inputFirstName ? "error" : null}>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        inputFirstName: e.target.value,
                      });
                    }}
                  />
                </p>
                <span className="errorText">{formErrors.inputFirstName}</span>
              </div>
              <div>
                <label>{t(`SIGNUP.lastNm`)}</label>
                <p className={formErrors.inputLastName ? "error" : null}>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        inputLastName: e.target.value,
                      });
                    }}
                  />
                </p>
                <span className="errorText">{formErrors.inputLastName}</span>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`SIGNUP.companyNm`)}</label>
                <p className={formErrors.inputCompanyName ? "error" : null}>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        inputCompanyName: e.target.value,
                      });
                    }}
                  />
                </p>
                <span className="errorText">{formErrors.inputCompanyName}</span>
              </div>
            </div>
            <div className="accInp">
              <div>
                <label>{t(`SIGNUP.email`)}</label>
                <div>
                  <p className={formErrors.inputEmail ? "error" : null}>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          inputEmail: e.target.value,
                          inputVerification: "",
                          inputIndustry: "",
                          inputPassword: "",
                          inputConfirmPassword: "",
                        });

                        setFormErrors({
                          ...formErrors,
                          inputEmail: "",
                          inputVerification: "",
                          inputIndustry: "",
                          inputPassword: "",
                          inputConfirmPassword: "",
                          inputAgreeCheck: "",
                        });

                        setVerifiView(false);
                        setSecondView(false);
                      }}
                    />
                  </p>
                  <button onClick={firstInfoCheck} disabled={disabledSendBtn}>
                    {t(`SIGNUP.send`)}
                  </button>
                </div>
                <span className="errorText">{formErrors.inputEmail}</span>
              </div>
            </div>
            {/* 확인 코드 */}
            {verifiView && <Verification prop={prop} />}

            {/* 두번째 스텝 */}
            {secondView && <SecondCreateAccount prop={prop} />}
          </div>

          {/* 동의 및 가입 스텝 */}
          {secondView && (
            <>
              <div className="accountMsg">
                {t(`SIGNUP.policy1`)}
                <br />
                {t(`SIGNUP.policy2`)}
                <p className="chkWrap">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        inputAgreeCheck: e.target.checked,
                      });
                    }}
                  />
                  <label>{t(`SIGNUP.agree`)}</label>
                </p>
                <div className="accInp">
                  <span
                    style={{
                      color: "#c00000",
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "block",
                      marginTop: "10px",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.inputAgreeCheck}
                  </span>
                </div>
              </div>
              <div className="accountBtn">
                <button
                  onClick={() => {
                    onClickSignup();
                  }}
                >
                  {t(`SIGNUP.signUp`)}
                </button>
                <p>
                  {t(`SIGNUP.haveSign`)}
                  <Link to="/login2024">{t(`SIGNIN.signIn`)}</Link>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default FirstCreateAccount;
