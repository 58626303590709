import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Verification({ prop }) {
  const { t } = useTranslation();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    setSecondView,
    clickCount,
  } = prop;
  const [currenInputCode, setCurrenInputCode] = useState(null);
  const [verifiStatus, setVerifiStatus] = useState(null);
  const [time, setTime] = useState(600); // 10분 = 600초
  useEffect(() => {
    setVerifiStatus(null); // 코드 입력 폼 초기화
    setTime(600); // 시간 초기화
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime < 1) {
          clearInterval(intervalId);
          setVerifiStatus("error");
        } else {
          return prevTime > 0 ? prevTime - 1 : 0;
        }
      });
    }, 1000);
  }, [clickCount]);

  // 시간을 mm:ss 형식으로 변환하는
  const formatTime = (seconds) => {
    const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  const clickVerification = () => {
    if (formData.inputVerification !== currenInputCode) {
      setVerifiStatus(false);
      setFormErrors({
        ...formErrors,
        inputVerification: "This is incorrect code.",
      });
    } else {
      setTime(9999999); // (임시) intervalCount
      setVerifiStatus(true);

      setFormErrors({
        ...formErrors,
        inputVerification: "",
      });
      setSecondView(true);
    }
  };

  return (
    <>
      <div className="accInp">
        <div>
          <label>{t(`SIGNUP.verifiCode`)}</label>

          {!verifiStatus && (
            <>
              <p
                className="verifi"
                style={{
                  borderColor: formErrors.inputVerification ? "#c00000" : "",
                }}
              >
                <input
                  type="text"
                  onChange={(e) => {
                    setCurrenInputCode(e.target.value);
                  }}
                />
                <em
                  style={{
                    color: formErrors.inputVerification ? "#c00000" : "",
                  }}
                >
                  {formatTime(time)}
                </em>
                <button onClick={clickVerification}>
                  {t(`SIGNUP.verifi`)}
                </button>
              </p>
              {formErrors.inputVerification && (
                <span className="errorText">
                  {formErrors.inputVerification}
                </span>
              )}
            </>
          )}

          {verifiStatus === true && (
            <p className="success">
              <input type="text" value={currenInputCode} readOnly />
            </p>
          )}

          {verifiStatus === "error" && (
            <p className="error">
              <input
                type="text"
                style={{ color: "#c00000" }}
                value="Input time has been exceeded."
                readOnly
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
}
