import React, { useEffect, useState } from "react";
import Verification from "../../common/Verification2024";
import PasswordStep2 from "./PasswordStep2";
import {
  getExistEmail,
  mailSend,
  postResetPassword,
} from "../../../service/signService";
import { decrypt } from "../../../util/crypto";
import { useNavigate } from "react-router-dom";
import { alertData } from "../../../util/alertData";
import { useTranslation } from "react-i18next";
import AlertAndConfirmPop from "../../popup2024/AlertAndConfirmPop2024";

function PasswordStep1() {
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupData, setPopupData] = useState({
    content: "",
    popupStatus: popupStatus,
    confirmText: null,
    setPopupStatus: setPopupStatus,
  });

  // 비밀번호 유효성 검사
  const pwdRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  const { t } = useTranslation();
  const nav = useNavigate();
  const [verifiView, setVerifiView] = useState(false);
  const [disabledSendBtn, setDisabledSendBtn] = useState(false);
  const [secondView, setSecondView] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const [formData, setFormData] = useState({
    inputEmail: "",
    inputVerification: "",
    inputPassword: "",
    inputConfirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    inputEmail: "",
    inputVerification: "",
    inputPassword: "",
    inputConfirmPassword: "",
  });

  const prop = {
    formData: formData,
    setFormData: setFormData,
    formErrors: formErrors,
    setFormErrors: setFormErrors,
    setSecondView: setSecondView,
    clickCount: clickCount,
  };

  const firstInfoCheck = async () => {
    try {
      // 입력한 이메일이 있으면
      if (formData.inputEmail) {
        const response = await getExistEmail(formData.inputEmail);
        if (response.status === 200) {
          if (response.data === false) {
            setFormErrors({
              ...formErrors,
              inputEmail: "This is not your registered email address.",
            });
          } else {
            fieldCheck();
          }
        }
      } else {
        // 이메일 값이 없으면
        setFormErrors({
          ...formErrors,
          inputEmail: "Email is required",
        });
      }
    } catch (error) {
      console.error("getExistEmail Error => : " + error);
    }
  };

  function fieldCheck() {
    if (!formData.inputEmail) {
      setFormErrors({ ...formData, inputEmail: "Email is required" });
    } else if (!validateEmail(formData.inputEmail)) {
      setFormErrors({ ...formData, inputEmail: "Invalid email format" });
    } else {
      setClickCount(clickCount + 1);
      setDisabledSendBtn(true);
      sendEmailCode();
    }
  }

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const sendEmailCode = async () => {
    setVerifiView(true);
    try {
      // 여기에 메일전송 api
      const response = await mailSend(
        formData.inputEmail,
        null
        // formData.inputFirstName + " " + formData.inputLastName
      );

      if (response.status === 200) {
        setFormData({
          ...formData,
          inputVerification: String(decrypt(response.data)),
        });
      }
      setDisabledSendBtn(false);
    } catch (error) {
      setDisabledSendBtn(false);
      setVerifiView(false);
    }
  };

  const onClickResetPassword = () => {
    if (inputCheck()) {
      resetPassword();
    }
  };

  const inputCheck = () => {
    const fields = ["inputEmail", "inputPassword", "inputConfirmPassword"];
    const firstErrorInfo = {};

    // null 체크
    fields.forEach((field) => {
      firstErrorInfo[field] = formData[field]
        ? ""
        : `${field
            .replace("input", "")
            .replace(/([A-Z])/g, " $1")
            .trim()} is required`;
    });

    // 패스워드 비교
    if (formData.inputPassword !== formData.inputConfirmPassword) {
      firstErrorInfo.inputConfirmPassword =
        "Please check the password you entered.";
    } else {
      if (formData.inputConfirmPassword) {
        firstErrorInfo.inputConfirmPassword = "";
      }
    }

    if (!pwdRegex.test(formData.inputPassword)) {
      firstErrorInfo.inputPassword =
        "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    }
    if (!pwdRegex.test(formData.inputConfirmPassword)) {
      firstErrorInfo.inputConfirmPassword =
        "The password must be at least 8 characters containing a combination of English letters, special characters, and numbers.";
    }

    setFormErrors({
      ...formErrors,
      ...firstErrorInfo,
    });

    if (
      firstErrorInfo.inputEmail ||
      firstErrorInfo.inputPassword ||
      firstErrorInfo.inputConfirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (popupStatus === false && popupData.content) {
      nav("/login2024");
    }
  }, [popupStatus]);

  const resetPassword = async () => {
    const params = {
      email: formData.inputEmail,
      password: formData.inputPassword,
    };
    try {
      const response = await postResetPassword(params);
      if (response.status === 200) {
        setPopupData((prevData) => ({
          ...prevData,
          popupStatus: true,
          content: "ALERT.passwordResetComplete",
        }));
        setPopupStatus(true);
      }
    } catch (error) {
      console.error();
    }
  };

  return (
    <>
      <div className="pageTitle">{t(`RESETPWD.resetPwd`)}</div>
      <div className="cbSpBox accountBox">
        <div className="accountWrap inCenter">
          <div className="accountInput">
            {!secondView && (
              <div className="accInp">
                <div>
                  <label>{t(`RESETPWD.email`)}</label>
                  <div>
                    <p className={formErrors.inputEmail ? "error" : null}>
                      <input
                        type="text"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            inputEmail: e.target.value,
                            inputVerification: "",
                            inputPassword: "",
                            inputConfirmPassword: "",
                          });

                          setFormErrors({
                            ...formErrors,
                            inputEmail: "",
                            inputVerification: "",
                            inputPassword: "",
                            inputConfirmPassword: "",
                            inputAgreeCheck: "",
                          });

                          setVerifiView(false);
                          setSecondView(false);
                        }}
                      />
                    </p>
                    <button disabled={disabledSendBtn} onClick={firstInfoCheck}>
                      {t(`RESETPWD.verifi`)}
                    </button>
                  </div>
                  <span className="errorText">{formErrors.inputEmail}</span>
                </div>
              </div>
            )}

            {/* 확인 코드 */}
            {!secondView && verifiView && <Verification prop={prop} />}

            {/* 패스워드 재설정 */}
            {secondView && (
              <PasswordStep2
                formData={formData}
                setFormData={setFormData}
                formErrors={formErrors}
              />
            )}
          </div>
          {secondView && (
            <div className="accountBtn">
              <button onClick={onClickResetPassword}>
                {t(`RESETPWD.resetPwd`)}
              </button>
            </div>
          )}
        </div>
      </div>
      {popupStatus ? <AlertAndConfirmPop prop={popupData} /> : null}
    </>
  );
}

export default PasswordStep1;
