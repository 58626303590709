import React from "react";
import { useTranslation } from "react-i18next";

function DataSource() {
  const { t } = useTranslation();
  return (
    <>
      <div className="DSourceGrid">
        <div className="cbSpBox">
          <dl>
            <dt className="beis">BEIS</dt>
            <dd>{t(`SOURCESDATA.beis`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="epa">EPA</dt>
            <dd>
              {t(`SOURCESDATA.epa`)}
              <div className="line">
                {t(`SOURCESDATA.ghgReporting`)}
                <br />
                {t(`SOURCESDATA.eGrid`)}
                <br />
                {t(`SOURCESDATA.usEmissions`)}
                <br />
                {t(`SOURCESDATA.warm`)}
                <br />
                {t(`SOURCESDATA.ipcc`)}
              </div>
            </dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="moe_kor">MOE(KOR)</dt>
            <dd>{t(`SOURCESDATA.koreaMoe`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="moe_jp">MOE(JAP)</dt>
            <dd>{t(`SOURCESDATA.japMoe`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="diser">DISER</dt>
            <dd>{t(`SOURCESDATA.nga`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="mfe">MFE</dt>
            <dd>{t(`SOURCESDATA.mfe`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="tgo">TGO</dt>
            <dd>{t(`SOURCESDATA.tgo`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="exiobase">ExioBase</dt>
            <dd>{t(`SOURCESDATA.exioBase`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="glec">GLEC</dt>
            <dd>{t(`SOURCESDATA.glec`)}</dd>
          </dl>
        </div>
        <div className="cbSpBox">
          <dl>
            <dt className="ipcc">IPCC</dt>
            <dd>{t(`SOURCESDATA.ipcc2006`)}</dd>
          </dl>
        </div>
      </div>
    </>
  );
}

export default DataSource;
