import React, {useEffect} from "react";
import iso01 from "../../images/iso01.svg";
import iso02 from "../../images/iso02.svg";
import iso03 from "../../images/iso03.svg";
import iso04 from "../../images/iso04.svg";

function Home_Kr() {
    useEffect(() => {
        document.body.classList.add('kr');
    });
        return (
        <>
            <div className="pageTitle">
                카본스코프에 오신 것을 환영합니다
            </div>
            <div className="homeTopGrid">
                <div className="cbSpBox">
                    <div className="boxTitle">탄소경영은 필수 이슈 입니다</div>
                    <div className="pressure">
                        <div className="gover"><b>정부</b></div>
                        <div className="indu"><b>산업</b></div>
                        <div className="corp"><b>기업</b></div>
                        <p className="arrow">
                            <b></b>
                            <span>세계적인 압력</span>
                        </p>
                    </div>
                    <dl className="pressure_desc">
                        <dt>파리협정</dt>
                        <dd>
                            산업화 이전 대비 지구 평균기온이 1.5도 이상 상승하지 않도록 모든 국가가 넷제로(net-zero)를 약속하는 협약입니다.
                        </dd>
                        <dt>
                            국가온실가스 감축목표
                        </dt>
                        <dd>
                            각 국가는 기후변화 대응 및 온실가스 감축 목표를 발표하고 이행하기 위해  목표,절차,방법론을 제출해야 합니다.
                        </dd>
                    </dl>
                </div>
                <div className="cbSpBox">
                    <div className="boxTitle">기업은 탄소 배출 관리에 어려움을 겪고 있습니다.</div>
                    <dl>
                        <dt>온실가스 계산 자동화가 필요 합니다.</dt>
                        <dd>현재 방식은 기술이 낮고 수동적입니다.</dd>
                        <dt>배출량 측정에 복잡성이 존재합니다.</dt>
                        <dd>인간 활동, 지역 및 방법론 전반에 걸쳐 데이터가 일관되지 않음</dd>
                        <dt>시간, 노동력, 비용이 많이 소요됨</dt>
                        <dd>
                            엄청난 양의 개발 및 감사 준비 비용 소요
                        </dd>
                    </dl>
                </div>
                <div className="cbSpBox">
                    <div className="boxTitle">Carbonscope가 도움을 줄 수 있는 방법은<br />다음과 같습니다.</div>
                    <dl>
                        <dt>사용의 용이성</dt>
                        <dd>검색과 북마크만으로 API 사용가능</dd>
                        <dt>간소화됨</dt>
                        <dd>빅데이터를 통한 물질수지</dd>
                        <dt>정확한</dt>
                        <dd>과학 기반 계산</dd>
                        <dt>철저한</dt>
                        <dd>귀하의 비즈니스에 적합한 종합 플랫폼</dd>
                        <dt>감사 준비</dt>
                        <dd>데이터, 방법 및 가정의 투명성</dd>
                    </dl>
                </div>
            </div>
            <div className="homeBtmGrid">
                <div className="cbSpBox">
                    <a href="#">나의 API 북마크 확인</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">오픈 API 문서</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">탄소 배출 계산기 살펴보기</a>
                </div>
                <div className="cbSpBox">
                    <a href="#">배출 데이터 살펴보기</a>
                </div>
                <div className="cbSpBox">
                    <div>
                        인증서
                        <span>
                            <img src={iso01}/>
                            <img src={iso02}/>
                            <img src={iso03}/>
                            <img src={iso04}/>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home_Kr;