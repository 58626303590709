import React from "react";
import { useTranslation } from "react-i18next";

function CalcuOverview() {
  const { t } = useTranslation();
  return (
    <>
      <div className="pageTitle">{t(`OVERVIEWCAL.welcomeCal`)}</div>
      <div className="cOverview">
        <strong>{t(`OVERVIEWCAL.softwareDesc`)}</strong>
        <div className="cbSpBox">
          {t("OVERVIEWCAL.thingspireDesc")
            .split("\n")
            .map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        </div>
        <div className="cOverviewGrid">
          <div className="cbSpBox">
            <dl>
              <dt>
                <em className="general"></em>
                {t(`OVERVIEWCAL.general`)}
              </dt>
              <dd>
                {t(`OVERVIEWCAL.buildsUpon`)}
                <ul>
                  <li>ISO 14064</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt>
                <em className="manufac"></em>
                {t(`OVERVIEWCAL.manufacturing`)}
              </dt>
              <dd>
                {t(`OVERVIEWCAL.buildsUpon`)}
                <ul>
                  <li>ISO 14064</li>
                  <li>IPCC Guidelines</li>
                  <li>{t(`OVERVIEWCAL.framework`)}</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt>
                <em className="logist"></em>
                {t(`OVERVIEWCAL.logistics`)}
              </dt>
              <dd>
                {t(`OVERVIEWCAL.buildsUpon`)}
                <ul>
                  <li>ISO 14064</li>
                  <li>IGLEC standards</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt>
                <em className="hospital"></em>
                {t(`OVERVIEWCAL.hospitality`)}
              </dt>
              <dd>
                {t(`OVERVIEWCAL.buildsUpon`)}
                <ul>
                  <li>ISO 14064</li>
                  <li>GSTC and HCMI standards</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="cbSpBox">
            <dl>
              <dt>
                <em className="ship"></em>
                {t(`OVERVIEWCAL.ship`)}
              </dt>
              <dd>
                template builds upon
                <ul>
                  <li>ISO 14064</li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalcuOverview;
