import React from "react";

function Integrate() {
    return (
        <>
            <div className="pageTitle">
                Integrate with API
            </div>
            <div className="integrateWrap">
                <strong>Find the source code to implement your bookmarked ones into your service.</strong>
                <div className="integrate">
                    <div className="cbSpBox">
                        <div className="calcu">
                            <strong>My Bookmarked<br />Emission Calculator</strong>
                            <button>Click to see the detail</button>
                        </div>
                    </div>
                    <div className="cbSpBox">
                        <div className="data">
                            <strong>My Bookmarked<br/>Emission Data</strong>
                            <button>Click to see the detail</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Integrate;