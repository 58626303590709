import React from "react";
import {Link} from "react-router-dom";

function IntegrateCalcu() {
    return (
        <>
            <div className="pageTitle">
                <button className="back"></button>
                My Bookmarked Emission Calculator
            </div>
            <div className="CalcuBookmark">
                <div className="CalcuWrap">
                    <div className="calcuList">

                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <dl>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>
                                            <div className="inputObj">
                                                <div>
                                                    <p>
                                                        <b>철강 생산(iron and Steel Production)</b>
                                                        <button>열림</button>
                                                    </p>
                                                    <ul style={{display: "block"}}>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                        <li>철강 생산(iron and Steel Production)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                    </dl>
                                </div>
                                <div className="calcuApi">
                                    <label>API Code</label>
                                    <div className="ApiResult">
                                        <div className="code">
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"<br/>
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"<br/>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <dl>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                    </dl>
                                </div>
                                <div className="calcuApi">
                                    <label>API Code</label>
                                    <div className="ApiResult">
                                        <div className="code">
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <dl>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                    </dl>
                                </div>
                                <div className="calcuApi">
                                    <label>API Code</label>
                                    <div className="ApiResult">
                                        <div className="code">
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <dl>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                    </dl>
                                </div>
                                <div className="calcuApi">
                                    <label>API Code</label>
                                    <div className="ApiResult">
                                        <div className="code">
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <button className="delete"></button>
                                </div>
                                <div className="calcuBtm">
                                    <dl>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                        <dt>업종</dt>
                                        <dd>철강 생산 (iron and Steel Production)</dd>
                                        <dt>배출 활동</dt>
                                        <dd>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10
                                            % S); MDO/MGO(ULSFO)
                                        </dd>
                                        <dt>생산 공정</dt>
                                        <dd>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</dd>
                                    </dl>
                                </div>
                                <div className="calcuApi">
                                    <label>API Code</label>
                                    <div className="ApiResult">
                                        <div className="code">
                                            curl --request POST/<br/>
                                            -- url https://Carbonscope.com/Estimate/EmissionByActivity /<br/>
                                            -- header 'Authorization : Bearer $API Ket/<br/>
                                            -- data (<br/>
                                            "emission factor"<br/>
                                            "factorid" : "KOR-S2-c-11111300-32-0-2021",<br/>
                                            "source" : "MOE/KOR"<br/>
                                            <br/>
                                            "parameters"<br/>
                                            "regActivityAmt" : 100,<br/>
                                            "regActivityUnit" : "kWh"

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="CalcuBookmark">
                <div className="cbSpBox noData">
                    <Link to="/findDataExplorer2024">
                        <span>Go to browse and bookmark emission data</span>
                    </Link>
                </div>
            </div>

        </>
    );
}

export default IntegrateCalcu;