import React, { useEffect, useState } from "react";
import {
  getEmissionBookmarkSector,
  getEmissionBookmarkTemplate,
  insertTemplate,
} from "../../service/calculatorService";
import { nowInsertDate } from "../../util/common";
import { alertData } from "../../util/alertData";
import { insertAllEmissionActivityCategoryBookmark } from "../../service/activityCategoryService";

function AddCalculatorBookmarkRaw({
  scrollElement,
  setAddrowCheck,
  userInfo,
  oriData,
  setOriData,
  oriTitle,
  setOriTitle,
}) {
  const [sector, setSector] = useState();
  const [template, setTemplate] = useState();
  const [title, setTitle] = useState([{}]);
  const [optionOpen, setOptionOpen] = useState({
    bizOpen: false,
    catgOpen: false,
  });

  useEffect(() => {
    scrollElement.scrollLeft = scrollElement.scrollWidth;
  }, [scrollElement]);

  useEffect(() => {
    getBizOption();
  }, []);

  const getBizOption = async () => {
    try {
      const response = await getEmissionBookmarkSector();
      if (response.status === 200) {
        setSector(response.data);
      }
    } catch (error) {
      console.error("getBizOption Error:", error);
    }
  };

  // 템플릿 조회
  const getTemplate = async (data) => {
    try {
      const response = await getEmissionBookmarkTemplate(
        userInfo.userNo,
        data.curtNo
      );
      if (response.status === 200) {
        if (response.data.length < 1) {
          // 데이터가 없을 때
          let tepm = [
            {
              curtCatgName: alertData.allAndNull,
              curtCatgNo: null,
              curtName: null,
              curtNo: null,
            },
          ];
          setTemplate(tepm);
        } else {
          setTemplate(response.data);
        }
      }
    } catch (error) {
      console.error("getTemplate error => ", error);
    }
  };

  const clickBizOption = (titleData) => {
    bizTitleSetting(titleData);
    getTemplate(titleData);
  };
  const bizTitleSetting = (titleData) => {
    let copyTitle = { ...title };
    copyTitle.curtNo = titleData.curtNo;
    copyTitle.curtName = titleData.curtName;
    copyTitle.curtCatgNo = null;
    copyTitle.curtCatgName = null;
    setTitle(copyTitle);

    setOptionOpen({
      bizOpen: !optionOpen.bizOpen,
      catgOpen: false,
    });
  };

  // 템플릿 셀렉트 "옵션" 클릭 시
  const clickCatgOption = (titleData) => {
    if (titleData.curtCatgName === alertData.allAndNull) {
      return;
    } else {
      catgTitleSetting(titleData);
    }
  };

  const catgTitleSetting = (titleData) => {
    let copyTitle = { ...title };
    copyTitle.curtCatgNo = titleData.curtCatgNo;
    copyTitle.curtCatgName = titleData.curtCatgName;
    setTitle(copyTitle);

    setOptionOpen({
      bizOpen: false,
      catgOpen: !optionOpen.catgOpen,
    });
  };

  const saveClick = () => {
    if (title.curtCatgNo) {
      insertTemplateData();
    } else {
      alert(alertData.addCalulatorBookmarkDataNull);
    }
  };

  const insertTemplateData = async () => {
    try {
      const cpDataParam = {
        // bizOpen: false,
        // catgOpen: false,
        // userCurtCatgId: "SaveData",
        // editMode: false,
        // curtCatgName: title.curtCatgName,
        // curtName: title.curtName,
        // userName: userInfo.userName,
        chgDtm: nowInsertDate(),
        regDtm: nowInsertDate(),
        chgUserNo: userInfo.userNo,
        curtCatgNo: title.curtCatgNo,
        curtNo: title.curtNo,
        regUserNo: userInfo.userNo,
        userNo: userInfo.userNo,
      };

      const response = await insertTemplate(cpDataParam);

      if (response.status === 200) {
        cpDataParam.userCurtCatgId = "SaveData";
        cpDataParam.bizOpen = false;
        cpDataParam.catgOpen = false;
        cpDataParam.editMode = false;
        cpDataParam.curtCatgName = title.curtCatgName;
        cpDataParam.curtName = title.curtName;
        cpDataParam.userName = userInfo.userName;

        const cpData = [...oriData];
        cpData.push(cpDataParam);
        setOriData(cpData);

        const cpTitle = [...oriTitle];
        cpTitle.push(title);
        setOriTitle(cpTitle);
        setAddrowCheck(false);
        alert(alertData.insertAlert);

        // 해당 템플릿에 모든 activity Category 북마크
        try {
          const param = {
            userNo: userInfo.userNo,
            curtCatgNo: title.curtCatgNo,
            nationId: "KOR",
            langCd: null,
            // langCd: "ko",
          };

          await insertAllEmissionActivityCategoryBookmark(param);
        } catch (error) {
          console.error(
            "insertAllEmissionActivityCategoryBookmark error: ",
            error
          );
        }
      }
    } catch (error) {
      console.error("insertTemplateData Error:", error);
    }
  };
  return (
    <>
      <div className="calcuBox">
        <div className="calcuCont">
          <div className="calcuTop">
            <label>신규</label>
            <b></b>
          </div>
          <div className="calcuBtm">
            {/* */}
            <div className="inputObj">
              <label>업종</label>
              <div>
                <div>
                  <p
                    onClick={() => {
                      setOptionOpen({
                        bizOpen: !optionOpen.bizOpen,
                        catgOpen: false,
                      });
                    }}
                  >
                    <b>{title.curtName}</b>
                    <button>열림</button>
                  </p>
                  <ul
                    style={{
                      display: optionOpen.bizOpen ? "block" : "none",
                      top: "47px",
                    }}
                  >
                    {sector && (
                      <>
                        {sector.map((data, childIndex) => (
                          <li
                            key={childIndex}
                            onClick={() => {
                              clickBizOption(data);
                            }}
                          >
                            {data.curtName}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="inputObj">
              <label>Template</label>
              <div>
                <p
                  onClick={() => {
                    setOptionOpen({
                      bizOpen: false,
                      catgOpen: !optionOpen.catgOpen,
                    });
                  }}
                >
                  <b>{title.curtCatgName}</b>
                  <button>열림</button>
                </p>
                <ul
                  style={{
                    display: optionOpen.catgOpen ? "block" : "none",
                    top: "47px",
                  }}
                >
                  {template && (
                    <>
                      {template.map((data, childIndex) => (
                        <li
                          key={childIndex}
                          onClick={() => {
                            clickCatgOption(data);
                          }}
                        >
                          {data.curtCatgName}
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/* */}
          </div>

          <div className="calcuButton">
            <button className="save" onClick={saveClick}>
              Save
            </button>
            <button
              onClick={() => {
                setAddrowCheck(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCalculatorBookmarkRaw;
