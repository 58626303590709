import { useEffect, useState } from "react";
import { getMyInfo } from "../../service/userRequestService";
import { useTranslation } from "react-i18next";

export default function MyBookmarkCalApi2024({ data, userInfo }) {
  const [openDetail, setOpenDetail] = useState(false);
  const [myInfo, setMyInfo] = useState(null);
  const { t } = useTranslation();

  let cpData = structuredClone(data);
  let varsArray = [];
  let inputValDetailInfo = [];

  useEffect(() => {
    if (userInfo?.userNo) {
      myData();
    }
  }, [userInfo]);

  const myData = async () => {
    const response = await getMyInfo(userInfo.userNo);
    if (response.status === 200) {
      setMyInfo(response.data);
    }
  };
  for (let i = 1; i < 7; i++) {
    const actCatgObj = cpData[`actCatg${i}`];
    const inputDesc = cpData[`inputText${i}`];

    // 해당 레벨의 속성 타입이 json일 때(즉 값이 고정된 경우)
    if (cpData[`actJsonType${i}`]) {
      for (const key in actCatgObj) {
        if (actCatgObj.hasOwnProperty(key)) {
          const name = actCatgObj[key].name;
          const value = actCatgObj[key].value;
          varsArray.push({ type: name, value: value, desc: "Fixed Value" });
        }
      }
    }

    // input type useVal을 제외하고 추가로 존재할 경우
    if (i > 1) {
      if (cpData[`inputName${i}`]) {
        varsArray.push({
          type: cpData[`inputName${i}`],
          value: 0,
          desc: inputDesc,
        });
      }
    }
  }

  // request param data 가공
  cpData.requestData = `'{"userFactId" : ${
    data.userFactId ? '"' + data.userFactId + '"' : null
  }
  , "userCurtItemId" : ${
    data.userCurtItemId ? '"' + data.userCurtItemId + '"' : null
  }
  , "unit" : "${data.selectUnit}"
  , "useVal" : 0
  , "nationId": "KOR"
  , "yyyy": "2024"
  , "vars" :
  ${
    // vars 데이터 가공
    varsArray.length > 0
      ? "{" + varsArray.map((data) => `"${data.type}" : ${data.value}`) + "}"
      : null
  }}'`;

  cpData.apiUrl = `"http://${window.location.host}/api/userRequest/getRequestEmissionCalculator"`;
  cpData.apiUrl = cpData.apiUrl.replace("33061", "33198");
  cpData.apiUrl = cpData.apiUrl.replace(
    "carbonscope.io",
    "carbonscope.io:8080"
  );

  cpData.accessToken = "Bearer " + localStorage.getItem("access_token");

  // 첫 데이터는 useVal 고정임
  inputValDetailInfo.push({
    label: cpData.inputName1,
    desc: cpData.inputText1,
    defaultValue: 0,
  });

  varsArray.forEach((data) => {
    // console.log(data);
    let info = {
      label: data.type,
      desc: data.desc,
      defaultValue: data.value,
    };

    inputValDetailInfo.push(info);
  });
  cpData.inputValDetailInfo = inputValDetailInfo;

  return (
    <div className="calcuApi">
      <label>API Code</label>
      <button
        onClick={() => {
          setOpenDetail(!openDetail);
        }}
      >
        Input Parameter Info
      </button>
      {openDetail && (
        <div className="DetailInfo">
          <dl>
            <dt>
              <span
                className="close"
                onClick={() => {
                  setOpenDetail(false);
                }}
              ></span>
            </dt>
            <dd>
              <table>
                <thead>
                  <tr>
                    <th>Column</th>
                    <th>Desc</th>
                    <th>Default Value</th>
                  </tr>
                </thead>
                <tbody>
                  {cpData.inputValDetailInfo.map((data, index) => (
                    <tr key={index}>
                      <td>{data.label}</td>
                      <td>{data.desc}</td>
                      <td>{data.defaultValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </dd>
          </dl>
        </div>
      )}

      <div className="ApiResult">
        <div className="code">
          {myInfo?.cbUserSubscribeCd === "1" ? (
            <h3>{t(`EXPLORERCAL.membership`)}</h3>
          ) : cpData.selectUnit !== "Please select a unit" ? (
            <>
              curl --header "Content-Type: application/json" \ <br />
              --header "APP_KEY: {myInfo?.appKey}" \ <br />
              --data {cpData.requestData} \ <br />
              --url {cpData.apiUrl}
            </>
          ) : (
            <h3>Please select a unit</h3>
          )}

          {/* {cpData.selectUnit !== "Please select a unit" ? (
            <>
              curl --header "Content-Type: application/json" \ <br />
              --header "APP_KEY: {myInfo?.appKey}" \ <br />
              --data {cpData.requestData} \ <br />
              --url {cpData.apiUrl}
            </>
          ) : (
            <h3>Please select a unit</h3>
          )} */}
        </div>
      </div>
    </div>
  );
}
