import React, { useState } from "react";

import { CommonDialog, TitleDialog } from "../../components/styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
//import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

function CloseIconSvg() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" stroke="#000" fill="#000"></path>
      </svg>
  )
}

function AccountEdit() {
  const [popOpen, setPopOpen] = useState(false);

  const pophandleClose = () => {
    setPopOpen(false);
  };

  const [popOpen2, setPopOpen2] = useState(false);

  const pophandleClose2 = () => {
    setPopOpen2(false);
  };

  const pophandleOpen2 = () => {
    setPopOpen2(true);
  }

  return (
      <>
      <CommonDialog open={popOpen} onClose={pophandleClose}>
        <DialogTitle>Notice</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={pophandleClose}
          className="dialogClose"
        >
          <CloseIconSvg />
        </IconButton>
        <DialogContent>
          <div className="dialogCont">
            <p className="dialogText">
              This data will be added to the bookmarks, and will be implemented
              into your emissions calculations from now on.{" "}
            </p>
            <div className="dialogBtn">
              <button>Check</button>
              <button className="cancel">Cancel</button>
            </div>
          </div>
        </DialogContent>
      </CommonDialog>
      <CommonDialog open={popOpen2} onClose={pophandleClose2}>
        <DialogTitle>Change Password</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={pophandleClose}
            className="dialogClose"
        >
          <CloseIconSvg />
        </IconButton>
        <DialogContent>
          <div className="dialogCont">
            <div className="accountInput">
              <div className="accInp">
                <div>
                  <label>Create Password</label>
                  <p>
                    <input type="password" value="jaehong"/>
                  </p>
                </div>
              </div>
              <div className="accInp">
                <div>
                  <label>Confirm Password</label>
                  <p>
                    <input type="password" value="jaehong"/>
                  </p>
                  <span className="errorText">Incorrect data. Please check your info.</span>
                </div>
              </div>

            </div>
            <div className="dialogBtn">
              <button>Confirm</button>
            </div>
          </div>
        </DialogContent>
      </CommonDialog>
      <div className="pageTitle">My Account detail</div>
      <div className="accountDetail">
        <div className="left">
          <div className="cbSpBox">
            <div className="boxTitle">General Information</div>
            <div className="boxCont">
              <ul>
                <li>
                  <span>First Name</span>
                  <input type="text" value="Jaehong"/>
                </li>
                <li>
                  <span>Last Name</span>
                  <input type="text" value="Kim"/>
                </li>
                <li>
                  <span>Company</span>
                  <input type="text" value="Thingspire, Ltd" disabled/>
                </li>
                <li>
                <span>Industry</span>
                  <div className="selectObj">
                    <p className="d"><span>Agriculture, Forestry, Fishing and Hunting</span></p>
                    <ul>
                      <li>Agriculture, Forestry, Fishing and Hunting</li>
                      <li>Mining, Quarrying, and Oil and Gas Extraction</li>
                      <li>Utilities</li>
                      <li>Construction</li>
                      <li>Manufacturing</li>
                      <li>Wholesale Trade</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <span>Role</span>
                  <input type="text" value="Product Designer"/>
                </li>
                <li>
                  <span>Work Email</span>
                  <input type="text" value="john@thinspire.com"  disabled />
                </li>
                <li>
                  <span>Password</span>
                  <button className="insBtn" onClick={pophandleOpen2}>Change Password</button>
                </li>
              </ul>
              <button>Edit</button>
            </div>
          </div>
        </div>
        <div className="right">
        <div className="cbSpBox">
            <div className="boxTitle">My API Key</div>
            <div className="boxCont">
            <div className="noData">No data.</div>
            </div>
          </div>
          <div className="cbSpBox">
            <div className="boxTitle">My Subscription Status</div>
            <div className="boxCont">
              <div className="noData">No data.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountEdit;
