import React, {useEffect} from "react";

function CalcuOverview_Kr() {
    useEffect(() => {
        document.body.classList.add('kr');
    });

    return (
        <>
            <div className="pageTitle">
                세계에서 가장 효율적인 배출량 계산기 API에 오신 것을 환영합니다.
            </div>
            <div className="cOverview">
                <strong>세계에서 가장 쉽고 정교한 배출 계산기를 귀하의 소프트웨어에 구현해 보세요.</strong>
                <div className="cbSpBox">
                    Thingspire에서는 현재까지 5가지 유형의 템플릿을 개발했습니다. <br />
                    당사의 범용 템플릿은 일반적인 사용을 위해 광범위한 산업을 포괄하도록 설계되었으며, <br />
                    나머지 4개의 산업별 템플릿은 조선, 호텔, 물류 및 제조에 대한 배출량 계산을 촉진하고 간소화하도록 설계되었습니다. <br />
                    우리의 산업별 템플릿은 비 ESG 전문가가 조직의 탄소 배출량을 계산할 수 있도록 관련 산업 표준 및 프로토콜을 기반으로 구축되었습니다.
                </div>
                <div className="cOverviewGrid">
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="general"></em>일반사항
                            </dt>
                            <dd>
                                템플릿은 다음을 기반으로 합니다.
                                <ul>
                                    <li>ISO 14064</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="manufac"></em>제조
                            </dt>
                            <dd>
                                템플릿은 다음을 기반으로 합니다.
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>IPCC Guidelines</li>
                                    <li>세계철강협회 보고 프레임워크</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="logist"></em>물류
                            </dt>
                            <dd>
                                템플릿은 다음을 기반으로 합니다.
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>IGLEC standards</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="hospital"></em>호텔
                            </dt>
                            <dd>
                                템플릿은 다음을 기반으로 합니다.
                                <ul>
                                    <li>ISO 14064</li>
                                    <li>GSTC and HCMI standards</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="cbSpBox">
                        <dl>
                            <dt>
                                <em className="ship"></em>조선
                            </dt>
                            <dd>
                                템플릿은 다음을 기반으로 합니다.
                                <ul>
                                    <li>ISO 14064</li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CalcuOverview_Kr