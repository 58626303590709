import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../util/authUtil";
import LnbBottom from "./LnbBottom2024";
import {
  mainMenu,
  // productMenu,
  settingMenu,
  myProfileMenu,
  noticeMenu,
} from "./menuInfo";
import { useTranslation } from "react-i18next";

const Lnb = () => {
  const { t, i18n } = useTranslation();
  const [currentMain, setCurrentMain] = useState("");
  const [currentSub, setCurrentSub] = useState("");
  const [viewDetail, setViewDetail] = useState(false);
  const nav = useNavigate();

  return (
    <>
      <div id="ScopeLNBWrap">

        <div id="ScopeLNB">
          <h1>
            <strong>Carbon API</strong>
          </h1>
          {mainMenu?.map((data, index) => (
            <p
              key={index}
              className={
                currentMain === data.name ? data.pClass + " on" : data.pClass
              }
            >
              <a
                className={data.aClass}
                onClick={() => {
                  if (data.link === "/") {
                    setCurrentSub("");
                  }
                  nav(data.link);
                  setCurrentMain(data.name);
                  setViewDetail(true);
                }}
                data-link={data.goTo ? "goTo" : "subopen"}
              >
                <b></b>
                <em>{t(`LNB.${data.transData}`)}</em>
              </a>
            </p>
          ))}
          <LnbBottom
            currentMain={currentMain}
            setCurrentMain={setCurrentMain}
            setViewDetail={setViewDetail}
          />

          {/* <div
            className={
              currentMain === "Products" && viewDetail ? "LNBOpen on" : "LNBOpen"
            }
            id="ProducDetail"
          >
            <strong
              onClick={() => {
                setViewDetail(false);
              }}
            >
              <span>{t(`LNB.products`)}</span>
            </strong>
            <ul>
              {productMenu &&
                productMenu.map((data, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <li key={data.parent}>
                      <b>{t(`LNB.${data.parentTransData}`)}</b>
                    </li>

                    {data.child.map((child, childIndex) => (
                      <li key={childIndex}>
                        <a
                          onClick={() => {
                            nav(child.link);
                            setCurrentSub(child.title);
                          }}
                          className={currentSub === child.title ? "on" : ""}
                        >
                          {t(`LNB.${child.transData}`)}
                        </a>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
            </ul>
          </div> */}

          <div
            className={
              currentMain === "Setting" && viewDetail ? "LNBOpen on" : "LNBOpen"
            }
            id="SettingDetail"
          >
            <strong
              onClick={() => {
                setViewDetail(false);
              }}
            >
              <span>{t(`LNB.setting`)}</span>
            </strong>
            <ul>
              {settingMenu &&
                settingMenu.map((data, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <li key={data.parent}>
                      <b>{t(`LNB.${data.parentTransData}`)}</b>
                    </li>

                    {data.child.map((child, childIndex) => (
                      <li key={childIndex}>
                        <a
                          onClick={() => {
                            if (child.link !== "" && child.link !== "#") {
                              nav(child.link);
                            }
                            if (child.title === "Korean") {
                              document.body.classList.add("kr");
                              document.body.classList.remove("en");
                              i18n.changeLanguage("ko");
                              localStorage.setItem("langCd", "ko");
                            } else if (child.title === "English") {
                              document
                                .querySelector("body")
                                .classList.remove("kr");

                              document.querySelector("body").classList.add("en");

                              i18n.changeLanguage("en");
                              localStorage.setItem("langCd", "en");
                            }
                            setCurrentSub(child.title);
                          }}
                          className={currentSub === child.title ? "on" : ""}
                        >
                          {t(`LNB.${child.transData}`)}
                        </a>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
            </ul>
          </div>

          <div
            className={
              currentMain === "notifi" && viewDetail ? "LNBOpen on" : "LNBOpen"
            }
            id="NotifiList"
          >
            <strong
              onClick={() => {
                setViewDetail(false);
              }}
            >
              <span>{t(`LNB.note`)}</span>
            </strong>
            <ul className="notiList">
              {noticeMenu &&
                noticeMenu.map((data, index) => (
                  <li key={index}>
                    <div>
                      <span>{data.contents}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div
            className={
              currentMain === "account" && viewDetail ? "LNBOpen on" : "LNBOpen"
            }
            id="SignInList"
          >
            <strong
              onClick={() => {
                setViewDetail(false);
              }}
            >
              <span>{t(`LNB.profile`)}</span>
            </strong>
            <ul>
              {myProfileMenu &&
                myProfileMenu.map((data, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <li key={data.parent}>
                      <b>{t(`MYPROFILE.${data.parentTransData}`)}</b>
                    </li>

                    {data.child.map((child, childIndex) => (
                      <li key={`${data.parent}-${child.title}-${childIndex}`}>
                        <a
                          onClick={() => {
                            nav(child.link);
                            setCurrentSub(child.title);
                          }}
                          className={currentSub === child.title ? "on" : ""}
                        >
                          {t(`MYPROFILE.${child.transData}`)}
                        </a>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
            </ul>
            <div className="logout">
              <button
                onClick={() => {
                  logOut();
                }}
              >
                {t(`LNB.logout`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lnb;
